%center-phone {
  display: grid;
  place-items: center;
}

.create-squeeze {
  width: 100%;
  max-width: 1024px;
  padding: 20px;
  margin: 0 auto;
  &_file_container {
    flex-grow: 1;
    width: calc(100% - 5px);
    margin-bottom: 10px;

    .form__file-container{
      flex-direction: column;

      div {
        width: 100% !important;
      }

      .form__accepted-files {
        width: 100% !important;
        margin-top: 10px;
      }
    }
  }
  &__actions-title {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;
    &_label {
      color: #616261;
      font-size: 16px;
      font-weight: bold;
      margin: 0;
    }
    svg {
      cursor: pointer;
      font-size: 20px;
    }
  }
  &__actions-list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    padding: 8px 8px 20px;
    flex-grow: 1;
    &_item {
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 10px;
      border-radius: 4px;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.14);
      svg {
        cursor: pointer;
        margin-top: 36px;
      }

      .form__input-container {
        margin-bottom: 0;
      }
    }
  }
  &__border {
    @extend %center-phone;
    width: 380px;
    height: 664px;
    border-radius: 45px;
    background-color: #152839;
    border: 1px solid rgba(255, 255, 255, 0.6);
    box-shadow: inset 0 0 3px 1px #fff;
    color: #fff;
  }
  &__background {
    @extend %center-phone;
    width: 96%;
    height: 98%;
    border-radius: 40px;
    background-color: #000;
  }
  &__container {
    overflow-y: auto;
    width: 96%;
    height: 632px;
    border-radius: 35px;
  }
  &__audios {
    display: flex;
    align-items: center;
    gap: 12px;
    div {
      min-width: 310px;
    }
  }
  &__btns {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
