.forms {
  width: 100%;
  &__search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0 25px 0;
    .search {
      display: flex;
      align-items: center;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      box-sizing: border-box;
      border-radius: 4px;
      width: 280px;
      height: 35px;
      padding-right: 10px;
      color: #999;
      input {
        flex-grow: 1;
        border: 0px;
        background-color: transparent;
        outline: none !important;
        height: 100%;
        padding: 0 4px 0 12px;
        font-weight: normal;
        font-size: 14px;
        color: #616261;
        &::placeholder {
          color: #e0e0e0;
        }
      }
    }
    .send-icon {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      background: #ced5fd;
      color: #8093fc;
      border-radius: 50%;
      font-size: 24px;
      .icon {
        margin: 2px 0 0 2px;
      }
    }
  }
  table {
    width: 100%;
    display: table !important;
    thead {
      background: #edf0ff;
      border-radius: 4px;
      padding-top: 12px;
      padding-bottom: 12px;
      display: block !important;
      width: 100%;
      margin-bottom: 10px;
      tr {
        display: flex !important;
        width: 100%;
        th {
          text-align: center;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          color: #818181;
        }
      }
    }
    tbody {
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      display: block;
      width: 100%;
      max-height: calc(100vh - 310px);
      overflow-y: scroll;
      tr {
        display: flex;
        width: 100%;
        min-height: 61px;
        margin: 8px 0;
        background-color: #fcfcfc;
        td {
          display: flex;
          justify-content: center;
          text-align: center;
          font-size: 14px;
          color: #818181;
          padding: 12px 0;
          margin: auto 0;
          &:last-child {
            display: flex;
            justify-content: flex-end;
            padding-right: 40px;
          }
          .select-form {
            display: flex;
            align-items: center;
            font-size: 26px;
            width: 25px;
            height: 25px;
            padding-bottom: 2px;
            margin-left: 10px;
            color: #bdcbf8;
            background: #ffffff;
            border: 1px solid #d9d9d9;
            border-radius: 3px;
          }
          .form-detail {
            border: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #495fd7;
            background: #ced5fd;
            font-size: 18px;
            width: 25px;
            height: 25px;
            border-radius: 3px;

            &:not(:last-child) {
              margin-right: 4px;
            }

            &--delete {
              color: tomato;
              font-size: 16px;
            }
          }
        }
        &.selected-form {
          background-color: #8093fc;
          &:hover {
            background-color: #8093fc;
          }
          td {
            color: #ffffff;
          }
          .select-form {
            border: 2px solid #bdcbf8;
          }
        }
        &:hover {
          background-color: #edf0ff;
        }
      }
    }
  }
}
.pagination-forms {
  margin-top: 10px !important;
}
.patients-list-modal {
  &__close {
    font-size: 26px;
    color: #616261;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 20px;
  }
  &__title {
    margin: 30px 0 10px 30px;
    h3 {
      font-weight: bold;
      font-size: 20px;
      color: #616261;
      margin-bottom: 2px;
    }
    p {
      font-size: 16px;
      color: #bdbdbd;
    }
  }
  &__search {
    margin: 0 30px 20px;
    .filter {
      cursor: pointer;
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 130px;
      height: 35px;
      padding: 0 5px;
      background: #ced5fd;
      border-radius: 4px;
      font-weight: 600;
      font-size: 14px;
      color: #8093fc;
    }
    .select {
      width: 200px;
    }
  }
  &__list {
    height: 400px;
    padding: 0 30px 0 30px;
    overflow-y: scroll;
    &.minlength {
      height: auto;
    }
    .patient {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      border-bottom: 1px solid rgba(217, 217, 217, 0.4);
      &__info {
        display: flex;
        align-items: center;
        &__avatar {
          width: 40px;
          height: 40px;
          img {
            width: 100%;
          }
        }
        &__name {
          padding-left: 20px;
          p {
            font-size: 14px;
            color: #8093fc;
            margin-bottom: 0;
            &:first-child {
              font-size: 16px;
              font-weight: 600;
              color: #606060;
              margin-bottom: -2px;
            }
            &:last-child {
              margin-top: -4px;
              font-size: 14px;
              color: #606060;
            }
          }
        }
      }
    }
  }
  &__submit {
    display: flex;
    justify-content: center;
    margin: 30px 0;
  }
}
