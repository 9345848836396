.evie-home {
  background-image: url('../../assets/images/pink-bg.webp');
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 60svh;
  background-attachment: fixed;
  min-height: 100vh;
  min-height: 100svh;
  padding: 32px 20px 100px;
  &__programtitle {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 12px;
  }
}

.program-info-card {
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px #dfe6ff4d;
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 12px;
  &__img {
    display: block;
    width: 100%;
    height: auto;
    max-height: 180px;
    object-fit: cover;
    border-radius: 12px;
  }
  &__content {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    &_labels {
      flex: 1;
      h2 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 4px;
      }
      p {
        margin-bottom: 0;
      }
    }
    &_arrowicon {
      display: grid;
      place-items: center;
      flex-shrink: 0;
      background-color: #ea7dae;
      color: #fff;
      font-size: 24px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
  }
}

.program-info {
  min-height: 100vh;
  min-height: 100svh;
  padding-bottom: 128px;
  &__topbar {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    font-size: 24px;
    background-color: #fff;
    width: 100%;
    max-width: 480px;
    padding: 4px 16px;
    margin: 0 auto;
    svg {
      cursor: pointer;
    }
  }
  &__header {
    position: relative;
    background-image: url('../../assets/images/program-info-bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 280px;
    margin-bottom: 32px;
    &_bg {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 280px;
      background-image: linear-gradient(180deg, transparent, rgba(255, 255, 255, 1));
    }
  }
  &__title {
    position: absolute;
    bottom: -16px;
    left: 20px;
    max-width: 128px;
    h2 {
      font-size: 28px;
      font-weight: bold;
      margin: 0;
    }
  }
  &__duration {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #fff;
    padding: 2px 4px;
    margin-bottom: 4px;
    border-radius: 16px;
    &_icon {
      display: grid;
      place-items: center;
      background-color: #ffe0e9;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      font-size: 14px;
    }
    span {
      font-size: 14px;
    }
  }
  &__startbtn {
    position: fixed;
    bottom: 70px;
    background-color: #fff;
    width: 100%;
    max-width: 480px;
    padding: 8px 20px;
    margin: 0 auto;
  }
}

.see-more {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__label {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }
  &__btn {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 16px;
    font-weight: bold;
    color: #ea7dae;
    svg {
      font-size: 20px;
    }
  }
}

.daily-ex-card {
  background-color: rgba(186, 165, 145, 1);
  background-image: url('../../assets/images/pregnant-woman-2.webp');
  background-repeat: no-repeat;
  background-size: 220px 160px;
  background-position: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 160px;
  padding: 20px;
  margin-top: 16px;
  border-radius: 12px;
  color: #fff;
  &__title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 4px;
  }
  &__content {
    max-width: 220px;
    margin: 0;
  }
  &__labels {
    display: flex;
    gap: 12px;
    p {
      font-size: 14px;
      color: #000;
      margin: 0;
      svg {
        font-size: 16px;
      }
    }
  }
}

.daily-exercises {
  position: relative;
  display: grid;
  place-items: center;
  background-color: #9b2c51;
  color: #fff;
  min-height: 100vh;
  min-height: 100svh;
  &__closeicon {
    cursor: pointer;
    position: absolute;
    top: 16px;
    left: 16px;
    font-size: 28px;
  }
}

.go-plus-card {
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: #eac8c1;
  width: 100%;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 24px;
  border-radius: 12px;
  &__staricon {
    display: grid;
    place-items: center;
    background-color: #fff;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    flex-shrink: 0;
    svg {
      font-size: 28px;
      color: #9b2c51;
    }
  }
  &__content {
    flex: 1;
    h2 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 0;
    }
    p {
      margin: 0;
    }
  }
}

.week-content {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  margin-top: 16px;
}

.content-card {
  position: relative;
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px #dfe6ff4d;
  border-radius: 12px;
  &__playicon {
    position: absolute;
    top: calc(50% - 56px);
    left: calc(50% - 19px);
    color: #fff;
    font-size: 40px;
  }
  &__img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 128px;
    border-radius: 12px 12px 0 0;
  }
  &__footer {
    min-height: 82px;
    padding: 8px 12px;
    border: 1px solid rgba(138, 155, 254, 0.2);
    border-radius: 0 0 12px 12px;
    &_description {
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-height: normal;
      font-size: 14px;
      font-weight: bold;
      margin: 0;
    }
  }
  &__footertitle {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 4px;
    &_label {
      font-size: 14px;
      margin: 0;
    }
  }
  &__footericon {
    display: grid;
    place-items: center;
    background-color: #ffeaf1;
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }
}

.program-content-card-container {
  padding: 0 20px;
}
.program-content-card {
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 12px;
  border-radius: 12px;
  &.description {
    background-color: #f2e4d3;
  }
  &.benefits {
    background-color: #eac8c1;
  }
  &.content {
    background-color: #ffeaf1;
  }
  &__title {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
    &_icon {
      flex-shrink: 0;
      display: grid;
      place-items: center;
      background-color: #fff;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      svg {
        color: #9b2c51;
        font-size: 20px;
      }
    }
    &_label {
      flex: 1;
      font-size: 16px;
      font-weight: bold;
      margin: 0;
    }
  }
}

.program-content {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  p {
    color: rgba(0, 0, 0, 0.8);
    margin: 0;
  }
  &__benefits {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    margin-bottom: 12px;
    svg {
      flex-shrink: 0;
      font-size: 20px;
      margin-top: 2px;
    }
    p {
      flex: 1;
    }
  }
}

.unit-card {
  display: grid;
  grid-template-columns: minmax(60px, 25%) 1fr;
  gap: 12px;
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px #dfe6ff4d;
  width: 100%;
  padding: 12px;
  margin-bottom: 12px;
  border-radius: 12px;
  border: 1px solid rgba(138, 155, 254, 0.2);
  &__img {
    img {
      display: block;
      object-fit: cover;
      width: 100%;
      height: auto;
      max-height: 100px;
      border-radius: 12px;
    }
  }
  &__title {
    align-self: center;
    h3 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0;
    }
    p {
      margin: 0;
    }
  }
}

.unit-description-modal {
  position: relative;
  &__closeicon {
    position: absolute;
    top: -8px;
    right: -8px;
    font-size: 24px;
  }
  h3 {
    font-size: 18px;
    font-weight: bold;
    max-width: 430px;
    margin-bottom: 16px;
  }
  p {
    margin: 0;
  }
}
