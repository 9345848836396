.availability {
  height: 100%;
  padding: 10px 30px;
  &_title {
    margin-top: 10px;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    color: #616261;
  }
  &_description {
    font-size: 14px;
    text-align: center;
    color: #bdbdbd;
    max-width: 576px;
    margin: 0 auto 10px;
  }
  &__content {
    padding: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }
  &__timezone {
    width: 100%;
    max-width: 514px;
  }
  &__divider {
    border: 1px solid #bdbdbd;
    margin: 20px 0;
    opacity: 0.5;
  }
  &__weekdays {
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: minmax(120px, 10%) 1fr minmax(40px, 5%);
    gap: 16px;
    &_check {
      align-self: center;
      display: flex;
      margin-bottom: 12px;
      p {
        font-size: 16px;
        font-weight: 600;
        color: #606060;
        margin: 0 0 0 10px;
        opacity: 0.4;
        &.selected {
          opacity: 1;
        }
      }
      .custom-checkbox {
        display: flex;
        align-items: center;
        font-size: 26px;
        width: 20px;
        height: 20px;
        color: darken(#bdcbf8, 20%);
        background: #ffffff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        &.selected {
          border: 2px solid #bdcbf8;
        }
      }
    }
    &_intervals {
      width: 100%;
      max-height: 45px;
      padding-right: 16px;
      margin-bottom: 12px;
      border-radius: 4px;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
      background-color: darken(#fcfcfc, 2%);
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      .interval {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 130px;
        padding: 0 8px;
        border-radius: 4px;
        border: 1px solid #495fd7;
        color: #f9f9f9;
        background-color: lighten(#495fd7, 5%);
        &:not(:last-child) {
          margin-right: 12px;
        }
        span {
          font-size: 14px;
          margin-right: 8px;
        }
        svg {
          margin-top: -2px;
          cursor: pointer;
          font-size: 18px;
        }
      }
    }
    &_add {
      width: 36px;
      height: 36px;
      margin-bottom: 12px;
      border: 2px solid #bdcbf8;
      border-radius: 4px;
      display: grid;
      place-items: center;
      svg {
        color: #bdcbf8;
        font-size: 20px;
      }
    }
  }
  &__addintervals {
    height: 414px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto;
    gap: 12px;
    &_label {
      height: 60px;
      color: #616261;
      font-size: 12px;
      font-weight: bold;
      .css-yk16xz-control {
        margin-top: 8px;
      }
    }
    &_buttons {
      grid-column: 1 / 3;
      display: flex;
      justify-content: center;
    }
  }
  &__buttons {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
  }
}
