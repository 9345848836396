.billing {
  &__content {
    &__success {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 40px 30px;
      min-height: 50vh;
      align-items: center;
      justify-content: center;
      .button__confirm {
        width: 330px;
        height: 40px;
        align-self: center;
        margin-bottom: 50px;
      }
      &__container {
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        .success-title {
          font-weight: bold;
          font-size: 24px;
          line-height: 48px;
          color: #818181;
          text-align: center;
          margin-bottom: 10px;
        }
        .success-description {
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          color: #bdbdbd;
          margin-bottom: 50px;
          width: 55vw;
        }
      }
    }
  }
}
