.form-invite-container {
	flex-grow: 1;
  background-color: white;
  width: 100%;
  padding: 60px;
  border-radius: 4px;
  &__emails {
  	position: relative;
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  	min-height: 350px;
  	background: #FFFFFF;
  	border: 1px solid #E0E0E0;
  	box-sizing: border-box;
  	border-radius: 4px;
  	margin-bottom: 4px;
  	.field {
  		background: #ffffff;
  		border-bottom: 1px solid #D9D9D9;
  		margin: 18px auto;
  		width: 95%;
  		height: 35px;
  		display: flex;
  		align-items: center;
  		color: #999;
  		p {
  			margin-bottom: 0;
  		}
  		input {
  		  flex-grow: 1;
  		  border: 0px;
  		  background-color: transparent;
  		  outline: none !important;
  		  height: 100%;
  		  padding-left: 10px;
  		  font-weight: normal;
  		  font-size: 14px;
  		  color: #616261;
  		  &::placeholder {
  		    font-size: 14px;
  		    color: #BDBDBD;
  		  }
  		}
  	}
  	.emails {
  		width: 95%;
  		margin-bottom: 18px;
  		display: flex;
  		flex-wrap: wrap;
  		.added-email {
  			background: #8093FC;
  			min-width: 140px;
  			height: 26px;
  			padding: 10px 6px;
  			border-radius: 4px;
  			margin: 4px;
  			display: flex;
  			justify-content: space-between;
  			align-items: center;
  			p {
  				margin: 0;
  				font-size: 14px;
  				color: #FFFFFF;
  			}
  			.close-invite {
  				font-size: 24px;
  				margin-top: 4px;
  				cursor: pointer;
  				color: #FFF;
  				&:hover {
  					transform: scale(1.05);
  					color: darken(#FFF, 5%);
  				}
  			}
  		}
  	}
  	.empty-emails {
  		margin-top: 10px;
  		display: flex;
  		flex-direction: column;
  		align-items: center;
  		img {
  			width: 50%;
  			margin-bottom: 12px;
  		}
  		p {
  			text-align: center;
  			font-weight: bold;
  			font-size: 18px;
  			color: #BDBDBD;
  		}
  	}
  }
	h3 {
		font-weight: bold;
		font-size: 20px;
		line-height: 21px;
		color: #616261;
	}
	label {
		font-weight: normal;
		font-size: 14px;
		line-height: 21px;
		color: #BDBDBD;
	}
	.error {
		font-size: 14px;
		position: absolute;
		left: 0;
		bottom: -7%;
		color: tomato;
		margin-bottom: 0;
	}
	.buttons-container {
		margin-top: 40px;
		display: flex;
		justify-content: space-between;
	}
}