%center-phone {
  display: grid;
  place-items: center;
}
.squeezepreview {
  @extend %center-phone;
  position: relative;
  width: 100%;
  height: 100%;
  &__close {
    position: absolute;
    top: 5px;
    right: 25px;
    cursor: pointer;
    font-size: 28px;
  }
  &__border {
    @extend %center-phone;
    width: 375px;
    height: 736px;
    padding: 4px;
    border-radius: 45px;
    background-color: #fff;
    border: 1px solid rgba(128, 128, 128, 0.4);
  }
  &__background {
    overflow: hidden;
    display: grid;
    grid-template-rows: auto minmax(592px, 1fr) auto;
    width: 100%;
    height: 100%;
    border-radius: 40px;
    border: 1px solid rgba(128, 128, 128, 0.6);
    &_title {
      background-color: #ffeaf1;
      text-align: center;
      font-size: 16px;
      padding: 12px 0;
      margin: 0;
    }
  }
  &__animation {
    background-color: #ffeaf1;
    svg {
      image {
        display: none !important;
      }
    }
  }
  &__counter {
    padding-top: 8px;
    &_value {
      text-align: center;
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 4px;
    }
  }
  &__actions {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
    &_action {
      font-weight: 700;
      margin: 0;
      &:nth-child(2) {
        background-color: #ffeaf1;
        padding: 4px 20px;
        border-radius: 16px;
      }
      &:not(:nth-child(2)) {
        font-size: 12px;
        color: rgba(128, 128, 128, 0.6);
      }
    }
  }
}
