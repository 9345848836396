.booking-title {
  font-family: 'SF Pro Text';
  font-weight: 700;
  font-size: 20px;
  line-height: 36px;
  color: #616261;
  text-align: center;
}
.booking-description {
  font-size: 14px;
  color: #a1a2a1;
  text-align: center;
  margin-bottom: 30px;
}
.booking-form {
  &__btns {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 40px;
  }
  .css-9f5dsg-menu {
    div {
      max-height: 230px;
    }
  }
}
