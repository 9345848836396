.patients {
  &__container {
    width: 100%;
    height: 100%;
    .search-box {
      background: #ffffff;
      border: 1px solid #cbe6e0;
      box-sizing: border-box;
      border-radius: 10px;
      margin-bottom: 20px;
      max-width: 40%;
      height: 40px;
      display: flex;
      &.covenants {
        width: 40%;
      }
      input {
        flex-grow: 1;
        border: 0px;
        background-color: transparent;
        outline: none !important;
        height: 100%;
        padding-left: 16px;
        font-weight: normal;
        font-size: 14px;
        color: #616261;
        &::placeholder {
          color: #e0e0e0;
        }
      }
      .icon {
        margin-left: 12px;
        margin-right: 12px;
        width: 18px;
        height: 18px;
        align-self: center;
      }
    }
    table {
      width: 100%;
      display: table !important;
      height: 57vh;
      thead {
        background-color: #65dfb6;
        border-radius: 10px;
        padding-top: 12px;
        padding-bottom: 12px;
        display: block !important;
        width: 100%;
        margin-bottom: 10px;
        tr {
          display: flex !important;
          width: 100%;
          th {
            text-align: center;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            color: #ffffff;
          }
        }
      }
      tbody {
        border: 1px solid #cbe6e0;
        display: block !important;
        width: 100%;
        border-radius: 10px;
        height: 100%;
        max-height: 100%;
        overflow-y: scroll;
        tr {
          display: flex !important;
          width: 100%;
          margin-bottom: 8px;
          margin-top: 8px;
          background-color: #fcfcfc;
          &.empty-state {
            margin: 0px !important;
            height: 100%;
            margin: 0px;
            background-color: #f9faff;
            td {
              padding: 0px;
              text-align: left;
            }
            &:hover {
              background-color: #f9faff;
            }
          }
          td {
            text-align: center;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            color: #616261;
            padding-top: 12px;
            padding-bottom: 12px;
            margin-top: auto;
            margin-bottom: auto;
          }
          &:hover {
            background-color: lighten(#cbe6e0, 10%);
          }
        }
      }
    }
    .status {
      border-radius: 10px;
      border: 1px solid #bdbdbd !important;
      color: #bdbdbd !important;
      width: 90%;
      font-size: 14px;
      font-weight: bold;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-left: 10%;
      cursor: pointer;
      &.active {
        color: #6fcf97 !important;
        border: 1px solid #6fcf97 !important;
        &:hover,
        &:active {
          color: darken(#6fcf97, 20%) !important;
          border: 1px solid darken(#6fcf97, 10%) !important;
        }
      }
      &:hover,
      &:active {
        color: darken(#bdbdbd, 20%) !important;
        border: 1px solid darken(#bdbdbd, 10%) !important;
      }
    }
    .actions-col {
      width: 10% !important;
      .button {
        max-width: 90% !important;
        padding: 5px 12px;
        border-radius: 10px;
        font-size: 14px;
      }
    }
  }
  &__no-results,
  &__empty-state {
    width: 100%;
    padding: 60px;
    background: white;
    background-color: #f9faff;
    border: 0.5px solid #cbe6e0;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      max-width: 40%;
      margin-right: 60px;
      font-family: 'SF Pro Text' !important;
      .title {
        font-size: 24px;
        line-height: 36px;
        font-weight: bold;
        color: #616261;
      }
      .subtitle {
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #bdbdbd;
        margin-bottom: 40px;
      }
    }
    .image {
      width: 30% !important;
    }
    .icon {
      margin-top: -2px;
    }
  }
  &__no-results {
    border: 0px !important;
    margin-bottom: 0px !important;
    max-height: 100%;
    padding: 50px;
    .image {
      width: 30%;
    }
  }
}
.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.pagination {
  margin-top: 65px;
  width: 100%;
  display: flex !important;
  flex-direction: row !important;
  .page-item {
    font-size: 14px;
    color: #bdbdbd;
    width: 24px;
    height: 24px;
    text-align: center;
    margin: 8px;
    cursor: pointer;
    &.active {
      color: #495fd7;
      font-weight: bold;
      &:hover {
        color: darken(#495fd7, 5%);
      }
    }
    &:hover {
      color: darken(#bdbdbd, 10%);
    }
    &.arrow {
      color: #8093fc;
      font-size: 30px;
      margin-top: 0px;
      &:hover {
        color: darken(#8093fc, 10%);
      }
    }
  }
}

.covenant-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.span-covenant-description {
  .form__error {
    display: none !important;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff !important;
}
