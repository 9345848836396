.image-input {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  max-width: 365px;
  max-height: 150px;
  padding: 24px;
  border: 0.3px dashed #ea7dae;
  border-radius: 12px;
  margin: 0 auto 12px;
  box-shadow: 0px 4px 4px rgba(223, 230, 255, 0.3);
  &__bg {
    display: grid;
    place-items: center;
    width: 150px;
    height: 100px;
    border-radius: 12px;
    background-color: #f2e4d3;
    background-size: cover;
    &_icon {
      color: #76453c;
      font-size: 24px;
    }
  }
  &__add {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    &_label {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
    }
  }
}
