.order-detail {
  min-width: 576px;
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 30px 0;
  }
  &__column {
    display: flex;
    flex-direction: column;
    span {
      color: #616261;
      font-weight: 700;
      &:not(:first-child) {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  &__footer {
    padding-top: 20px;
    border-top: 1px solid #999;
    display: flex;
    justify-content: flex-end;
  }
}
