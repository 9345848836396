.signup {
  &__terms {
    display: flex;
    flex-direction: column;
    .logo-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      .logo {
        width: 99px;
        height: 75px;
      }
    }
    .title {
      font-weight: bold;
      font-size: 24px;
      width: 100%;
      text-align: center;
      color: #616261;
      margin: 20px 0px 20px 0px;
    }
    .container-xl {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding-left: 8vw;
      padding-right: 8vw;
      margin-bottom: 30px;
      .term-list {
        padding-left: 2vw;
      }
    }
    .container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      max-height: 280px;
      height: 280px;
      overflow-y: scroll;
      overflow-x: scroll;
      padding-left: 80px;
      padding-right: 80px;
    }
    .term-title {
      font-weight: bold;
      font-size: 16px;
      color: #818181;
      text-align: center;
      margin: 10px;
    }
    .term-content {
      font-weight: normal;
      font-size: 14px;
      color: #909090;
      text-align: justify;
    }
    .term-list {
      list-style: disc;
      color: #909090;
      padding-left: 30px;
    }
    .footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 60px;
      margin-top: 35px;
      margin-bottom: 15px;
    }
    .license-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 80px;
      margin-right: 60px;
      margin-top: 35px;
      margin-bottom: 15px;
      .checkbox {
        font-weight: normal;
        font-size: 14px;
        color: #818181;
        font-weight: bold;
        input {
          margin-right: 10px;
        }
      }
      .disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
  &__intro {
    display: flex;
    flex-direction: row;
    height: 100%;
    .left {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      padding: 70px;
      max-width: 60%;
      .title {
        font-weight: bold;
        font-size: 36px;
        color: #616261;
        line-height: 40px;
        margin-bottom: 24px;
      }
      .message {
        font-family: 'SF Pro Text', 'SF Pro Display', Verdana;
        font-weight: normal;
        font-size: 14px;
        color: #818181;
        margin-bottom: 40px;
      }
      &__buttons {
        display: flex;
      }
    }
    .right {
      width: 40%;
      background-color: #cbe6e0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    &__image {
      width: 80%;
    }
  }
  &__profile {
    display: flex;
    flex-direction: row;
    height: 100%;
    .left {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 30%;
      width: 30%;
      padding: 31px;
      padding-top: 50px;
      .user-avatar {
        margin-top: 32px;
        margin-bottom: 32px;
      }
      .camera-button {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        background-color: #495fd7;
        border: 5px solid #ffffff;
        cursor: pointer;
        margin-left: 60%;
        margin-top: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          color: #fff;
          font-size: 40px;
        }
        &:hover {
          background-color: darken(#495fd7, 5%);
        }
      }
      label {
        font-weight: bold;
        font-size: 12px;
        color: #818181 !important;
        width: 100%;
      }
      .description-field {
        background-color: #f9faff;
        border: 1px solid #cbe6e0;
        border-radius: 5px;
        font-weight: normal;
        font-size: 14px;
        color: #818181;
        &.is-invalid {
          border-color: #dc3545;
        }
      }
      .form__error {
        display: none !important;
      }
    }
    .right {
      width: 60%;
      flex-grow: 1;
      background-color: #f9faff;
      border-left: 1px solid #cbe6e0;
      display: flex;
      height: 100%;
      overflow-y: scroll;
      padding: 50px;
      padding-right: 130px;
      flex-direction: column;
      label {
        font-weight: normal;
        font-size: 14px;
        color: #616261;
      }
      .form-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-left: 2px;
        margin-bottom: 5px;
        .form__input-container {
          flex-grow: 1;
          margin-bottom: 15px;
          &:last-child {
            margin-left: 25px;
          }
        }
      }
      .button-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
      }
    }
  }
}

.change-password-company {
  margin-top: 50px;
  .item-label {
    font-size: 14px;
    color: #bdbdbd;
  }
  .item-value {
    &.password {
      letter-spacing: 2px !important;
    }
  }
}

#signup_modal,
#trial_modal {
  &.ReactModal__Content {
    padding: 0px !important;
  }
}
