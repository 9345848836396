.invoices {
  padding: 14px 30px;
  height: 100%;
  &__header {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    width: 100%;
    max-width: 768px;
    padding: 8px 0;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-bottom: 14px;
    background: #f9faff;
    &.reimbursement {
      max-width: 320px;
    }
    &_info {
      padding-top: 4px;
      padding-left: 20px;
      .amount {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
        margin-bottom: 12px;
        &__label {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          &_title {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 12px;
            line-height: 21px;
            color: #818181;
          }
          &_value {
            font-weight: 700;
            font-size: 22px;
            line-height: 21px;
            color: #2c3980;
            &.due-date {
              font-size: 14px;
            }
          }
        }
      }
      .status-count {
        @extend .amount;
        &__label {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          &_count {
            font-size: 16px;
            line-height: 21px;
            color: #2c3980;
          }
          &_status {
            background-color: transparent;
            text-align: center;
            font-size: 14px;
            padding: 2px 24px;
            border-radius: 4px;
            color: tomato;
            &.paid {
              color: #46c2ad;
              background-color: rgba(70, 194, 173, 0.2);
            }
            &.draft {
              color: #ffbb6a;
              background-color: rgba(255, 187, 106, 0.2);
            }
            &.pending_void {
              color: #8093fc;
              background-color: rgba(128, 147, 252, 0.2);
            }
          }
        }
      }
    }
    &_payment {
      display: grid;
      place-items: center;
      padding-right: 20px;
      padding-left: 20px;
      border-left: 1px solid #d9d9d9;
    }
  }
  &__filters {
    display: flex;
    align-items: flex-end;
    gap: 16px;
    margin-bottom: 16px;
    &_label {
      min-width: 160px;
      height: 49px;
      color: #616261;
      font-size: 12px;
      font-weight: bold;
      .css-yk16xz-control {
        margin-top: 2px;
      }
    }
  }
  &__table {
    display: table;
    width: 100%;
    margin-bottom: 16px;
    &_head {
      display: block;
      padding: 12px 0 12px 28px;
      border-radius: 6px 6px 0 0;
      background: #f9faff;
      box-shadow: inset 0px -1px 0px #d9d9d9;
      .head-row {
        display: flex;
        th {
          font-size: 16px;
          font-weight: 700;
          color: #616261;
        }
        &__download {
          text-align: center;
        }
      }
    }
    &_body {
      display: block;
      max-height: calc(100vh - 450px);
      overflow-y: auto;
      .body-row {
        display: flex;
        padding: 12px 0 12px 28px;
        &:nth-child(even) {
          background-color: #f9faff;
        }
        td {
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #616261;
        }
        &__amount {
          font-weight: 700;
        }
        &__download {
          justify-content: center;
          svg {
            cursor: pointer;
            color: #8093fc;
            font-size: 20px;
          }
          &_mark {
            padding: 4px 12px;
            border-radius: 6px;
            color: #8093fc;
            font-size: 14px;
            font-weight: 700;
            background-color: rgba(128, 147, 252, 0.3);
          }
        }
      }
    }
  }
}
