.plan-view {
  &__modal {
    .close-icon-container {
      width: 100%;
      text-align: right;
      .close-icon {
        font-size: 28px;
        cursor: pointer;
        color: #616261;
        margin-right: -25px !important;
        margin-top: 5px;
        &:hover {
          color: #495fd7;
        }
      }
    }
    &__title {
      width: 100%;
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #616261;
      margin-top: -10px;
      margin-bottom: 30px;
    }
    &__content {
      height: 70vh;
      max-height: 70vh;
      display: flex;
      flex-direction: row;
      min-width: 100%;
    }
  }
}
