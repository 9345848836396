.create-audio {
  padding: 20px 32px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(375px, 1fr));
  gap: 8px;
  &__player {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px 0;
    border-radius: 4px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.14);
  }
  &__buttons {
    padding-bottom: 16px;
    display: flex;
    justify-content: center;
  }
}
