.product-detail {
  &__content {
    margin-bottom: 16px;
    &--item {
      color: #616261;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 2px;
      img {
        width: 20px;
        height: 20px;
        margin-left: 4px;
      }
    }
    &--description {
      max-width: 576px;
      font-size: 14px;
      color: #616261;
      &.unactive {
        font-weight: bold;
        color: #F39090;
      }
      &.active {
        font-weight: bold;
        color: #46C2AD;
      }
    }
    &--image {
      width: 320px;
      height: 250px;
      border-radius: 4px;
      margin-top: 5px;
      object-fit: cover;
    }
    &--status {
      font-size: 14px;
      margin: 0;
    }
  }
}