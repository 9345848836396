.forms-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: 24px;
      line-height: 36px;
      font-weight: bold;
      color: #616261;
      margin-bottom: 5px;
    }
    .subtitle {
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #bdbdbd;
    }
    &-image {
      width: 60%;
    }
  }
}
.select-forms-container {
  padding-bottom: 16px;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 10px;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      font-size: 24px;
      margin-top: -8px;
      cursor: pointer;
    }
  }
  .title {
    font-weight: bold;
    font-size: 20px;
    color: #616261;
  }
  label {
    font-size: 14px;
    color: #bdbdbd;
  }
}
.no-form-selected {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  &--survey {
    padding: 20px 0;
  }
  img {
    width: 50%;
    height: auto;
    margin-left: 20px;
  }
}
.followup-buttons {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
