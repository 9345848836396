$label-color: #616261;
$hover-color: lighten(#495fd7, 8%);

.reading {
  width: 100%;
  height: 100%;
  padding: 20px;
  &__description {
    max-width: 576px;
  }
  &__button {
    margin-top: -15px;
    display: flex;
    justify-content: flex-end;
  }
  &__editor {
    margin-top: 20px;
  }
}

.builder {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr minmax(300px, 30%);
  background-color: #f5f6f9;
  &__blocks {
    position: relative;
    &_mask {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      pointer-events: none;
    }
  }
  &__title {
    padding: 20px;
    margin: 20px 20px 16px;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    gap: 12px;
    align-items: center;
    &_back {
      flex-shrink: 0;
      font-size: 22px;
      color: $label-color;
      cursor: pointer;
    }
    &_prevBtn {
      flex-shrink: 0;
      cursor: pointer;
      font-size: 24px;
      color: $label-color;
      &:hover {
        color: $hover-color;
      }
    }
    &_label {
      margin: 0;
      font-family: 'SF Pro Text';
      font-weight: 500;
      font-size: 20px;
      color: $label-color;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      &:hover {
        color: $hover-color;
      }
    }
    &_modal-label {
      margin: 0 0 16px;
      text-align: center;
      font-weight: 700;
      font-size: 20px;
      color: $label-color;
    }
    &_btns {
      display: flex;
      justify-content: center;
    }
  }
  &__blockOptions {
    max-height: calc(100vh - 95px);
    padding: 20px;
    border-left: 1px solid #dce0e5;
    overflow-y: auto;
    &_label {
      font-weight: 700;
      font-size: 20px;
      color: $label-color;
      margin: 0;
    }
    &_description {
      font-family: 'SF Pro Text';
      font-size: 14px;
      color: $label-color;
      margin-top: 8px;
    }
    &_form-title {
      font-weight: 700;
      font-size: 20px;
      color: $label-color;
    }
    &_form-type {
      font-size: 14px;
      color: $label-color;
    }
    &_checkbox-label {
      margin-left: 6px;
      font-size: 16px;
      color: $label-color;
    }
    &_text-input {
      min-height: 150px;
      margin-bottom: 16px;
    }
    .bulletList {
      margin-top: 16px;
      &__content {
        display: grid;
        grid-template-columns: 1fr minmax(25px, 5%);
        gap: 8px;
        color: #616261;
        margin: 0;
        &.content-title {
          margin-bottom: 24px;
        }
        &_title {
          font-family: 'SF Pro Text';
          font-size: 16px;
          margin: 0;
          font-weight: 500;
        }
        &_plus-icon {
          cursor: pointer;
          font-size: 20px;
          &:hover {
            color: $hover-color;
          }
        }
        &_trash-icon {
          cursor: pointer;
          font-size: 20px;
          margin-top: 8px;
          &:hover {
            color: lighten(tomato, 8%);
          }
        }
      }
    }
    .blockOptions {
      &__list {
        list-style-type: none;
        &_item {
          width: 100%;
          padding: 16px 8px;
          margin-bottom: 12px;
          border-radius: 4px;
          color: #7d7c8e;
          background-color: #fff;
          box-shadow: 1px 1px 2px 0 #d8d8d8;
          display: grid;
          gap: 4px;
          grid-template-columns: minmax(40px, 10%) 1fr minmax(40px, 10%);
          place-items: center;
          &.v-container {
            opacity: 0.5;
            pointer-events: none;
          }
          .input_icon {
            font-size: 20px;
          }
          &:hover {
            color: $hover-color;
            box-shadow: 1px 1px 2px 0px rgba(216, 216, 216, 0.6);
          }
        }
      }
    }
  }
}

.builder-blocks {
  max-height: calc(100vh - 225px);
  overflow-y: auto;
  padding: 20px;
  margin: 0 20px;
  border-radius: 4px;
  background-color: #fff;
  list-style-type: none;
}
.builder-block-card {
  display: grid;
  grid-template-columns: minmax(40px, 5%) 1fr minmax(40px, 10%);
  gap: 4px;
  padding: 20px 12px;
  margin-bottom: 20px;
  border-radius: 4px;
  color: #7d7c8e;
  box-shadow: 1px 1px 2px 0 #d8d8d8;
}
.builder-block-card-icon {
  align-self: center;
  font-size: 20px;
}
.builder-block-card-content {
  align-self: center;
  max-width: 614px;
}
.builder-block-card-label {
  font-family: 'SF Pro Text';
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.builder-block-card-type {
  font-size: 14px;
}
.builder-block-card-btns {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  color: #7d7c8e;
  box-shadow: 1px 1px 2px 0 #d8d8d8;
}
.builder-block-card-icons {
  cursor: pointer;
}
.builder-block-card-icons:hover {
  color: $hover-color;
}

.rich-text-editor {
  max-width: 1280px;
  min-height: 400px;
  border: 1px solid #f1f1f1;
  padding: 20px 10px;
  border-radius: 2px;
  margin: 0 auto;
}

.public-DraftStyleDefault-block {
  margin: 0 !important;
}

.rdw-editor-toolbar {
  max-width: 1280px;
  margin: 0 auto;
}
