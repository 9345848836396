.DayPickerInput {
  display: block;
  font-size: 0.75rem;
  position: relative;
  &-OverlayWrapper {
    position: absolute;
  }
  &-Overlay {
    left: 0;
    top: 100%;
    z-index: 1;
    position: absolute;
    background: white;
    box-shadow: 0 2px 5px rgba(black, 0.15);
  }
  &--alt {
    display: inline-block;
    & > input {
      color: inherit !important;
      display: inline-block !important;
      flex: 1;
      font-size: inherit !important;
      height: 26px;
      line-height: inherit !important;
      margin: -4px -5px;
      padding: 4px;
      width: calc(100% + 5px) !important;
      &:focus {
        outline: none;
      }
      &.textarea {
        height: auto;
      }
    }
    .DayPickerInput-Overlay {
      left: -5px;
      top: calc(100% + 5px);
    }
  }
}
