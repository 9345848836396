.form {
  &__label {
    display: block;
    color: #616261;
    text-align: left;
    font-size: 0.8rem;
    font-weight: bold;
  }
  &__radio {
    display: block;
    color: #86888f;
    text-align: left;
    font-size: 0.8rem;
  }

  &__input {
    border: 1px solid #e0e0e0;
    width: 100%;
    color: #86888f;
    font-size: 0.85rem;
    font-weight: normal;
    padding: 7px;
    padding-left: 10px;
    border-radius: 5px;
    outline: none !important;
    background-color: #fff;

    &-container {
      position: relative;
      margin-bottom: 35px;
      &-select {
        //max-width: 400px;
        div {
          border: none !important;
          &:active,
          &:focus,
          &:hover {
            outline: none !important;
            border-color: #8093fc !important;
          }
        }
        > div {
          border: 1px solid #e0e0e0 !important;
        }
      }
      .password-button {
        position: absolute;
        right: 5px;
        top: 34px;
        .icon {
          font-size: 30px;
          color: #495fd7;
          cursor: pointer;
          &:hover {
            color: darken(#495fd7, 10%);
          }
        }
      }
    }
    &:active,
    &:focus {
      outline: none !important;
      border-color: #8093fc;
    }
    &:hover {
      outline: none !important;
      border-color: #8093fc;
    }
    &::placeholder {
      color: #bdbdbd;
    }
    &.field-block {
      display: none;
    }
  }
  &__input-select {
    border: 1px solid #e0e0e0 !important;
    width: 100%;
    color: #86888f;
    font-size: 0.85rem;
    font-weight: normal;
    border-radius: 5px;
    outline: none !important;
    background-color: #fff;
  }
  &__error {
    font-size: 14px;
    color: #ee7466 !important;
    position: absolute;
    left: 0;
    width: 95%;
    text-align: left;
    &_dropfiles {
      top: 115px;
    }
  }
  &__file-container {
    display: flex;
  }
  &__accepted-files {
    width: 50%;
    padding-left: 20px;
    font-size: 14px;
    list-style-type: none;
    color: #86888f;
    &_file {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: center;
    }
  }
}

.range-container {
  display: flex;
  flex-direction: row;
  width: 90%;
  .range-item {
    flex-grow: 1;
    text-align: center;
    border: 0.5px solid #e5e5e5;
    border-left-width: 0px;
    color: #bdbdbd;
    font-size: 0.85rem;
    cursor: pointer;
    padding-top: 3px;
    padding-bottom: 3px;
    background-color: #fff;
    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-left-width: 0.5px;
    }
    &:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
  .active {
    border: 0.5px solid #495fd7;
    background-color: #495fd7;
    color: #fff;
  }
}

.textarea {
  position: relative;
  margin-top: -36px;
  &__error {
    position: absolute;
    top: 65px;
  }
}

.position {
  position: absolute;
  z-index: 1;
  resize: none;
  padding-right: 15px;
}

.shadow {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.09);
}

input[type='color'] {
  width: 35px;
  height: 35px;
  padding: 10px;
  cursor: pointer;
}
input[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type='color']::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
}
