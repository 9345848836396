.content__container_plan {
  flex: 1;
  width: 100%;
  padding: 35px;
}
.plans-view-create-plan-form {
  display: flex;
  align-items: center;
  &__fields {
    flex: 1;
    display: grid;
    align-items: center;
    column-gap: 8px;
    row-gap: 28px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    margin-right: 16px;
  }
  &__description {
    position: relative;
    align-self: center;
    margin-top: -8px;
    &_icon {
      z-index: 1;
      top: -2px;
      right: 4px;
      position: absolute;
      font-size: 24px;
      cursor: pointer;
      color: #e0e0e0;
      &:hover {
        color: #8093fc;
      }
    }
  }
  &__buttons {
    display: flex;
    margin-top: -8px;
  }
}
