.react-autosuggest {
  &__container {
    position: relative;
    width: 100%;
    & input {
      border: none;
      border: 1px solid #e0e0e0;
      background: #fff;
      width: 100%;
      color: #86888f;
      font-size: 0.85rem;
      padding: 6px;
      padding-left: 10px;
      border-radius: 5px;
      outline: none !important;
      &:active,
      &:focus {
        outline: none !important;
        border-color: #8093fc;
      }
      &:hover {
        outline: none !important;
      }
      &::placeholder {
        color: #86888f;
      }
    }
  }

  &__suggestions-container {
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 0 0 4px 4px;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 2;
    max-height: 300px;
    overflow-y: scroll;
    &--open {
      display: block;
    }
  }

  &__suggestions-list {
    list-style: none;
    padding: 7px 0;
    margin: 0;
  }

  &__suggestion {
    color: #818181;
    font-weight: normal;
    padding: 5px 12px;
    font-size: 0.85rem;
    cursor: pointer;
    &--highlighted {
      background-color: rgba(#818181, 0.24);
    }
    &:hover {
      background-color: #8093fc;
      color: #fff;
    }
  }
}
