.password {
  &__modal {
    display: flex;
    flex-direction: column;
    padding: 30px;
    .close-icon-container {
      width: 100%;
      text-align: right;
      .close-icon {
        font-size: 32px;
        cursor: pointer;
        color: #616261;
        margin-right: -25px !important;
        margin-top: 5px;
        &:hover {
          color: #495fd7;
        }
      }
    }
    &__title {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 41px;
      .title {
        font-size: 24px;
        font-weight: bold;
        color: #8093fc;
        margin-bottom: 0px;
      }
      .icon {
        font-size: 28px;
        color: #495fd7;
        margin-right: 16px;
      }
    }
    &__content {
      display: flex;
      flex-direction: row;
      .form {
        display: flex;
        flex-direction: column;
        width: 50%;
        max-width: 50%;
        label {
          font-weight: normal;
          font-size: 14px;
          color: #616261;
        }
        .button-bar {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;
          .password-button {
            border: 0px;
            background-color: transparent;
            font-weight: normal;
            color: #616261;
            font-size: 14px;
            display: flex;
            flex-direction: row;
            padding-right: 0px;
            &:hover {
              color: darken(#616261, 5%);
              .icon-container {
                background-color: darken(#ecefff, 2%);
                .icon {
                  color: darken(#495fd7, 2%);
                }
              }
            }
            .icon-container {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              background-color: #ecefff;
              margin-right: 7px;
              margin-top: -2px;
              .icon {
                margin-top: 6px;
                margin-left: 6px;
                color: #495fd7;
                font-size: 20px;
              }
            }
          }
        }
      }
      .feedback {
        display: flex;
        flex-direction: column;
        margin-left: 48px;
        .feedback-item {
          display: flex;
          flex-direction: row;
          .label {
            font-weight: normal;
            font-size: 14px;
            color: #818181;
            margin-left: 16px;
            &.checked {
              color: #bdbdbd;
            }
          }
          .icon {
            font-size: 24px;
            color: #e0e0e0;
            &.checked {
              color: #65dfb6;
            }
          }
        }
      }
    }
  }
}
