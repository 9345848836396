.login {
  &__note {
    font-size: 0.85rem;
    &--lighted {
      color: #495fd7;
      text-decoration: underline;
      &:hover {
        color: darken(#495fd7, 10%);
      }
    }
  }
}

.forgot-password {
  position: relative;
  button {
    position: absolute;
    top: 0;
    left: 70px;
    opacity: 0.6;
    text-decoration: underline;
    font-size: 14px;
    color: #484848;
    &:hover {
      opacity: 0.8;
    }
  }
}

.login-button {
  display: flex;
  justify-content: center;
}

.send-email-message {
  color: #484848;
  font-size: 14px;
}

.send-email-buttons {
  display: flex;
  justify-content: center;
}

.login-label {
  font-weight: 400;
  color: #2c3980;
}
