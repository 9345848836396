$background-range: linear-gradient(90deg, #ffbfc8, #ff5ca9);

.input-container {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  color: #fff;
  &__label {
    margin-bottom: 5px;
  }
  &__input {
    width: 100%;
    margin-bottom: 5px;
    min-height: 30px;
    padding: 5px 10px;
    border: 1px solid #999;
    outline: none;
    resize: none;
    color: #fff;
    letter-spacing: 0.5px;
    background-color: hsla(0, 0%, 100%, 0.2);
    &:focus {
      border: 2px solid #333;
    }
    &::placeholder {
      color: #999;
    }
  }
  &__error {
    color: red;
    font-weight: lighter;
    font-size: 14px;
  }
}

.evie-range {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  &__numbers {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    width: 100%;
    padding: 0 0px;
    margin-bottom: 12px;
    span {
      color: #000;
      font-size: 14px;
      padding-left: 8px;
      &.active {
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
  &__input {
    width: 100%;
    height: 16px;
    background: $background-range;
    background-color: #ff5ca9;
    border-radius: 10px;
    outline: none;
    -webkit-appearance: none;
    appearance: none;
    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 16px;
      background: $background-range;
      border-radius: 12px;
    }
    &::-moz-range-track {
      width: 100%;
      height: 16px;
      background: $background-range;
      border-radius: 12px;
    }
    &::-ms-track {
      width: 100%;
      height: 16px;
      background: transparent;
      border-color: transparent;
      color: transparent;
    }
    &::-ms-fill-lower {
      background: $background-range;
      border-radius: 12px;
    }

    &::-ms-fill-upper {
      background: $background-range;
      border-radius: 12px;
    }
  }
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    outline: none;
    width: 28px;
    height: 28px;
    background-color: white;
    border: 5px solid #ff5ca9;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -6px;
  }

  input[type='range']::-moz-range-thumb {
    outline: none;
    width: 28px;
    height: 28px;
    background-color: white;
    border: 5px solid #ff5ca9;
    border-radius: 50%;
    cursor: pointer;
  }

  input[type='range']::-ms-thumb {
    outline: none;
    width: 28px;
    height: 28px;
    background-color: white;
    border: 5px solid #ff5ca9;
    border-radius: 50%;
    cursor: pointer;
  }
}
