.pay-step-list {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.pay-step-line {
  width: 100px;
  border: 3px dashed #bdbdbd;
  margin-left: 12px;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 3px;
  border-radius: 2px;
}
.pay-step-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 30px;
  .pay-step {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 4px solid #bdbdbd;
  }
  .pay-step-name {
    line-height: 30px;
    margin-bottom: 0px;
    margin-left: 10px;
    font-size: 14px;
    color: #bdbdbd;
  }
  .checked-icon {
    color: #fff;
    width: 24px;
    height: 24px;
    margin-top: -2px;
    margin-left: -1px;
    border-radius: 50%;
  }
}
.pay-step-checked {
  border-color: #6fcf97 !important;
  background-color: #6fcf97 !important;
}
.pay-step-checked-line {
  border-color: #6fcf97 !important;
}
.pay-step-current {
  border-color: #6fcf97 !important;
}
.pay-step-current-text {
  font-weight: 700;
  color: #818181 !important;
}
