$base-paddng: 10px;
$base-color: #606060;

%shadow {
  -webkit-box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.14);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.14);  
}

.edit-zone {
  display: flex;
  &__picker {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #F9FAFF;
    padding: 50px 20px;
    width: 65%;
    &__title {
      font-weight: bold;
      font-size: 24px;
      color: $base-color;
      margin-bottom: 20px;
      p {
        margin-bottom: 0;
        &:last-child {
          font-weight: normal;
          font-size: 16px;
          color: #BDBDBD;
        }
      }
    }
    &__colors {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
    &__dropzone {
      margin: 20px 0;
      .drop-prev-card {
        padding: $base-paddng;
        display: flex;
        background: #fff;
        border-radius: 6px;
        border: none;
        @extend %shadow;
        &__drop {
          width: 50%;
          padding: $base-paddng 25px;
          &_title {
            font-weight: bold;
            font-size: 18px;
            color: $base-color;
          }
        }
        &__preview {
          width: 50%;
          padding: $base-paddng 25px;
          &_title {
            @extend .drop-prev-card__drop_title;
          }
          &__thumbs {
            display: flex;
            align-items: center;
            height: 125px;
            border-radius: 6px;
            @extend %shadow;
            .thumb {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              padding: $base-paddng;
              &__image {
                width: 110px;
                height: 100px;
                img {
                  object-fit: contain;
                  width: 100%;
                  height: 100%;
                }
              }
              &__shadow {
                width: 65%;
                padding: $base-paddng;
                div {
                  width: 100%;
                  height: 8px;
                  background: #cacaca;
                  border: 8px solid #cacaca;
                  border-radius: 10px;
                  margin-bottom: 10px;
                  &:last-child {
                    width: 80%;
                  }
                }
              }
            }
          }
        }
      }
    }
    .whitelabel-update {
      margin-left: auto;
    }
  }
}

.edit-zone__preview {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  border-right: 1px solid #D9D9D9;
  width: 35%;
  &__app {
    @extend %shadow;
    border-radius: 25px;
    width: 340px;
    height: 600px;
    &_header-icons {
      padding: $base-paddng $base-paddng 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;
      color: $base-color;
    }
    &_title {
      padding-left: $base-paddng;
      margin: 5px 0 0;
      color: $base-color;
      font-size: 26px;
      font-weight: bold; 
    }
    &_subtitle {
      padding-left: $base-paddng;
      font-size: 16px;
      font-weight: bold;
    }
    &__indicators {
      padding: 0 $base-paddng;
      display: flex;
      color: #fff;
      p {
        font-size: 14px;
        padding: 2px 12px;
        margin: 0 5px 0 0;
        border-radius: 10px;
      }
    }
    &__required {
      &_title {
        color: $base-color;
        padding-left: $base-paddng;
        margin-bottom: 5px;
      }
      &__items {
        padding-left: $base-paddng;
        display: flex;
        p {
          color: #fff;
          font-size: 14px;
          padding: 2px 12px;
          margin: 0 5px 0 0;
          border-radius: 10px;
        }
      }
    }
    &__exercise {
      display: flex;
      align-items: center;
      padding: 0 $base-paddng;
      &__image {
        width: 70px;
        height: 70px;
        margin-right: 10px;
        img {
          width: inherit;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      &__content {
        p {
          margin-bottom: 0;
          font-size: 14px;
          color: $base-color;
          &:first-child {
            font-weight: bold;  
          }
        }
      }
    }
    &_mimic-button {
      padding: 10px 0 10px;
      margin: 0 10px 15px;
      border-radius: 15px;
      color: #fff;
      text-align: center;
    }
    &__nav {
      display: flex;
      justify-content: space-between;
      padding: 15px 40px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      &__icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          margin: 0px 0 0;
          color: $base-color;
          font-size: 14px;
        }
      }
    }
  }
}

.close-white-label-modal {
  cursor: pointer;
  color: #495FD7;
  font-size: 24px;
  position: absolute;
  right: 7px;
}