.create-plan-form {
  width: 100%;
  img {
    width: 100%;
  }
  .image-container {
    width: 30%;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
    margin-bottom: 70px;
    margin-left: auto;
    margin-right: auto;
  }
  .types-selection {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    justify-content: center;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
}
