.new-employee-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.new-employee {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__logo {
    width: 140px;
    img {
      width: 100%;
      height: auto;
      display: block;
      object-fit: cover;
    }
  }
  &__form {
    position: relative;
    width: 95%;
    max-width: 514px;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    margin-top: 20px;
    &__button {
      margin: 0 auto;
    }
    &__succes {
      width: 95%;
      max-width: 514px;
      padding: 15px;
      border-radius: 4px;
      background-color: #46c2ad;
      opacity: 0;
      transition: opacity 300ms 200ms;
      p {
        text-align: center;
        color: darken(#fff, 4%);
        margin-bottom: 0;
      }
      &.sent {
        opacity: 1;
      }
    }
    &_phone {
      display: flex;
      align-items: center;
      .form__input-container-select {
        width: 120px;
        margin-right: 10px;
      }
      &--number {
        flex: 1;
        margin-top: 26px;
      }
    }
  }

  &__footer {
    flex-shrink: 0;
    color: #bdbdbd;
    font-size: 14px;
    padding: 15px 0;
  }
}
