$base-border: 1px solid #d9d9d9;
%video-title {
  text-align: center;
  font-size: 14px;
  color: #616261;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.active-pause {
  height: 100%;
  margin: 10px 20px;
  display: grid;
  grid-template-columns: 1fr minmax(320px, 30%);
  &__exercises {
    position: relative;
    &_title {
      font-weight: 700;
      font-size: 18px;
      color: #2c3980;
      margin-bottom: 12px;
    }
    &_filters {
      display: flex;
      align-items: center;
    }
    &_close {
      position: absolute;
      top: 4px;
      right: 24px;
      color: #2c3980;
      font-size: 24px;
      cursor: pointer;
    }
    .exercises-list {
      list-style-type: none;
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      height: calc(100vh - 215px);
      padding: 0 24px 8px 0;
      margin: 24px 0 0;
      overflow-y: auto;
      &__card {
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        position: relative;
        padding-bottom: 75%;
        height: 0;
        &:hover {
          .card-mask {
            opacity: 1;
          }
          .exercise-title_icon {
            opacity: 1;
          }
        }
        &_image {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 70%;
          object-fit: cover;
          border-radius: 4px 4px 0 0;
        }
        .exercise-title {
          position: absolute;
          bottom: 0;
          display: grid;
          place-items: center;
          width: 100%;
          height: 30%;
          padding: 10px 14px;
          border-radius: 0 0 4px 4px;
          border: $base-border;
          background-color: #f9faff;
          h3 {
            max-width: 100%;
            @extend %video-title;
          }
          &_icon {
            transition: 0.5s ease;
            opacity: 0;
            position: absolute;
            right: 2px;
            color: #2c3980;
            font-size: 14px;
            cursor: pointer;
          }
        }
        .card-mask {
          transition: 0.5s ease;
          opacity: 0;
          position: absolute;
          left: 0;
          top: 0;
          display: grid;
          place-items: center;
          width: 100%;
          height: 70%;
          background: rgba(73, 95, 215, 0.5);
          border-radius: 4px 4px 0 0;
          &_label {
            padding: 4px 28px;
            border: 1px solid #2c3980;
            border-radius: 10px;
            background: rgba(249, 250, 255, 0.2);
            font-size: 14px;
            font-weight: 600;
            color: #2c3980;
          }
          &--selected {
            pointer-events: none;
          }
        }
      }
    }
  }
  &__details {
    border: $base-border;
    border-radius: 4px;
    .form-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      border-bottom: $base-border;
      &_title {
        font-weight: 700;
        font-size: 18px;
        color: #2c3980;
        margin-right: 4px;
        margin-bottom: 0;
      }
    }
    .form-content {
      padding: 0 16px;
      &__file {
        display: flex;
        align-items: center;
        margin: 12px 0;
        &_img {
          width: 80px;
          height: 80px;
          border: 1px dashed #d9d9d9;
          border-radius: 4px;
          margin-right: 10px;
          background-color: rgba(189, 189, 189, 0.2);
          background-size: cover;
        }
        &_btn {
          padding: 6px 12px;
          font-size: 14px;
          color: #495fd7;
          background: #ced5fd;
          border-radius: 15px;
        }
      }
      &__description {
        margin: -12px 0 20px;
      }
      &__selectors {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &_parts {
          flex: 1;
          margin-left: 20px;
        }
      }
    }
    .videos {
      margin-top: -10px;
      &__title {
        padding-left: 16px;
        font-weight: 600;
        font-size: 16px;
        color: #2c3980;
      }
      &__list {
        list-style-type: none;
        height: calc(100vh - 630px);
        padding: 0 16px;
        margin-bottom: 0;
        overflow-y: auto;
        &_card {
          position: relative;
          display: grid;
          grid-template-columns: minmax(80px, 25%) 1fr;
          margin-bottom: 10px;
          border: $base-border;
          border-radius: 4px;
          background: #f9faff;
        }
        &_img {
          width: 100%;
          height: auto;
          object-fit: cover;
          border-radius: 4px 0 0 4px;
        }
        &_title {
          padding: 0 6px;
          margin: auto;
          @extend %video-title;
        }
        &_close {
          cursor: pointer;
          position: absolute;
          top: 8px;
          right: 4px;
          font-size: 20px;
          color: #2c3980;
        }
      }
    }
  }
}
