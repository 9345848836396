.create-week {
  &__container {
    padding: 20px 5px 25px 35px;
    position: relative !important;
    height: 100%;
  }
  &__search-panel {
    background: #ffffff;
    border: 1px solid #cbe6e0;
    border-radius: 10px;
    padding: 0 0 0 24px !important;
    height: 85vh;
    .search-bar {
      display: flex;
      align-items: center;
      padding: 20px 0;
      margin-bottom: 10px;
      &__filters {
        flex: 1;
        display: flex;
        align-items: center;
      }
      &__icon {
        margin-right: 20px;
        font-size: 28px;
        cursor: pointer;
        color: #616261;
        &:hover {
          color: #495fd7;
        }
      }
      .search-box {
        background: #ffffff;
        box-sizing: border-box;
        margin-bottom: 20px;
        width: 200px !important;
        height: 40px;
        display: flex;
        input {
          flex-grow: 1;
          border: 0px;
          background-color: transparent;
          outline: none !important;
          height: 100%;
          padding-left: 16px;
          font-weight: normal;
          font-size: 14px;
          color: #616261;
          &::placeholder {
            color: #e0e0e0;
          }
        }
        .icon {
          margin-left: 12px;
          margin-right: 12px;
          width: 18px;
          height: 18px;
          align-self: center;
        }
      }
    }
  }
  &__left {
    width: 100%;
    position: relative;
  }
  &__selected {
    height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 30px;
    &-item {
      background: #fcfcfc;
      border-radius: 5px;
      min-height: 26vh;
      display: flex;
      flex-direction: column;
      &:hover {
        background: #ffffff;
        box-sizing: border-box;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        .create-week__img {
          border: 1px solid #cbe6e0;
        }
        .info-container {
          border: 1px solid #cbe6e0;
          border-top: 0px;
          .detail-button {
            display: block;
          }
        }
        .create-week__img-btn {
          opacity: 1;
        }
      }
      .info-container {
        padding: 16px;
        border-radius: 0px 0px 5px 5px;
        flex-grow: 1;
        .title {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          font-weight: normal;
          font-size: 12px;
          color: #616261;
          margin-bottom: 0px;
        }
        .detail-button {
          display: none;
          margin-top: auto;
          margin-bottom: auto;
          margin-right: -8px;
          cursor: pointer;
          .icon {
            width: 18px;
            height: 18px;
            color: #495fd7;
          }
        }
      }
    }
  }
  &__img {
    cursor: pointer;
    border-radius: 5px 5px 0px 0px;
    border: 1px solid #cbe6e0;
    margin-bottom: 0px !important;
    &-container {
      position: relative;
    }
    &-btn {
      transition: 0.5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
      background: rgba(73, 95, 215, 0.5);
      width: 100%;
      height: 100%;
      border-radius: 5px 5px 0px 0px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      .add-button {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 15px;
        font-weight: bold;
        font-size: 14px;
        width: 50%;
        text-align: center;
        padding-top: 2px;
        padding-bottom: 2px;
        .icon {
          color: #fff;
          font-size: 18px;
          margin-right: 5px;
        }
      }
    }
  }
  &__week-panel {
    padding-right: 0px !important;
    height: 85vh;
    .card {
      border-radius: 10px;
      height: 100%;
      flex-shrink: 0 !important;
      border: 0px !important;
      .card-title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background: #f9faff;
        border: 1px solid #cbe6e0;
        border-radius: 10px 10px 0px 0px;
        padding: 20px 15px;
        justify-content: space-between;
        .card-title {
          font-weight: bold;
          font-size: 18px;
          font-weight: bold;
          color: #818181;
          margin-bottom: 0px;
        }
        .buttons {
          display: flex;
        }
      }
      .card-summary-container {
        border: 1px solid #cbe6e0;
        border-top: 0px;
        background: #f9faff;
        display: flex;
        flex-direction: row;
        .card-summary-item {
          width: 50%;
          text-align: center;
          font-weight: bold;
          font-size: 12px;
          line-height: 21px;
          color: #818181;
          margin-bottom: 0px;
          padding: 14px;
          height: 100%;
          vertical-align: middle;
          &:first-child {
            border-right: 1px solid #cbe6e0;
          }
          span {
            font-size: 18px;
            margin-right: 4px;
          }
        }
      }
      .show-rules-button {
        position: absolute;
        top: 10px;
        right: 10px;
      }
      .card-days-container {
        position: relative;
        border: 1px solid #cbe6e0;
        border-top: 0px;
        height: 100%;
        max-height: 100%;
        border-radius: 0px 0px 10px 10px;
        overflow-y: auto;
        .card-day {
          display: flex;
          flex-direction: row;
          .day-data {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            margin: 0px 10px 15px 6px;
            .day-title {
              margin-bottom: 0px;
              padding-top: 20px;
              font-size: 14px;
              color: #818181;
            }
          }
        }
      }
    }
  }
  &__select {
    max-width: 80px;
  }
}

.card-exercise-drag {
  background: #fcfcfc;
  border: 1px solid #cbe6e0;
  border-radius: 10px;
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  cursor: move;
}
.image-drag {
  width: 120px;
  min-width: 100px;
  height: auto;
  margin-top: -1px;
  margin-left: -1px;
  border: 1px solid #cbe6e0;
  border-radius: 10px 0px 0px 10px;
  object-fit: cover;
}
.info-drag {
  flex-grow: 1;
  padding: 15px;
  display: flex;
  flex-direction: column;
}
.title-drag {
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 14px;
  color: #616261;
  margin-top: -10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.close-icon-container-drag {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.close-icon-drag {
  margin-top: -10px !important;
  margin-right: -10px;
  font-size: 24px;
  color: #818181;
  cursor: pointer;
  &:hover {
    color: #495fd7;
  }
}

.content__fullwidth {
  height: 100% !important;
}

.label-select-reps {
  margin-bottom: 0;
  display: block;
  color: #616261;
  text-align: left;
  font-size: 14px;
}
