@font-face {
    font-family: 'SF Pro Text';
    src: url('../../assets/fonts/SF-Pro-Text-Bold.otf') format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'SF Pro Text';
    src: url('../../assets/fonts/SF-Pro-Text-Regular.otf') format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'SF Pro Text';
    src: url('../../assets/fonts/SF-Pro-Text-Semibold.otf') format('truetype');
    font-weight: 500;
  }

  @font-face {
    font-family: 'SF Pro Display';
    src: url('../../assets/fonts/SF-Pro-Display-Regular.otf') format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'SF Pro Display';
    src: url('../../assets/fonts/SF-Pro-Display-Bold.otf') format('truetype');
    font-weight: bold;
  }


  @keyframes smooth-appear {
    to{
      opacity:1;
    }
  }


html {
    width: 100%;
    height: 100%;
    font-family: 'SF Pro Display', 'Lucida Sans', 'Verdana', sans-serif !important;
    color: #1C1C1C;
    background-color: #F5EEE3;
}

.evie-main-container {
    background-color: #F5EEE3;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.evie-checkout-container {
    max-width: 1280px;
    margin: auto;
    display: flex;
    flex-direction: row;
    height: 100%;
}


@media (max-width: 1025px) {

    .evie-card {
        background-color: white;
        border-radius: unset !important;
        box-shadow: unset !important;
    }

    .evie-main-container {
        background-color: white !important;
    }

    html {
        background-color: white !important;
    }

    .evie-checkout-container {
        flex-direction: column;
        max-width: 760px;
    }

    .evie-main-card {
        width: 100% !important;
        padding: 25px !important;
        min-height: 100% !important;
        max-height: unset !important;
        margin-top: unset !important;
        margin-bottom: 340px !important;
    }

    .evie-right-card {
        width: 100% !important;
        padding: 25px !important;
        min-height: 100% !important;
        max-height: unset !important;
        margin-top: unset !important;
        margin-bottom: unset !important;
    }

    .evie-main-form {
        width: 100% !important;
    }

    .evie-banner {
        width: 100% !important;
    }

    .evie-logo {
        margin-top:40px;
    }

    .success-modal-container {
        width: 85vw !important;
        height: 70vh !important;
        padding: 20px !important;
    }

    .success-modal-image {
        height: 15vh !important;
    }
}


.evie-card {
    background-color: white;
    border-radius: 3px;
    box-shadow: 1px 1px 3px #76453C80;
}

.evie-main-card {
    margin-right: 15px;
    width: 70%;
    padding: 40px;
    height: fit-content;
    max-height: unset !important;
    margin-top: 40px;
    margin-bottom: 40px;
}

.evie-right-card {
    width: 30%;
    padding: 40px;
    height: fit-content;
    max-height: unset !important;
    margin-top: 40px;
    margin-bottom: 40px;
}

.evie-logo {
    width: 160px;
    margin-bottom: 4rem;
}

.evie-section-title {
    font-family: 'SF Pro Text', 'Lucida Sans', 'Verdana', sans-serif !important;
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 1.4rem;
}

.evie-main-form {
    width: 90%;
}

.evie-label {
    font-family: 'SF Pro Text', 'Lucida Sans', 'Verdana', sans-serif !important;
    margin-bottom: 0.3rem;
}

.evie-program-name {
    color: #9B2C51;
    font-weight: 500;
    font-size: 1.2rem;
    font-family: 'SF Pro Text', 'Lucida Sans', 'Verdana', sans-serif !important;
}

.evie-light-paragraph {
    color: #818181;
    font-weight: normal;
    font-size: 0.85rem;
    font-family: 'SF Pro Text', 'Lucida Sans', 'Verdana', sans-serif !important;
}

.evie-paragraph {
    color: #1c1c1c;
    font-weight: normal;
    font-size: 0.85rem;
    font-family: 'SF Pro Text', 'Lucida Sans', 'Verdana', sans-serif !important;
    margin-bottom: 0.7rem;
}

.evie-paragraph a {
    font-family: 'SF Pro Text', 'Lucida Sans', 'Verdana', sans-serif !important;
    font-weight: 500;
    text-decoration: none;
    color: #EA7DAE;
}

.evie-paragraph a:hover {
    color: #9B2C51;
}

.evie-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;
}

.evie-list-item p {
    margin-bottom: 0rem;
}

.evie-check {
    width: 18px;
    height: 18px;
    margin-right: 10px;
}

.evie-banner {
    width: 100%;
    margin-bottom: 20px;
}

.evie-price-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.evie-price-item.total {
    margin-bottom: 1rem;
}

.evie-price-item p.name {
    margin-bottom: 0rem;
    flex-grow: 1;
    font-size: 0.9rem;
}

.evie-price-item p.value {
    margin-bottom: 0rem;
    font-size: 0.9rem;
}

.evie-check-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 1rem;
}

.evie-checkbox {
  margin-top: 5px;
  margin-right: 10px;
}

.evie-button {
    background-color:  #9B2C51;
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.8rem;
    border-radius: 0.3rem;
    font-size: 0.9rem;
    font-weight: 500;
    font-family: 'SF Pro Text', 'Lucida Sans', 'Verdana', sans-serif !important;
    text-decoration: none;
    text-transform: uppercase;
}

.evie-email-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.evie-email-confirmed {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.evie-email-confirmed h3 {
    color: #1c1c1c80;
    font-weight: 500;
    font-size: 1rem;
    font-family: 'SF Pro Text', 'Lucida Sans', 'Verdana', sans-serif !important;
    flex-grow: 1;
}

.evie-button.small {
    width: 200px;
}

.evie-button.outline {
    border: 1px solid #9B2C51;
    background-color: white;
    color: #9B2C51;
}

.evie-button.outline:hover {
    border: 1px solid #9B2C51;
    background-color: #F5E5ED;
    color: #9B2C51;
}


.evie-button:disabled,
.evie-button[disabled]{
    opacity: 0.5;
    cursor:not-allowed;
}


.evie-button:hover {
    background-color:  #781f3c;
}

.evie-input {
    border-radius: 0.3rem;
    padding: 0.5rem 1rem;
    border: 1px solid #818181;
    box-sizing: border-box;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    width: 100%;
    outline: none;
    font-size: 0.9rem;
    font-weight: normal;
    font-family: 'SF Pro Text', 'Lucida Sans', 'Verdana', sans-serif !important;
    color:#1C1C1CD9;
}

.evie-input:focus {
    border: 1px solid #9B2C51 !important;
}

.evie-input.email-input {
    margin-bottom: 1rem;
}

.evie-payment-data-container {
    display: flex;
    flex-direction: column;
}

.evie-payment-data-container.disabled {
    opacity: 0.5;
}

.evie-payment-type {
    margin-bottom: 1rem;
}

.evie-input.inline {
    margin-right: 10px;
}

.discount-code {
    background-color: #FFEAF1;
    border-radius: 4px;
    padding: 4px 5px;
    font-size: 0.75rem;
    font-family: 'SF Pro Text', 'Lucida Sans', 'Verdana', sans-serif !important;
    font-weight: 500;
    color:  #781f3c;
}

.success-modal-overlay {
    background-color: #d9a9bed9;
    width: 100vw;
    height: 100vh !important;
    z-index: 99999999;
    position: absolute;
    top: 0;
    height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity:0;
    animation: smooth-appear 1s ease forwards;
}

.success-modal-container {
    width: 40vw;
    height: 50vh !important;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 80px 100px;
    align-items: center;
    justify-content: center;
}

.succes-modal-title {
    color: #9B2C51;
    font-weight: 500;
    font-size: 1.2rem;
    font-family: 'SF Pro Text', 'Lucida Sans', 'Verdana', sans-serif !important;
    text-align: center;
    margin-bottom: 0.5rem;
}

.succes-modal-message {
    color: #1c1c1c;
    font-weight: normal;
    font-size: 0.9rem;
    font-family: 'SF Pro Text', 'Lucida Sans', 'Verdana', sans-serif !important;
    margin-bottom: 1.5rem;
    text-align: center;
}

.success-modal-image {
    height: 19vh;
    margin-bottom: 1rem;
}

.evie-promo-code-error {
    color: #9B2C51;
}
