.library {
  &__content {
    background: #F5F6F9;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
  }
  &__list {
    padding: 32px;
    background: #F5F6F9;
    @media (min-width: 1600px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__tabs {
      max-width: 1600px;
      display: flex;
      flex-wrap: wrap;
      border-radius: 4px 4px 0 0;
      padding: 8px 30px;
      background-color: #FAFBFF;
      @media (max-width: 1024px) {
        padding: 8px 15px;
      }
      .tab {
        cursor: pointer;
        font-size: 18px;
        color: #2C3980;
        padding: 8px 30px;
        margin-bottom: 0;
        height: 40px;
        border-radius: 4px;
        &.selected-tab {
          background-color: #CED5FD;
        }
      }
    }
    &__exercises {
      width: 100%;
      max-width: 1600px;
      padding: 24px 80px 60px 24px;
      border-radius: 0 0 4px 4px;
      background-color: #fff;
      @media (min-width: 1600px) {
        padding-right: 120px;
      }
      @media (max-width: 1024px) {
        padding-right: 50px;
      }
      @media (max-width: 768px) {
        padding-right: 40px;
      }
      .exercise-container {
        display: flex;
        width: 100%;
        height: 231px;
        margin-bottom: 24px;
        @media (min-width: 1500px) {
          height: 286px;
        }
        @media (max-width: 1024px) {
          margin-bottom: 34px;
        }
        &__image {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          width: 35%;
          margin-right: 4%;
          cursor: pointer;
          &:hover {
            .list-play-icon {
              display: block;
            }  
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @media (max-width: 1180px) {
              height: auto;
            }
          }
          .list-play-icon {
            display: none;
            position: absolute;
            color: #fff;
            font-size: 30px; 
          }
        }
        &__info {
          width: 60%;
          &-title {
            font-size: 20px;
            line-height: 21px;
            font-weight: 600;
            color: #030303;
            @media (max-width: 768px) {
              font-size: 16px;
              margin-bottom: 8px;
            }
          }
          &-parts {
            overflow-y: hidden;
            display: flex;
            flex-wrap: wrap;
            list-style-type: none;
            max-height: 80px;
            margin-bottom: 10px;
            @media (max-width: 768px) {
              margin-bottom: 0;
            }
            &.shrink-bdy-list {
              overflow-y: scroll;
            }
            li {
              padding: 8px 15px;
              border-radius: 4px;
              margin-right: 6px;
              margin-bottom: 5px;
              font-size: 13px;
              background-color: #F2F4FF;
              color: #606060;
            }
          }
          &-instructions {
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            color: #030303;
            margin-bottom: 5px;
          }
          ol {
            height: 70px;
            overflow: hidden;
            list-style-type: none;
            @media (max-width: 1024px) {
              margin-bottom: 8px;
            }
            &.shrink-list {
              height: 30px;
              @media (max-width: 1024px) {
                height: 40px;
              }
            }
            li {
              font-weight: normal;
              font-size: 14px;
              margin-bottom: 5px;
              color: #606060;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
          &-more {
            cursor: pointer;
            color: #8093FC;
            font-size: 16px;
          }
        }
      }
    }
    .selected-exercise-container {
      display: flex;
      width: 100%;
      max-width: 1600px;
      height: 785px;
      @media (max-width: 1024px) {
        height: 875px;
      }
    }
  }
  &__row {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 25px;
    margin-bottom: 35px;
    &__title {
      font-weight: bold;
      font-size: 24px;
      color: #030303;
      margin-bottom: 20px;
      margin-left: 30px;
    }
    &__slider {
      padding-bottom: 10px;
    }
    &__carousel {
      display: flex;
      flex-direction: row;
      overflow: scroll;
      margin-right: 60px;
      margin-left: 70px;
      padding-left: 10px;
      padding-bottom: 10px;
      &::-webkit-scrollbar {
        display: none;
      }
      &.locked {
        overflow: hidden !important;
      }
      &__item {
        position: relative;
        width: 226px;
        height: 230px;
        margin-bottom: 8px;
        padding: 8px;
        opacity: 1;
        cursor: pointer;
        transition: all 0.25s;
        &:hover {
          background-color: #fff;
          border-radius: 6px;
          .play-icon {
            display: block;
          }
        }
        img {
          width: 210px;
          height: 144px;
          object-fit: cover;
        }
        p {
          width: 210px;
          height: 60px;
          margin-bottom: 0px;
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          color: #616261;
          display: -webkit-box;
          -webkit-line-clamp: 2 !important;
          padding: 7px 0;
          text-overflow: ellipsis !important;
          overflow: hidden;
        }
        .play-icon {
          display: none;
          position: absolute;
          top: 23.5%;
          left: 45%;
          color: #fff;
          font-size: 30px;
        }
        &.selected {
          -webkit-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.09);
          -moz-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.09);
          box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.09);
          background-color: #fff;
          border-radius: 6px;
          opacity: 1;
        }
        &.unselected {
          opacity: 0.7;
        }
      }
    }
  }
  &__detail {
    &-enter {
      opacity: 0.01;
      transform: translateY(-30px);
    }
    &-enter-active {
      opacity: 1;
      transform: translateY(0px);
      transition: all 300ms;
    }

    &-exit {
      opacity: 1;
      transform: translateY(0px);
    }

    &-exit-active {
      opacity: 0.01;
      transform: translateY(-30px);
      transition: all 300ms;
    }
  }
  &__filters {
    background-color: #FAFBFF;
    width: 100%;
    height: 72px;
    padding: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid #F2F2F2;
    .filter__icon {
      margin-left: 10px;
      background-color: #edf0ff;
      border-radius: 40px;
      padding: 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        font-size: 25px;
        color: #8093fc;
      }
      &:hover {
        background-color: darken(#edf0ff, 2%);
      }
    }
    .menu-grid-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      font-size: 32px;
      color: #8093fc;
      background-color: #edf0ff;
      cursor: pointer;
      &:hover {
        background-color: darken(#edf0ff, 1%);
      }
    }
    .menu-list-icon {
      font-size: 24px;
    }
  }
}

.arrow-right {
  position: absolute;
  top: calc(50% - 290px);
  left: calc(100% - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  background-color: #CED5FD;
  color: #2C3980;
  font-size: 36px;
  cursor: pointer;
}

.arrow-left {
  @extend .arrow-right;
  left: calc(100% - 100px);
}

.arrow-bottom-position {
  top: calc(50% - 160px);
}

.hRnZkV {
  padding-bottom: 10px;
}
