.no-url-found {
  display: grid;
  place-items: center;
  height: 100vh;
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.14);
    border-radius: 10px;
    &__image {
      width: 40%;
      img {
        width: 100%;
        height: auto;
        display: block;
        overflow: hidden;
      }
    }
    p {
      margin-top: 20px;
      font-size: 24px;
      text-align: center;
      font-weight: bold;
      color: #616261;
    }
  }
}
