.setpassword-screen {
  position: relative;
  height: 100vh;
  height: 100svh;
  display: flex;
  flex-direction: column;
  background-image: url('../../assets/images/form-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: lighten(#ffeaf1, 2%);
  &__form {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &_logo {
      align-self: center;
      object-fit: contain;
      aspect-ratio: 2/1;
      width: 140px;
      margin-bottom: 32px;
    }
    &_label {
      font-size: 28px;
      font-weight: bold;
      text-align: center;
    }
    &_description {
      color: #000;
      text-align: center;
    }
    &_input {
      margin-bottom: 0 !important;
    }
    &_nextbtn {
      z-index: 3;
      position: absolute;
      bottom: 16px;
      right: 20px;
      &.handle-sections {
        min-width: calc(100% - 40px);
      }
    }
    &_error {
      color: tomato;
      font-size: 14px;
      p {
        text-align: center;
        margin: 0;
      }
    }
  }
}
