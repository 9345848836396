.payment__method {

  &__payment_icon { 
    margin: 0; 
    width: 60px;
  }

  &__bank_icon { 
    color: white;
    font-size: 20px;
    &__container {
      margin: 0; 
      width: 60px;
      display: flex;
      background-color: #65dfb6;
      border-radius: 3px;
      padding: 14px 0;
      align-items: center;
      justify-content: center;
    }
  }

  &__payment_icon__container { 
    margin: 0; 
    color:  #8093fc;
    font-size: 20px;

    &:hover {
      color: #495fd7;
    }
  }

  &__container {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  &__modal__body {
    max-height: 70vh;
    overflow-y: scroll;
    padding: 10px
  }

  &__title {
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 14px;
    color: #818181;

    &__container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;   
    }
  }

  &__edit {
    color: #495fd7;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      margin-right: 6px;
    }

    &:hover {
      color: #8093fc;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    align-items: center;

    &__card_data {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      color: #909090;
      vertical-align: middle;

      p {
        margin: 0;

        &.brand_name {
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 1px;
        }


        &.add_payment_method {
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 1px;
          color: #8093fc;
          cursor: pointer;
          padding: 20px 0;

          &:hover {
            color: #495fd7;
          }
        }

        &.card_number {
          font-size: 13px;

          span {
            letter-spacing: 4px;
            margin-left: 4px;
          }
        }
      }
    }
  }
}
