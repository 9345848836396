.employees {
  height: 100%;
  margin: 20px 30px;
  &__content {
    table {
      margin-top: 20px;
      display: table;
      width: 100%;
      thead {
        background: #46c2ad;
        padding: 12px 0;
        border-radius: 4px;
        display: block;
        margin-bottom: 10px;
        tr {
          display: flex;
          th {
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            color: #f9faff;
          }
        }
      }
      tbody {
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        display: block;
        max-height: calc(100vh - 365px);
        overflow-y: scroll;
        tr {
          display: flex;
          margin: 8px 0;
          background-color: #fcfcfc;
          td {
            display: flex;
            justify-content: center;
            text-align: center;
            font-size: 14px;
            color: #616261;
            padding: 12px 0;
            margin: auto 0;
            span {
              background: rgba(70, 194, 173, 0.1);
              width: 70%;
              max-width: 140px;
              padding: 8px 0;
              border-radius: 4px;
              margin: 0;
              font-weight: bold;
              color: #46c2ad;
              &.unactive {
                background: rgba(243, 144, 144, 0.1);
                color: #f39090;
              }
            }
          }
          .employee-edit {
            cursor: pointer;
            font-size: 28px;
            color: #d9d9d9;
            &:hover {
              color: #8093fc;
            }
          }
          .employee-info {
            font-size: 24px;
            color: #8093fc;
            cursor: pointer;
            &:hover {
              color: darken(#8093fc, 5%);
            }
          }
          .employee-disabled {
            @extend .employee-edit;
            font-size: 24px;
            &:hover {
              color: #d9d9d9;
            }
          }
          &:hover {
            background-color: #edf0ff;
          }
        }
      }
    }
  }
}
.employee-spend {
  position: relative;
  &__close {
    top: -10px;
    right: -10px;
    position: absolute;
    cursor: pointer;
    font-size: 20px;
  }
  &__header {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.14);
    width: 100%;
    max-width: 514px;
    padding: 16px 20px;
    border-radius: 8px;
    margin: 0 auto;
    text-align: center;
    &_side {
      padding: 0 8px;
      &.right {
        border-left: 1px solid #d9d9d9;
      }
    }
    &_amount {
      font-weight: 700;
      font-size: 22px;
      line-height: 21px;
      color: #2c3980;
      &.company-limit {
        color: #46c2ad;
      }
    }
    &_label {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: #818181;
      margin-top: 4px;
      margin-bottom: 0;
    }
  }
  &__table {
    width: 100%;
    &_empty {
      margin: 20px 0 0;
      text-align: center;
      font-size: 16px;
      line-height: 21px;
      color: #818181;
    }
  }
}
