.plan {
  &__view {
    padding: 0px !important;
    height: 70vh;
    max-height: 70vh;
    border: 0px;
    display: flex;
    flex-direction: row;
    max-width: 100%;
    .weeks-container {
      .card {
        height: 70vh;
        max-height: 70vh;
        .card-exercise {
          cursor: pointer;
          &:hover {
            background-color: darken(#fcfcfc, 1%);
          }
        }
      }
    }
  }
  &__title {
    display: flex;
    flex-wrap: wrap;
    margin: 20px;
    justify-content: space-between;
    .title-container {
      flex: 1;
      display: flex;
      background-color: #f9faff;
      min-height: 80px;
      border: 1px solid #cbe6e0;
      border-radius: 10px;
      margin-right: 10px;
      .title-container-left {
        padding: 10px;
        border-right: 1px solid #cbe6e0;
        width: 40%;
        &--only-description {
          width: 100%;
          border: none;
        }
      }
      .title-container-right {
        padding: 10px;
        width: 60%;
        .title-right {
          margin-bottom: 10px;
          font-size: 14px;
          font-weight: bold;
          color: #616261;
        }
        .parts-list {
          display: flex;
          flex-wrap: wrap;
          .parts-list-title {
            padding: 4px 10px;
            border-radius: 10px;
            margin-right: 8px;
            margin-bottom: 8px;
            text-align: center;
            font-size: 14px;
            color: #fff;
            background-color: #8093fc;
          }
        }
      }
    }
    .buttons {
      min-width: 22.5%;
      align-items: center;
      display: flex;
      justify-content: flex-end;
    }
    .title {
      margin-bottom: 0px;
      font-weight: bold;
      font-size: 14px;
      color: #616261;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .description {
      margin-bottom: 0px;
      font-weight: normal;
      font-size: 14px;
      color: #bdbdbd;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
