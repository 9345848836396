.custom-plan {
  &__main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 76vh;
    background-color: white;
    width: 100%;
    padding: 40px;
    padding-left: 60px;
    border-radius: 4px;
    height: auto;
    margin-bottom: 40px;
    .empty-state {
      width: 100%;
      display: flex;
      flex-direction: row;
      height: 100%;
      .content {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        .image {
          width: 36%;
        }
        .title {
          font-weight: bold;
          font-size: 18px;
          text-align: center;
          color: #818181;
          margin-bottom: 8px;
          margin-top: 30px;
          max-width: 36%;
        }
        .subtitle {
          font-weight: normal;
          font-size: 14px;
          text-align: center;
          color: #bdbdbd;
          max-width: 35%;
          span {
            font-weight: bold;
            color: #65dfb6;
          }
        }
      }
    }
    .side-add-panel {
      width: 20%;
      min-width: 20%;
      background: #f9faff;
      border: 2px dashed #cbe6e0;
      border-radius: 10px;
      cursor: pointer;
      color: #cbe6e0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &:hover {
        border: 2px dashed darken(#cbe6e0, 5%);
        color: darken(#cbe6e0, 5%);
      }
      .text {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 10px;
      }
      .icon {
        font-size: 32px;
      }
    }
    .form {
      display: flex;
      width: 100%;
      align-items: center;
      .form__input-container {
        margin-right: 20px;
        flex-grow: 1;
        label {
          font-weight: 300 !important;
        }
      }
      .buttons {
        display: flex;
        justify-content: flex-end;
        flex-grow: 1;
        .button--secondary-rounded {
          min-width: 100px;
        }
        .button--rounded {
          &.disabled {
            background-color: lighten(#bdbdbd, 10%);
            border-color: lighten(#bdbdbd, 10%);
            color: #818181;
          }
        }
      }
    }
    .plan-container {
      height: 66vh;
      max-height: 66vh;
      display: flex;
      flex-direction: row;
      min-width: 100%;
      .weeks-container {
        overflow-x: scroll;
        max-height: 100%;
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        margin-right: 20px;
        .card {
          margin-right: 20px;
          border-radius: 6px;
          width: 430px;
          height: 100%;
          flex-shrink: 0 !important;
          border: 0px !important;
          .card-title-container {
            display: flex;
            flex-direction: row;
            width: 100%;
            background: #f9faff;
            border: 1px solid #cbe6e0;
            border-radius: 10px 10px 0px 0px;
            padding: 24px;
            padding-left: 32px;
            justify-content: space-between;
            .card-title {
              font-weight: bold;
              font-size: 18px;
              font-weight: bold;
              color: #818181;
              margin-bottom: 0px;
            }
            .head-buttons {
              .icon {
                margin-left: 20px;
                color: #8093fc;
                font-size: 24px;
                cursor: pointer;
                &:first-child {
                  margin-left: 0px;
                }
                &:hover {
                  color: #495fd7;
                }
              }
            }
          }
          .card-summary-container {
            border: 1px solid #cbe6e0;
            border-top: 0px;
            background: #f9faff;
            display: flex;
            flex-direction: row;
            .card-summary-item {
              width: 50%;
              text-align: center;
              font-weight: bold;
              font-size: 12px;
              line-height: 21px;
              color: #818181;
              margin-bottom: 0px;
              padding: 14px;
              height: 100%;
              vertical-align: middle;
              font-family: 'SF Pro Text';
              &:first-child {
                border-right: 1px solid #cbe6e0;
              }
              span {
                font-size: 18px;
                margin-right: 4px;
              }
            }
          }
          .card-days-container {
            border: 1px solid #cbe6e0;
            border-top: 0px;
            height: 100%;
            max-height: 100%;
            border-radius: 0px 0px 10px 10px;
            overflow-y: auto;
            .card-day {
              display: flex;
              flex-direction: row;
              .day-title {
                width: 24px;
                border: 1px solid #cbe6e0;
                border-top: 0px;
                border-left: 0px;
                p {
                  color: #65dfb6;
                  transform: rotate(-90deg);
                  font-weight: bold;
                  font-size: 12px;
                  margin-bottom: 0px;
                  white-space: nowrap;
                  margin-top: 30px;
                  line-height: 24px;
                  margin-left: -1px;
                }
              }
              &:last-child {
                .day-title {
                  border-bottom: 0px;
                }
              }
              &:first-child {
                .day-title {
                  border-bottom: 1px solid #cbe6e0 !important;
                }
              }
              .day-data {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                margin: 0px 10px 15px 6px;
                .card-exercise {
                  background: #fcfcfc;
                  border: 1px solid #cbe6e0;
                  border-radius: 5px;
                  width: 100%;
                  margin-top: 15px;
                  max-height: 120px;
                  height: 120px;
                  display: flex;
                  flex-direction: row;
                  .image {
                    width: 98px !important;
                    min-width: 98px !important;
                    height: 120px !important;
                    margin-top: -1px;
                    margin-left: -1px;
                    border: 1px solid #cbe6e0;
                    border-radius: 5px 0px 0px 5px;
                    object-fit: cover;
                  }
                  .info {
                    flex-grow: 1;
                    padding: 20px 16px 16px 16px;
                    display: flex;
                    flex-direction: column;
                    font-family: 'SF Pro Text';
                    p {
                      margin-bottom: 0px;
                      font-weight: bold;
                      font-size: 20px;
                      color: #616261;
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                    }
                    span {
                      font-weight: bold;
                      font-size: 14px;
                      color: #e0e0e0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
