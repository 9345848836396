%positioning {
  display: flex;
  flex-direction: column;
  align-items: center;
}

%cardLayout {
  width: 100%;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
}

.new-user {
  padding: 40px 0 15px;
  min-height: 100vh;
  overflow-y: scroll;
  @extend %positioning;
  &__content {
    flex: 1 0 auto;
    width: 95%;
    max-width: 700px;
    @extend %positioning;
    &__logo {
      width: 140px;
      margin-bottom: 10px;
      img {
        width: 100%;
        height: auto;
        display: block;
        object-fit: cover;
      }
    }
    &__intro {
      @extend %cardLayout;
      @extend %positioning;
      &__image {
        width: 375px;
        height: 265px;
        margin-bottom: 20px;
        @media (max-width: 375px) {
          width: 95%;
          height: 165px;
        }
        img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }
      h2 {
        font-weight: bold;
        font-size: 32px;
        text-align: center;
        color: #2c3980;
        margin-bottom: 10px;
        @media (max-width: 1024px) {
          font-size: 28px;
        }
        @media (max-width: 576px) {
          font-size: 24px;
          margin-top: 40px;
        }
        @media (max-width: 374px) {
          font-size: 20px;
        }
      }
      p {
        font-size: 14px;
        text-align: center;
        color: #bdbdbd;
      }
    }
    &__form {
      @extend %cardLayout;
      border-radius: 4px;
      &_button {
        margin: 0 auto;
      }
    }
    &__completed {
      @extend %cardLayout;
    }
  }
  &__footer {
    flex-shrink: 0;
    color: #bdbdbd;
    font-size: 14px;
    padding: 20px 0 0;
  }
}
