.no-patients-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 95%;
  &.isModal {
    width: 50%;
    height: 330px;
    margin: 0 auto;
  }
  .content {
    width: 100%;
    padding-bottom: 0;
    margin-top: 20px;
    background: transparent;
    .title {
      font-size: 24px;
      text-align: center;
      font-weight: bold;
      color: #616261;
    }
    .subtitle {
      font-size: 14px;
      text-align: center;
      color: #bdbdbd;
    }
  }
  .image {
    width: 70%;
    margin: 0 auto;
  }
}

.members-empty-state {
  width: 95%;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.14);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px auto 0;
  &.patient-empty-state {
    margin: 10px auto;
    padding: 20px 0;
    background-color: transparent;
    border: none;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 40%;
    margin-right: 60px;
    font-family: 'SF Pro Text' !important;
    .title {
      font-size: 24px;
      line-height: 36px;
      font-weight: bold;
      color: #616261;
    }
    .subtitle {
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #bdbdbd;
      margin-bottom: 40px;
    }
  }
  .image {
    width: 30% !important;
  }
}

.library {
  &__no-exercises {
    width: 75%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      text-align: center;
      color: #606060;
    }
  }
}
