.header {
  display: flex;
  justify-content: space-between;
  padding: 10px 35px 0px 3px;
  background-color: #2c3980;
  &__title {
    font-size: 20px;
    color: #616261;
    font-weight: 600;
    margin: 0;
    &__container {
      background-color: white;
      flex-grow: 1;
      border-top-left-radius: 1.2vw;
      border-top-right-radius: 1.2vw;
      padding: 15px 30px;
      padding-top: 20px;
    }
    &__navigation {
      background-color: white;
      width: 25vw;
      &__container {
        width: 100%;
        height: 100%;
        background-color: #2c3980;
        border-bottom-left-radius: 1.5vw;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  &__date {
    font-family: 'SF Pro Text';
    color: #bdbdbd;
    font-size: 13px;
    margin-top: 4px;
    margin-bottom: 2px;
    span {
      color: #8093fc;
      font-weight: bold;
    }
  }
}

.header-shadow {
  // box-shadow: 0px 2px 3px 0px rgba(216, 216, 216, 0.6);
  border-bottom: 1px solid #d5d3d3;
  z-index: 99;
}
