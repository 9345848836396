.user-avatar {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  display: inline-block;
  background-color: #8093fc;
  &--small {
    width: 42px;
    height: 42px;
  }

  &--medium {
    width: 36px;
    height: 36px;
  }

  &--large {
    width: 50px;
    height: 50px;
  }

  &--extra-large {
    width: 90px;
    height: 90px;
  }

  &--huge {
    width: 128px;
    height: 128px;
  }
}
