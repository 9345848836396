$black-label: #000;
$carissma: #ea7dae;
$night-shadz: #9b2c51;
$tutu: #fffafe;
$white: #fff;

%card-layout {
  background-color: $white;
  box-shadow: 0px 4px 4px rgba(223, 230, 255, 0.3);
  padding: 6px;
  border: 0.3px solid rgba(138, 155, 254, 0.8);
  border-radius: 12px;
}

.evie-program {
  background-color: $tutu;
  border: 1px solid rgba(138, 155, 254, 0.8);
  box-shadow: 0px 4px 4px rgba(223, 230, 255, 0.3);
  border-radius: 12px;
  padding: 12px;
  margin: 20px 32px;
  &__steps {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  &__step {
    color: #997770;
    padding: 8px 8px 4px;
    &.current-step {
      background-color: $white;
      border: 1px solid rgba(138, 155, 254, 0.8);
      border-bottom: none;
      border-radius: 12px 12px 0 0;
    }
    &_icon {
      font-size: 24px;
      &.current-icon {
        color: $carissma;
      }
    }
    &_check {
      display: grid;
      place-items: center;
      color: $black-label;
      background-color: $carissma;
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    &_label {
      font-family: 'SF Pro Text';
      font-weight: 500;
      font-size: 16px;
      margin: 0;
      &.current-label {
        color: $black-label;
      }
    }
  }
  &__intstep {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 4px 0;
    height: 100%;
    &.bg {
      background-color: #ffeaf1;
      border-radius: 12px;
    }
  }
  &__sections {
    background-color: $white;
    box-shadow: 0px 4px 4px rgba(223, 230, 255, 0.3);
    padding: 0 12px;
    margin-top: -1px;
    border-radius: 0 12px 12px;
    border: 0.3px solid rgba(138, 155, 254, 0.8);
    &.final-section {
      border-radius: 0 0 12px 12px;
    }
  }
  &__detail {
    width: 100%;
    max-width: 1024px;
    padding: 28px 0;
    margin: 0 auto;
  }
  &__levels {
    width: 100%;
    max-width: 1440px;
    padding: 24px 8px;
    margin: 0 auto;
  }
  &__add-level {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    &_title {
      font-size: 14px;
      line-height: 18px;
      color: $black-label;
    }
  }
  &__level-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    padding-left: 20px;
    margin-bottom: 24px;
    &_description {
      flex: 1;
      display: flex;
      align-items: flex-end;
      gap: 24px;
      max-width: 650px;
      p {
        flex: 1;
        margin: 0;
        color: #000;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  &__levels-list {
    list-style-type: none;
    &_level {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $tutu;
      padding: 12px 20px;
      border-radius: 12px;
      margin-bottom: 12px;
    }
    &_label-level {
      font-weight: 700;
      font-size: 20px;
      line-height: 29px;
      color: $black-label;
      margin: 0;
      &.label-session {
        font-weight: 400;
        font-size: 18px;
      }
    }
    &_label-add {
      color: $black-label;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 20px;
    }
  }
  &__handlers {
    display: flex;
    align-items: flex-end;
    gap: 8px;
    svg {
      color: $night-shadz;
      font-size: 24px;
      cursor: pointer;
    }
    &_selector {
      display: grid;
      place-items: center;
      background-color: #f5b6d3;
      width: 28px;
      height: 28px;
      border-radius: 8px;
      margin-left: 6px;
      svg {
        font-size: 12px;
      }
    }
  }
  &__session-exercises {
    overflow-x: auto;
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    padding-bottom: 24px;
    &:not(:last-child) {
      border-bottom: 1px solid #d89595;
      margin-bottom: 24px;
    }
  }
  &__session-exercises-list {
    list-style-type: none;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 0;
    margin: 0;
    .exercise-session-card {
      position: relative;
      width: 345px;
      height: 145px;
      border-radius: 12px;
      box-shadow: 0px 4px 4px 0px rgba(223, 230, 255, 0.3);
      &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 80%;
        border-radius: 12px;
        background-image: url('../../assets/images/exercises.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #ffe7ca;
        &_title {
          position: absolute;
          bottom: 45%;
          left: 5%;
          font-size: 20px;
          font-weight: 700;
          color: $white;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      &__bg-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        background-color: rgba(0, 0, 0, 0.3);
      }
      &__actions {
        z-index: 1;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 12px 20px;
        border: 0.3px solid rgba(138, 155, 254, 0.8);
        border-top: none;
        border-radius: 0 0 12px 12px;
        background-color: $white;
        &_button {
          display: flex;
          gap: 4px;
        }
        &_icon {
          cursor: pointer;
          font-size: 24px;
          margin-left: 4px;
        }
      }
    }
  }
  &__add-block {
    flex-shrink: 0;
    display: grid;
    place-items: center;
    width: 345px;
    height: 145px;
    border-radius: 12px;
    border: 0.3px dashed #ea7dae;
    box-shadow: 0px 4px 4px 0px rgba(223, 230, 255, 0.3);
  }
  &__btns {
    display: flex;
    justify-content: flex-end;
    &.add-content {
      margin-bottom: 32px;
    }
  }
  &__additional {
    width: 100%;
    max-width: 1440px;
    padding: 24px 8px;
    margin: 0 auto;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $tutu;
    padding: 12px 24px;
    border-radius: 12px;
    &_label {
      font-weight: 700;
      font-size: 20px;
      line-height: 29px;
      color: $black-label;
      margin: 0;
    }
    &_icon {
      display: grid;
      place-items: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: $white;
      box-shadow: 0px 4px 4px rgba(223, 230, 255, 0.3);
      svg {
        font-size: 20px;
        color: $carissma;
      }
    }
  }
  &__form {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    align-items: center;
    margin-top: 16px;
  }
  &__editor {
    &_label {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: $black-label;
    }
    &_container {
      padding: 8px 12px;
      height: 140px;
      border: 0.3px solid rgba(138, 155, 254, 0.8);
      box-shadow: 0px 4px 4px rgba(223, 230, 255, 0.3);
      border-radius: 12px;
    }
    &_toolbar {
      margin-bottom: 8px;
    }
    &_content {
      max-height: 90px;
    }
  }
  &__add-content {
    display: grid;
    grid-template-columns: 1fr minmax(320px, 30%);
    gap: 14px;
    margin-bottom: 28px;
  }
  &__title-level {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: $black-label;
    margin-bottom: 24px;
  }
  &__items-header {
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: #ffe7ca;
    padding: 12px 60px 12px 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    svg {
      font-size: 28px;
      color: $night-shadz;
      flex-shrink: 0;
    }
    &_label {
      flex-shrink: 0;
      font-weight: 800;
      font-size: 16px;
      color: $night-shadz;
      margin: 0;
    }
    &_divider {
      flex-shrink: 0;
      width: 4px;
      height: 32px;
      border-radius: 4px;
      background-color: $night-shadz;
    }
    &_description {
      font-size: 14px;
      line-height: 18px;
      color: $black-label;
      margin: 0;
    }
  }
  &__item-list-container {
    position: relative;
  }
  &__item-list {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 16px;
    list-style-type: none;
    overflow-y: auto;
    max-height: 380px;
    margin-top: 20px;
    margin-bottom: 24px;
    &.exercises {
      max-height: 580px;
    }
    &_item {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 190px;
      height: 180px;
      &.selected-item {
        opacity: 0.5;
      }
      @extend %card-layout;
    }
    &_item-img {
      width: 100%;
      height: 90px;
      background-size: cover;
      background-position: left;
      background-repeat: no-repeat;
      border-radius: 12px;
    }
    &_item-title {
      font-size: 14px;
      color: #0a0909;
      width: 90%;
      margin: 8px 0;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
    &_item-icon {
      position: absolute;
      bottom: 8px;
      right: 8px;
      color: $night-shadz;
      cursor: pointer;
    }
  }
  &__selected-item-list {
    background-color: $tutu;
    padding: 24px 0;
    border-radius: 12px;
    height: 660px;
    &_title {
      display: flex;
      justify-content: space-between;
      font-weight: 700;
      font-size: 16px;
      padding: 0 20px;
      margin-bottom: 20px;
    }
    &_title-label {
      color: $black-label;
    }
    &_title-count {
      color: $carissma;
    }
  }
  &__selected-list {
    list-style-type: none;
    overflow-y: auto;
    padding: 0 20px;
    height: 95%;
    &_item {
      @extend %card-layout;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      margin-bottom: 16px;
    }
    &_item-img {
      width: 110px;
      height: 55px;
      background-size: cover;
      border-radius: 12px;
    }
    &_item-icon {
      flex-shrink: 0;
      font-size: 24px;
      cursor: pointer;
    }
  }
}

.evie-warning {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__icon {
    display: grid;
    place-items: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-bottom: 16px;
    background-color: #ffeaf1;
    svg {
      font-size: 32px;
      color: #9b2c51;
    }
  }
  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    color: #000000;
    margin-bottom: 20px;
    text-align: center;
  }
  &__form {
    width: 100%;
    &_select {
      display: flex;
      justify-content: space-between;
      gap: 8px;
    }
    &_laps {
      flex: 1;
      margin-bottom: 40px;
    }
    .css-9f5dsg-menu {
      div {
        max-height: 120px;
      }
    }
  }
  &__handlers {
    display: flex;
    &.level-info-btns {
      justify-content: center;
    }
  }
}
