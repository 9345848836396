.navigation {
  &__dropdown {
    position: relative;
    display: inline-block;
    z-index: 6;
    top: 18px;
    margin-left: auto;
    &-menu {
      position: absolute;
      top: 50px;
      right: 10px;
      background: #fff;
      border: 1px solid #cbe6e0;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
      border-radius: 10px;
      display: none;
      z-index: 5;
      padding: 22px 22px 10px;
      min-width: 220px;
      font-family: 'SF Pro Text';
    }
    &:hover {
      .navigation__dropdown-menu {
        display: inline-block;
      }
    }
  }
  &__link {
    display: flex;
    flex-direction: row;
    width: 100%;
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    color: #818181 !important;
    margin-bottom: 10px;
    span {
      margin-bottom: 5px;
    }
    .icon {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    .icon-poly {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      margin-top: -5px;
    }
    &:hover {
      color: darken(#616261, 10%) !important;
    }
    &.red {
      color: #eb5757 !important;
      &:hover {
        color: darken(#eb5757, 10%) !important;
      }
    }
  }
  &__user {
    color: #616261;
    padding: 0 20px;
    margin-bottom: 5px;
    &-name {
      font-weight: 700;
    }
    &-email {
      font-size: 14px;
    }
  }
  &__name {
    position: relative;
    top: -11px;
    margin-right: 10px;
    .icon {
      width: 28px;
      height: 28px;
      color: #fff;
      margin-left: 6px;
      cursor: pointer;
    }
  }
}
