.billing {
  &__content {
    &__form {
      width: calc(65% - 30px);
      margin-right: 30px;
      margin-bottom: 29px;
      border: 1px solid #cbe6e0;
      background-color: #ffffff;
      border-radius: 5px;
      padding: 30px 40px;
      display: flex;
      flex-direction: column;
      height: 100%;
      input {
        padding: 8px;
      }
      &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .form__input-container {
          width: calc(50% - 20px);
        }
        &.cols_4 {
          .form__input-container {
            width: calc(25% - 20px);
          }
        }
        label {
          font-weight: normal;
        }
        &.field_70 {
          .form__input-container {
            width: 70%;
          }
        }
        &.field_100 {
          .form__input-container {
            width: 100%;
            &.checkbox__input {
              display: flex;
              flex-direction: row;
              margin-top: 30px;
              input {
                margin-right: 10px;
                font-size: 16px;
              }
              label {
                a {
                  color: #8093fc;
                  font-weight: bold;
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
      &__title {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #cbe6e0;
        margin-bottom: 50px;
        padding-bottom: 10px;
        align-items: center;
        &.second {
          margin-top: 30px;
        }
        .title {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 48px;
          color: #818181;
          margin-bottom: 0px;
          flex-grow: 1;
        }
        .icon {
          margin-right: 10px;
          font-size: 30px;
          color: #818181;
        }
      }
    }
  }
  &__phone {
    display: flex;
    flex-direction: row;
    &__container {
      display: flex;
      flex-direction: column;
      width: calc(50% - 20px);
    }
    .form__error {
      top: 35px;
      left: -110px;
    }
    .form__input-container {
      width: calc(100% - 110px);
    }
    .form__input-container-select {
      width: 100px;
      margin-right: 10px;
    }
  }
  &__terms {
    margin-top: 16px;
  }
}
