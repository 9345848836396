.left-menu {
  height: 100%;
  display: flex;
  flex-grow: 0;
  min-width: 20px;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  padding: 20px 0;
  &__logo {
    margin-top: 10px;
    &__item {
      display: flex;
      align-content: center;
      justify-content: center;
      padding: 5px;
    }
    &__first {
      margin-top: 15px;
    }
    &__container {
      margin-top: 10px;
      background-color: rgba($color: #fff, $alpha: 0);
      border-radius: 50%;
      display: flex;
      align-content: center;
      justify-content: center;
    }
  }
  &__add {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin: 20px auto 0;
    span {
      width: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin: 0;
      opacity: 0;
      transition: opacity 200ms 200ms;
    }
    &_text {
      width: auto !important;
      margin-left: 10px !important;
      opacity: 1 !important;
    }
  }
  &__expanded {
    background-color: #46c2ad;
    width: 85%;
    height: 40px;
    border-radius: 4px;
  }
  &__actual {
    position: absolute;
    bottom: 250px;
    right: 5px;
    width: 230px;
    padding: 20px 0;
    border-radius: 4px;
    background-color: #3e4ea5;
    background-image: url('../../assets/images/bg-actualiza.png');
    background-size: cover;
    display: grid;
    place-items: center;
    p {
      font-size: 16px;
      color: #ffffff;
      margin-bottom: 8px;
      text-align: center;
      span {
        display: inline-block;
        margin-top: 4px;
        font-weight: bold;
        font-size: 20px;
      }
    }
    &--hide {
      display: none;
    }
  }
  &__icon {
    width: 24px;
    height: 24px;
    margin-top: 4px;
    &.library {
      margin-top: 1px;
    }
  }
  .button-icon {
    margin-top: -4px;
  }
  &__link {
    display: flex;
    padding: 10px 25px;
    color: #e0e0e0;
    cursor: pointer;
    font-size: 18px;
    &:hover {
      color: #8093fc;
      font-weight: bold;
      .left-menu__icon {
        color: #495fd7;
      }
    }
    &.is-active {
      color: #8093fc;
      font-size: 18px;
      font-weight: bold;
      .left-menu__icon {
        color: #495fd7;
      }
    }
  }
  .button {
    margin-bottom: 50px;
    border-radius: 10px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  .icon-dashboard {
    margin-top: 3px !important;
  }
}
.alert-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .alert-icon {
    margin-top: 20px;
    width: 80px;
    height: 80px;
  }
}

.sidenav---sidenav---_2tBP {
  position: fixed;
  overflow-y: auto;
  height: 100vh;
  background-color: #2c3980 !important;
}

.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navtext---1AE_f,
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo
  > *,
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navtext---1AE_f
  > *,
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL
  .sidenav---sidenav-subnav---1EN61
  > .sidenav---sidenav-subnavitem---1cD47
  > .sidenav---navitem---9uL5T {
  color: #dcebf9;
  cursor: pointer;
  font-size: 18px;
}
