.send {
  &--title {
    font-weight: bold;
    font-size: 18px;
    color: #606060;
    text-align: center;
  }
  &--subtitle {
    font-family: 'SF Pro Text';
    font-size: 14px;
    color: #a1a2a1;
  }
  &--file {
    margin-top: 30px;
  }
  &--buttons {
    display: flex;
    justify-content: center;
  }
}
