.program {
  padding: 0 20px;
  &__steps {
    height: calc(100vh - 204px);
    padding: 20px 0;
    overflow-y: auto;
  }
  &__detail {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
    &__avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        &:not(:last-child) {
          margin-right: 20px;
        }
        span {
          color: #616261;
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 5px;
        }
      }
    }
    &__buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 16px;
    }
  }
  &__templates {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__select {
      width: 100%;
      max-width: 768px;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      &--tbody {
        max-height: calc(100vh - 400px) !important;
      }
    }
    &__buttons {
      display: flex;
      justify-content: flex-end;
    }
  }
  &__audio {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__buttons {
      display: flex;
    }
  }
}
