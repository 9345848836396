.step-list {
  align-items: flex-start;
}
.step-line {
  height: 50px;
  border: 2px solid #bdbdbd;
  margin-left: 18px;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 2px;
  border-radius: 2px;
}
.step-container {
  display: flex;
  align-items: center;
  .step {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 4px solid #bdbdbd;
  }
  .step-name {
    line-height: 30px;
    margin-bottom: 0px;
    margin-left: 10px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: #969595;
    span {
      display: block;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
    }
  }
  .checked-icon {
    color: #fff;
    width: 45px;
    height: 45px;
    margin-top: -2px;
    margin-left: -4px;
    border-radius: 50%;
  }
}
.step-checked {
  border-color: #6fcf97 !important;
  background-color: #6fcf97 !important;
}
.step-checked-text {
  color: #969595 !important;
}
.step-current {
  border-color: #8093fc !important;
}
.step-current-text {
  color: #8093fc !important;
}
