.segments {
  &__item {
    background-color: #fcfcfc;
    display: flex;
    cursor: pointer;
    align-items: center;
    color: #616261;
    padding: 10px 10px;
    border-radius: 3px;
    border: 1px solid #dce0e5;
    box-shadow: 1px 1px 2px 0px rgba(216, 216, 216, 1);
    &:hover {
      border: 0.5px solid #bbebdb !important;
      .segments__title {
        color: #65dfb6;
      }
    }
    &.is-active {
      border: 0.5px solid #bbebdb !important;
      .segments__title {
        color: #65dfb6;
      }
    }
  }
  &__container {
    height: 100%;
    color: #818a94;
    width: 100%;
  }
  &__image {
    width: 32px;
    margin-right: 10px;
    margin-left: 5px;
  }
  &__title {
    color: #bdbdbd;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 0px !important;
  }
  &__subtitle {
    font-size: 20px;
    margin-bottom: 20px;
  }
  &__plan {
    background: #ffffff;
    border: 1px solid #cbe6e0;
    border-radius: 10px;
    overflow-y: scroll;
    max-height: 500px !important;
    height: 500px !important;
    margin-bottom: 40px;
    &-item {
      display: flex;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
      padding-right: 20px;
      color: #818181;
      margin-top: 10px;
      margin-bottom: 10px;
      cursor: pointer;
      align-items: center;
      &:hover {
        background-color: darken(#fff, 2%);
        color: #616261;
      }
      &.is-active {
        background-color: lighten(#cbe6e0, 10%);
        color: #616261;
      }
      .item-title {
        margin-bottom: 0px !important;
        margin-left: 20px;
        font-size: 16px;
        font-weight: normal;
        flex-grow: 1;
      }
      .item-image {
        width: 72px;
        height: 48px;
        border-radius: 5px;
        object-fit: cover;
      }
      .detail-button {
        font-weight: bold;
        font-size: 14px;
        color: #65dfb6;
        border: 1px solid #65dfb6;
        border-radius: 7px;
        cursor: pointer;
        padding: 2px 15px 2px 15px;
        &:hover {
          color: darken(#65dfb6, 10%);
          border: 1px solid darken(#65dfb6, 10%);
        }
      }
    }
  }
}

.plan-container {
  display: flex;
  min-width: 100%;
  .button-container {
    margin-top: -10px !important;
    align-self: flex-end;
  }
}

.body-part-list {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  margin-right: 20px;
}

.left-title {
  color: #616261;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
}

.plan-badge {
  color: #6fcf97;
}

.no-results {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .image {
    width: 25%;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 50%;
    margin-right: 30px;
    font-family: 'SF Pro Text';
    .title {
      font-size: 24px;
      line-height: 36px;
      font-weight: bold;
      color: #616261;
    }
    .subtitle {
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #bdbdbd;
      margin-bottom: 40px;
    }
  }
}

.search-box {
  background: #ffffff;
  border: 1px solid #cbe6e0;
  box-sizing: border-box;
  border-radius: 10px;
  margin-left: auto;
  margin-bottom: 20px;
  max-width: 50%;
  height: 40px;
  display: flex;
  input {
    flex-grow: 1;
    border: 0px;
    background-color: transparent;
    outline: none !important;
    height: 100%;
    padding-left: 16px;
    font-weight: normal;
    font-size: 14px;
    color: #616261;
    &::placeholder {
      color: #e0e0e0;
    }
  }
  .icon {
    margin-left: 12px;
    margin-right: 12px;
    width: 18px;
    height: 18px;
    align-self: center;
  }
}
