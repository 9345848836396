%titlempty {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 18px;
  color: #818181;
}

.web-notifications {
  z-index: 6;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  padding-left: 10px;
  margin-left: 50px;
  align-self: center;
  &__bell {
    font-size: 22px;
    color: #fff;
  }
  &__dot {
    position: absolute;
    top: -18px;
    right: -10px;
    font-size: 32px;
    color: red;
  }
  &__list {
    display: none;
    z-index: 5;
    position: absolute;
    top: 40px;
    right: 0;
    width: 340px;
    max-height: 370px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    &__header {
      height: 60px;
      padding: 15px;
      border-bottom: 1px solid #d9d9d9;
      p {
        @extend %titlempty;
      }
    }
    &__body {
      max-height: 300px;
      overflow-y: scroll;
      &__empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        text-align: center;
        width: 300px;
        height: 180px;
        margin: 20px auto;
        p {
          @extend %titlempty;
        }
      }
      &__item {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 70px;
        padding: 15px;
        margin: 5px 0;
        background: #fcfcfc;
        p {
          margin-bottom: 0;
        }
        &__avatar {
          width: 44px;
          height: 44px;
          margin-right: 15px;
          img {
            display: block;
            width: 100%;
            height: auto;
          }
        }
        &__date {
          width: 80%;
          p {
            font-weight: bold;
            font-size: 12px;
            color: #616261;
            &:first-child {
              font-size: 14px;
              font-weight: 100;
              line-height: 16px;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
  &:hover {
    .web-notifications__list {
      display: inline-block;
    }
  }
  &.chat-notifications {
    cursor: pointer;
    position: relative;
    display: grid;
    place-items: center;
    svg {
      font-size: 26px;
    }
  }
}

.chat-notifications__count {
  position: absolute;
  top: -4px;
  right: -14px;
  font-size: 12px;
  display: grid;
  place-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: tomato;
  color: #fff;
}

.web-notifications__list__body__item {
  &:hover {
    background: darken(#fcfcfc, 2%);
  }
}
