.greeting {
  background-color: #46c2ad;
  min-height: 120px;
  border-radius: 6px;
  padding: 0 0 0 28px;
  margin: 25px;
  display: flex;
  align-items: center;
  p {
    color: white;
    flex-grow: 1;
    font-size: 32px;
    font-weight: 200;
    margin: 0;
    span {
      font-weight: bold;
    }
  }
  /*&__patients {
    padding: 0 28px;
    width: 340px;
    p {
      font-weight: normal;
      font-size: 16px;
      color: #f9faff;
    }
    .info-patients {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__left {
        display: flex;
        border-right: 0.5px solid #ffffff;
        &-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 62px;
          height: 62px;
          margin-top: 5px;
          border-radius: 50%;
          background-color: #e0f5f1;
          .icon-person {
            width: 38px;
            height: 38px;
            color: #46c2ad;
          }
        }
        &-text {
          padding: 0 30px;
          p {
            text-transform: uppercase;
          }
          .number {
            font-weight: bold;
            font-size: 44px;
            line-height: 48px;
          }
          .state {
            font-weight: bold;
            font-size: 12px;
            text-align: center;
          }
        }
      }
      &__right {
        padding-right: 5px;
        padding-left: 23px;
        p {
          text-transform: uppercase;
        }
        .number {
          font-weight: bold;
          font-size: 44px;
          line-height: 48px;
          text-align: center;
        }
        .state {
          font-weight: bold;
          font-size: 12px;
          text-align: center;
        }
      }
    }
  }*/
}
