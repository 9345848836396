.completed-days {
  position: relative;
  width: 320px;
  height: 258px;
  padding: 20px;
  border: 0.5px solid #CBE6E0;
  border-radius: 8px;
  margin: 98px 5px 0 0;
  background-color: #FCFCFC;
  @media (max-width: 1024px) {
    margin-top: 20px;
  }
  p {
    margin: 0;
  }
  .title {
    font-weight: normal;
    font-size: 18px;
    color: #616261;
  }
  .description {
    font-size: 16px;
    font-weight: normal;
    line-height: 26px;
    color: #BDBDBD;
  }
  .feedback {
    position: absolute;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    max-width: 130px;
    max-height: 160px;
    overflow-y: auto;
    color: #BDBDBD;
  }
  .doughnut {
    position: relative;
    margin-top: 20px;
    margin-left: auto;
    width: 50%;
    p {
      position: absolute;
      top: 37.5%;
      right: 27.5%;
      font-weight: bold;
      font-size: 27px;
      color: #616261;
    }
    .completed {
      position: absolute;
      top: 37.5%;
      right: 22.5%;
    }
    .no-completed {
      position: absolute;
      top: 37.5%;
      right: 33.5%; 
    }
    canvas {
      z-index: 1;
    }
  }
}