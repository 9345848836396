.onboarding {
  display: flex;
  padding: 20px 30px;
  border-radius: 8px;
  margin: 15px 25px;
  background-color: #FAFAFA;
  @media (min-width: 1900px) {
    padding: 80px 100px;
  }
  @media (max-width: 998px) {
    flex-direction: column;
  }
  &__bar {
    width: 40%;
    padding-right: 50px;
    display: flex;
    flex-direction: column;
    @media (max-width: 998px) {
      width: 100%;
    }
    &_completed {
      font-size: 30px;
      color: #818181;
    }
    progress {
      width: 100%;
      height: 7px;
      border-radius: 10px;
      &::-webkit-progress-bar {
        background-color: #C4C4C4;
        border-radius: 10px;
      }
      &::-webkit-progress-value {
        background-color: #8093FC;
        border-radius: 10px;
      }
      &::-moz-progress-bar {
        background-color: #8093FC;
        border-radius: 10px;
      }
    }
    &_link {
      margin: 20px 0 50px;
      font-weight: normal;
      font-size: 14px;
      color: #616261;
    }
  }
  &__image {
    align-self: center;
    width: 260px;
    height: 330px;
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      overflow: hidden;
    }
  }
  &__steps {
    width: 60%;
    @media (max-width: 998px) {
      width: 100%;
      margin-top: 20px;
    }
    &_title {
      font-weight: normal;
      font-size: 14px;
      color: #818181;
      margin-bottom: 24px;
    }
    .completed-title {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 24px 0;
      p {
        padding: 0 20px;
        margin-bottom: 0;
        font-weight: normal;
        font-size: 14px;
        color: #BDBDBD;
      }
      span {
        width: 40%;
        height: 0.5px;
        background-color: #D9D9D9;
      }
    }
  }
}

.step-by-step {
  color: #8093FC;
  &:hover {
    color: darken(#8093FC, 10%);
    text-decoration: none;
  }
}