.chart-status {
  display: flex;
  flex-direction: row;
  width: 320px;
  height: 258px;
  padding: 20px;
  border: 0.5px solid #CBE6E0;
  border-radius: 8px;
  margin: 98px 5px 0 10px;
  background-color: #FCFCFC;
  @media (max-width: 1024px) {
    margin-top: 20px;
  }
  &__description {
    p {
      font-weight: normal;
      font-size: 18px;
      color: #616261;
      margin-bottom: 30px;
    }
    .active {
      display: flex;
      &_count {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 48px;
        height: 40px;
        border-radius: 4px;
        margin-right: 4px;
        background-color: #FFBB6A;
        font-weight: bold;
        font-size: 18px;
        color: #FFFFFF;
      }
    }
    .available {
      @extend .active;
      &_count {
        @extend .active_count;
        background-color: #8093FC;
      }
    }
    .description {
      margin-bottom: 8px;
      max-width: 95px;
      font-size: 14px;
      color: #BDBDBD;
    }
  }
  &__doughnut {
    position: relative;
    width: 50%;
    p {
      margin-bottom: 0;
      text-align: center;
      position: absolute;
      top: 34%;
      left: 24%;
      font-weight: bold;
      font-size: 16px;
      color: #616261;
      span {
        font-size: 26px;
      }
    }
    canvas {
      z-index: 10;
    }
  }
}