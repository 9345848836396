.modal {
  &__close {
    border: none;
    background: transparent;
    margin-right: -30px;
    margin-bottom: 10px;
    color: #495fd7;
    font-size: 20px;
    font-weight: 700;
    &:active {
      outline: none;
    }
  }
  &__title {
    font-family: 'SF Pro Text';
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 18px;
    color: #616261;
    text-align: center;
    max-width: 100% !important;
    &.title_left {
      text-align: left;
    }
  }
  &__subtitle {
    font-family: 'SF Pro Text';
    font-size: 14px;
    color: lighten(#616261, 25%);
    text-align: center;
    max-width: 350px !important;
    margin-right: auto;
    margin-left: auto;
    &.subtitle_left {
      text-align: left;
    }
    &.no_max_width {
      max-width: inherit !important;
    }
  }
  &__buttons {
    padding-top: 30px;
    &.right_buttons {
      justify-content: flex-end;
    }
  }
}

.ReactModal__Content {
  padding: 20px 40px 30px;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.48);
  border-color: #dce0e5 !important;
  border-radius: 4px;
}

.ReactModal__Overlay {
  z-index: 1007 !important;
  background-color: rgba(0, 0, 0, 0.38) !important;
}
