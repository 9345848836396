.plans {
  &__container {
    width: 100%;
    height: 100%;
    .pagination {
      margin-top: 25px !important;
    }
    .search-box-container {
      display: flex;
      align-items: center;
      background-color: #f9faff;
      height: 100px;
      border-radius: 10px;
      padding-right: 40px;
      margin-bottom: 40px;
    }
    .search-box {
      background: #ffffff;
      border: 1px solid #cbe6e0;
      box-sizing: border-box;
      border-radius: 10px;
      max-width: 40%;
      height: 40px;
      margin-top: auto;
      margin-right: auto;
      margin-bottom: auto;
      margin-left: 40px;
      display: flex;
      flex-grow: 1;
      input {
        flex-grow: 1;
        border: 0px;
        background-color: transparent;
        outline: none !important;
        height: 100%;
        padding-left: 16px;
        font-weight: normal;
        font-size: 14px;
        color: #616261;
        &::placeholder {
          color: #e0e0e0;
        }
      }
      .icon {
        margin-left: 12px;
        margin-right: 12px;
        width: 18px;
        height: 18px;
        align-self: center;
      }
    }
    table {
      width: 100%;
      display: table !important;
      height: 62vh;
      tbody {
        border: 1px solid #cbe6e0;
        display: block !important;
        width: 100%;
        border-radius: 10px;
        height: 100%;
        max-height: 100%;
        overflow-y: scroll;
        tr {
          display: flex !important;
          width: 100%;
          margin-bottom: 10px;
          margin-top: 10px;
          .plan-card {
            border-left: 0;
            border-right: 0;
          }
          &.empty-state {
            margin: 0px !important;
            height: 100%;
            margin: 0px;
            background-color: #f9faff;
            td {
              padding: 0px;
              text-align: left;
            }
            &:hover {
              background-color: #f9faff;
            }
          }
          td {
            width: 100%;
            font-weight: normal;
            font-size: 15px;
            color: #616261;
            padding-top: 12px;
            padding-bottom: 12px;
            margin-top: auto;
            margin-bottom: auto;
          }
        }
      }
    }
  }
  &__no-results,
  &__empty-state {
    width: 100%;
    padding: 60px;
    background: white;
    background-color: #f9faff;
    border: 0.5px solid #cbe6e0;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      max-width: 40%;
      margin-right: 60px;
      font-family: 'SF Pro Text' !important;
      .title {
        font-size: 24px;
        line-height: 36px;
        font-weight: bold;
        color: #616261;
      }
      .subtitle {
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #bdbdbd;
        margin-bottom: 40px;
      }
    }
    .image {
      width: 30% !important;
    }
    .icon {
      margin-top: -2px;
    }
  }
  &__no-results {
    border: 0px !important;
    margin-bottom: 0px !important;
    max-height: 100%;
    padding: 50px;
    .image {
      width: 30%;
    }
  }
}
.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.tab-plans {
  z-index: 1;
  padding: 10px 30px 10px 30px;
  color: #bdbdbd;
  background-color: #fff;
  font-size: 16px;
  cursor: pointer;
  &.active {
    border: 1px solid #8093fc !important;
    background-color: #edf0ff;
    color: #8093fc;
    &:hover {
      color: #8093fc;
    }
  }
  &:hover {
    color: darken(#bdbdbd, 5%);
  }
}
.border-left {
  border-radius: 0 10px 10px 0;
}
.border-right {
  border-radius: 10px 0 0 10px;
}

.pages-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}
