.bottom-nav {
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
  gap: 8px;
  background-color: #fff;
  width: 100%;
  max-width: 480px;
  padding: 8px 20px;
  margin: 0 auto;
  border-top: 1px solid #dfe6ff;
  border-right: 1px solid #dfe6ff;
  border-left: 1px solid #dfe6ff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #9b2c51;
    font-size: 14px;
    min-width: 44px;
    min-height: 44px;
    padding: 4px 12px;
    border-radius: 12px;
    &.selected {
      background-color: #fcddec;
    }
    &:hover {
      color: #9b2c51;
      text-decoration: none;
    }
  }
  &__icon {
    color: #9b2c51;
    font-size: 24px;
  }
}
