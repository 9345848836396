.new-home-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__content {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    &__logo {
      width: 140px;
      img {
        width: 100%;
        height: auto;
        display: block;
        object-fit: cover;
      }
    }
    h2 {
      font-size: 24px;
      font-weight: 700;
      color: #2c3980;
      margin-bottom: 20px;
    }
    &__buttons {
      width: 280px;
      display: flex;
      justify-content: space-between;
    }
  }
  &__footer {
    flex-shrink: 0;
    color: #BDBDBD;
    font-size: 14px;
    padding-bottom: 10px;
  }
}