$text-color: #606060;

.profile {
  &__container {
    margin: 15px 35px 35px 35px;
    display: flex;
    flex-grow: 1;
    height: 100%;
    flex-direction: row;
  }
  &__avatar-container {
    border: 1px solid #cbe6e0;
    border-radius: 10px;
    margin-right: 32px;
    width: 260px;
    padding: 31px;
    display: flex;
    align-items: center;
    flex-direction: column;
    .user-name {
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      color: #616261;
      margin-bottom: 8px;
      width: 100%;
    }
    .user-role {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      text-align: center;
      color: #818181;
      width: 100%;
    }
    .user-avatar {
      margin-top: 32px;
      margin-bottom: 32px;
    }
    label {
      font-weight: bold;
      font-size: 12px;
      color: #818181 !important;
      width: 100%;
    }
    .description-field {
      width: 100%;
      background: #f9faff;
      border: 1px solid #cbe6e0;
      box-sizing: border-box;
      border-radius: 5px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #818181;
    }
    .camera-button {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      background-color: #495fd7;
      border: 5px solid #ffffff;
      cursor: pointer;
      margin-left: 60%;
      margin-top: 60%;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        color: #fff;
        font-size: 40px;
      }
      &:hover {
        background-color: darken(#495fd7, 5%);
      }
    }
    .form__error {
      display: none !important;
    }
  }
  &__info-container {
    display: flex;
    flex-grow: 1;
    background: #f9faff;
    border: 1px solid #cbe6e0;
    border-radius: 10px;
    .patient__title {
      margin-left: 30px;
      margin-top: 56px;
      .edit-button {
        position: absolute;
        right: 50px;
        cursor: pointer;
        .icon {
          color: #8093fc;
          &:hover {
            color: #495fd7;
          }
        }
        .close-icon {
          font-size: 36px;
          color: #818181;
          &:hover {
            color: #495fd7;
          }
        }
      }
    }
    .item-label {
      font-size: 14px;
      color: #bdbdbd;
    }
    .item-value {
      &.password {
        letter-spacing: 2px !important;
      }
    }
    .form__label {
      font-weight: normal;
      font-size: 14px;
      color: #bdbdbd;
    }
    .save-button {
      margin-left: 30px;
      margin-top: 30px;
    }
  }
}

.settings {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(378px, 1fr));
  padding-bottom: 40px;
  margin: 0 30px 40px;
  border-bottom: 1px solid #e0e0e0;
  @media (max-width: 883px) {
    margin: 0 60px 40px;
  }
  &__left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 40px;
    @media (max-width: 883px) {
      padding-left: 0;
      margin-bottom: 20px;
    }
    &_profile {
      font-weight: bold;
      font-size: 24px;
      color: $text-color;
    }
    &_description {
      max-width: 80%;
      margin-bottom: 0;
      color: $text-color;
    }
  }
  &__right {
    padding: 10px 20px 10px 12px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    &_language {
      min-width: 250px;
      margin: 5px 0 10px;
    }
    &--whitelabel {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
    }
    &__info {
      display: flex;
      align-items: center;
      padding: 20px 0 10px;
      border-bottom: 1px solid #e0e0e0;
      .label {
        min-width: 140px;
        margin-bottom: 0;
        font-weight: bold;
        font-size: 16px;
        color: $text-color;
      }
      .value {
        margin-bottom: 0;
        margin-left: 20px;
        font-weight: normal;
        font-size: 14px;
        color: #bdbdbd;
      }
    }
    &__edit {
      margin: 30px 0 20px;
    }
    .button-pagination {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @extend .settings__right__edit;
      &__arrows {
        font-size: 32px;
        display: flex;
        align-items: center;
        min-width: 86px;
        max-height: 40px;
        padding: 5px 0;
        border-radius: 3px;
        color: #7d7c8e;
        border: 1px solid #dce0e5;
        box-shadow: 1px 1px 2px 0px #d8d8d8;
        &__arrow {
          cursor: pointer;
          width: 50%;
          display: flex;
          justify-content: center;
          &:hover {
            color: lighten(#495fd7, 8%);
          }
          &--left {
            border-right: 1px solid #dce0e5;
          }
        }
        .left-disabled {
          pointer-events: none;
          color: #e3e4e5;
        }
        .right-disabled {
          @extend .left-disabled;
        }
      }
    }
    .search-box {
      background: #ffffff;
      padding-left: 15px;
      border: 1px solid #d9d9d9;
      box-sizing: border-box;
      border-radius: 4px;
      margin-top: 20px;
      max-width: 100%;
      height: 35px;
      display: flex;
      align-items: center;
      color: #999;
      input {
        flex-grow: 1;
        border: 0px;
        background-color: transparent;
        outline: none !important;
        height: 100%;
        padding-left: 10px;
        font-weight: normal;
        font-size: 14px;
        color: #616261;
        &::placeholder {
          font-size: 14px;
          color: #bdbdbd;
        }
      }
    }
    .no-members {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 95%;
      height: 304px;
      padding: 50px;
      .content {
        width: 95%;
        .title {
          font-size: 24px;
          text-align: center;
          font-weight: bold;
          color: #616261;
        }
        .subtitle {
          font-size: 14px;
          text-align: center;
          color: #bdbdbd;
        }
      }
      .image {
        width: 50%;
        margin: 0 auto;
      }
    }
  }
  &--profile {
    margin-top: 40px;
  }
}

.change-password {
  display: flex;
  align-items: center;
  &__button {
    margin-left: 80px;
  }
  .item-label {
    margin-bottom: 0;
  }
}

.add-team-member-modal-title {
  font-weight: bold;
  font-size: 22px;
  color: #606060;
}

.add-team-member-modal-buttons {
  display: flex;
  justify-content: flex-end;
}

.delete-member-modal {
  text-align: center;
  color: #606060;
  p {
    max-width: 350px;
  }
  &__buttons {
    display: flex;
    justify-content: center;
  }
}
