.incomplete {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 17px 30px;
  border: 0.5px solid #D9D9D9;
  border-radius: 8px;
  margin-bottom: 12px;
  &.completed {
    opacity: 0.5;
  }
  &__icon {
    display: flex;
    font-size: 26px;
    color: #8093FC;
    &.completed {
      font-size: 30px;
      color: #46C2AD;
    }
  }
  &__content {
    width: 70%;
    padding: 0 30px;
    &_title {
      font-weight: bold;
      font-size: 14px;
      color: #616261;
      margin-bottom: 8px;
    }
    &_description {
      @extend .incomplete__content_title;
      font-weight: normal;
    }
  }
}