.weeks-container {
  overflow-x: auto;
  max-height: 100%;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  .card {
    margin-right: 20px;
    border-radius: 6px;
    width: 430px;
    height: 100%;
    flex-shrink: 0 !important;
    border: 0px !important;
    .card-title-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      background: #f9faff;
      border: 1px solid #cbe6e0;
      border-radius: 10px 10px 0px 0px;
      padding: 24px;
      padding-left: 32px;
      justify-content: space-between;
      .card-title {
        font-weight: bold;
        font-size: 18px;
        font-weight: bold;
        color: #818181;
        margin-bottom: 0px;
      }
      .plan-view-title-card {
        width: 100% !important;
        text-align: center !important;
      }
    }
    .card-summary-container {
      border: 1px solid #cbe6e0;
      border-top: 0px;
      background: #f9faff;
      display: flex;
      flex-direction: row;
      .card-summary-item {
        width: 50%;
        text-align: center;
        font-weight: bold;
        font-size: 12px;
        line-height: 21px;
        color: #818181;
        margin-bottom: 0px;
        padding: 14px;
        height: 100%;
        vertical-align: middle;
        &:first-child {
          border-right: 1px solid #cbe6e0;
        }
        span {
          font-size: 18px;
          margin-right: 4px;
        }
      }
    }
    .card-days-container {
      border: 1px solid #cbe6e0;
      border-top: 0px;
      height: 100%;
      max-height: 100%;
      border-radius: 0px 0px 10px 10px;
      overflow-y: auto;
      .card-day {
        display: flex;
        flex-direction: row;
        .day-title {
          width: 24px;
          border: 1px solid #cbe6e0;
          border-top: 0px;
          border-left: 0px;
          p {
            color: #65dfb6;
            transform: rotate(-90deg);
            font-weight: bold;
            font-size: 12px;
            margin-bottom: 0px;
            white-space: nowrap;
            margin-top: 30px;
            line-height: 24px;
            margin-left: -1px;
          }
        }
        &:last-child {
          .day-title {
            border-bottom: 0px;
          }
        }
        &:first-child {
          .day-title {
            border-bottom: 1px solid #cbe6e0 !important;
          }
        }
        .day-data {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          margin: 0px 10px 15px 6px;
          .card-exercise {
            background: #fcfcfc;
            border: 1px solid #cbe6e0;
            border-radius: 5px;
            width: 100%;
            margin-top: 15px;
            max-height: 120px;
            height: 120px;
            display: flex;
            flex-direction: row;
            .image {
              width: 98px !important;
              min-width: 98px !important;
              height: 120px !important;
              margin-top: -1px;
              margin-left: -1px;
              border: 1px solid #cbe6e0;
              border-radius: 5px 0px 0px 5px;
              object-fit: cover;
            }
            .info {
              flex-grow: 1;
              padding: 20px 16px 16px 16px;
              display: flex;
              flex-direction: column;
              p {
                margin-bottom: 0px;
                font-weight: bold;
                font-size: 20px;
                color: #616261;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
              span {
                font-weight: bold;
                font-size: 14px;
                color: #e0e0e0;
              }
            }
          }
        }
      }
    }
  }
}
