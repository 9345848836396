.videocall {
  &__container {
    z-index: 9999;
    background-color: #f0f0f0;
    border-radius: 2px;
    .OT_publisher {
      z-index: 9999;
      border: 2px solid #fff;
      border-radius: 8px;
      width: 180px !important;
      height: 130px !important;
    }
    /*.OT_subscriber {
      resize: both;
      overflow: hidden;
      min-width: 460px;
      min-height: 380px;
    }*/
  }
  &__logo {
    position: fixed;
    z-index: 99999;
    top: 10px;
    right: 10px;
    width: 5%;
  }
  &__buttons {
    display: flex;
    position: fixed;
    bottom: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
    &--small {
      z-index: 99999;
      justify-content: flex-end;
      right: 30px;
      bottom: 10px;
    }
    .hang-button {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #ff1919;
      opacity: 0.5;
      margin-left: 15px;
      margin-right: 15px;
      cursor: pointer;
      &--small {
        width: 50px;
        height: 50px;
        margin: 0 5px;
      }
      &:hover {
        opacity: 0.9;
      }
      .icon {
        font-size: 32px;
        color: #fff;
        transform: rotate(135deg);
      }
    }
    .control-button {
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #505050;
      opacity: 0.5;
      cursor: pointer;
      &--small {
        width: 35px;
        height: 35px;
      }
      &:hover {
        opacity: 0.85;
      }
    }
  }
}
.share-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  z-index: 99999;
  cursor: pointer;
  color: #5A7EE0;
  font-size: 24px;
  position: fixed;
  bottom: 5px;
  left: 5px;
}
.full-screen-button {
  cursor: pointer;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 4px;
  color: #5A7EE0;
  font-size: 20px;
  &:hover {
    transform: scale(1.1);
  }
}

.OTSubscriberContainer {
  width: 100% !important;
  height: 100% !important;
}
