.redeem-product {
  height: 100vh;
  background-color: #F6F6F7;

  &__wrapper {
    margin: auto;
    padding: 40px 5px;
    max-width: 400px;
  }

  &__title {
    margin-bottom: 20px;
    font-size: 25px;
  }

  &__card {
    box-shadow: rgba(23, 24, 24, 0.05) 0px 0px 5px 0px, rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
    background-color: white;
    border-radius: 8px;
    margin-bottom: 16px;
    padding: 20px 0;

    &:not(:first-of-type) {
      padding: 20px 20px;
    }
  }

  &__image {
    width: 100%;
    border-radius: 8px;
    object-fit: contain;
  }

  &__product-name {
    display: block;
    font-size: 15px;
    line-height: 15px;
  }

  &__product-value {
    font-size: 13px;
    line-height: 15px;
    color: #8093FC;
    font-weight: 600;
  }

  &__table {
    width: 100%;

    thead {
      border-bottom: 1px solid rgb(128, 128, 128);
    }

    thead tr th {
      padding: 0 12px 10px 0;

      &:first-child {
        padding-left: 10px;
        width: 60px;
      }
      &:nth-child(2) {
        max-width: 100px;
      }
      &:last-child {
        padding-right: 10px;
      }
    }

    tbody tr td {
      padding: 10px 12px 0 0;
      font-size: 14px;

      &:first-child {
        padding-left: 10px;
        width: 60px;
      }
      &:nth-child(2) {
        max-width: 100px;
      }
    }

  }

  &__subtitle {
    font-size: 20px;
    font-weight: 500;
  }

  &__resume-item {
    display: grid;
    align-items: center;
    grid-template-areas: "bold dash text" ;
    grid-template-columns: minmax(min-content, max-content) 1fr minmax(min-content, max-content);
    grid-column-gap: 20px;
    font-size: 14px;

    b {
      grid-area: bold;
    }

    span {
      grid-area: text;
    }

    &:after {
      content: "";
      border-bottom: 0.0625rem dashed rgb(128, 128, 128);
    }
  }
  &__button {
    width: 100%;
    text-align: center;
  }

  &__error-img {
    width: 100%;
    margin: 40px 0 20px;
    display: block;
  }
  &__error {
    color: tomato;
    font-size: 14px;
    text-align: center;
    margin: 10px 0;
  }
}
