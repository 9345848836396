.add-product {
  &__close {
    z-index: 10;
    position: absolute;
    top: 10px;
    right: 12px;
    cursor: pointer;
    color: #616261;
    font-size: 26px;
  }
  &__title {
    position: fixed;
    width: 100%;
    padding: 15px 30px;
    box-shadow: inset 0px -1px 0px #D9D9D9;
    h3 {
      font-weight: 600;
      font-size: 18px;
      color: #616261;
    }
  }
  &__form {
    max-height: 85vh;
    overflow-y: scroll;
    padding: 5px 30px 20px;
    margin-top: 65px;
  }
  &__button {
    margin-top: -15px;
    margin-left: auto;
  }
}
