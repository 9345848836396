.billing {
  background-color: #fcfcfc;
  &__main {
    margin-left: 30px;
    margin-right: 30px;
  }
  &__header {
    padding-top: 44px;
    padding-bottom: 44px;
    border-bottom: 1px solid #e0e0e0;
    .title {
      margin-bottom: 0px;
      font-weight: normal;
      font-size: 24px;
      text-align: center;
      width: 100%;
      color: #616261;
    }
    .step-indicator {
      width: 100%;
      display: flex;
      flex-direction: row;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    &__title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 25px 60px;
      .title {
        font-weight: normal;
        font-size: 20px;
        line-height: 48px;
        color: #616261;
        margin-bottom: 0px;
      }
      .back {
        cursor: pointer;
        margin-right: 20px;
        .icon {
          color: #495fd7;
          font-size: 24px;
        }
      }
    }
    &__layout {
      display: flex;
      flex-direction: row;
      padding: 0px 60px;
    }
    &__summary {
      display: flex;
      flex-direction: column;
      width: 35%;
      padding-bottom: 30px;
    }
    &__panel {
      border: 1px solid #cbe6e0;
      background-color: #ffffff;
      padding: 30px;
      border-radius: 5px;
      &.wompi__info {
        width: 100%;
        padding-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
      }
      &.payment__details {
        width: 100%;
        .payment__details__title {
          border-bottom: 1px solid #cbe6e0;
          padding-bottom: 4px;
          display: flex;
          flex-direction: row;
          align-items: center;
          p {
            font-weight: bold;
            font-size: 18px;
            line-height: 48px;
            color: #818181;
            margin-bottom: 0px;
            margin-left: 15px;
          }
          .icon {
            font-size: 24px;
            color: #818181;
          }
        }
        .payment__details__body {
          width: 100%;
          padding-left: 40px;
          margin-top: 20px;
          .summary__row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 5px 0px;
            .label {
              font-family: 'SF Pro Text';
              font-weight: normal;
              font-size: 16px;
              line-height: 21px;
              color: #bdbdbd;
            }
            .value {
              font-family: 'SF Pro Text';
              font-weight: bold;
              font-size: 14px;
              line-height: 21px;
              color: #818181;
              text-align: right;
              &.discount {
                color: #f39090;
              }
              &.total__price {
                font-family: 'SF Pro Text';
                font-weight: normal;
                font-size: 16px;
                line-height: 21px;
                color: #6fcf97;
                border: 1px solid #cbe6e0;
                border-radius: 2px;
                padding: 4px 6px;
                span {
                  color: #65dfb6;
                  font-weight: bold;
                }
              }
            }
          }
          .button__container {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            .button__next {
              width: 70%;
              margin-top: 20px;
              margin-left: -40px;
              font-size: 14px;
              padding-top: 10px;
              padding-bottom: 10px;
            }
          }
        }
      }
    }
  }
  &__promo {
    display: flex;
    &__field {
      min-width: 220px;
    }
    button {
      align-self: flex-end;
      margin-bottom: 2px;
    }
  }
}
