.button {
  padding: 5px 20px;
  border-radius: 4px;
  background: white;
  border: 1px solid white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  &--primary {
    background: #495fd7;
    border: 1px solid #495fd7;
    color: white;
    &:hover {
      background-color: darken(#495fd7, 10%);
      color: white;
      text-decoration: none;
    }
    &[disabled] {
      background-color: lighten(#495fd7, 20%);
      border-color: lighten(#495fd7, 20%);
    }
  }
  &--rounded {
    background: #495fd7;
    border: 1px solid #495fd7;
    border-radius: 20px;
    color: white;
    min-width: 110px;
    &:hover {
      background-color: darken(#495fd7, 10%);
      color: white;
      text-decoration: none;
    }
  }
  &--simple {
    background: transparent;
    border: 0px;
    border-radius: 20px;
    color: #818181;
    font-weight: bold;
    padding: 0px;
    &:hover {
      color: #495fd7;
      text-decoration: none;
    }
  }
  &--secondary {
    background: white;
    border: 1px solid #495fd7;
    color: #495fd7;
    &:hover {
      color: #495fd7;
      text-decoration: none;
    }
  }
  &--secondary-rounded {
    background: white;
    border: 1px solid #495fd7;
    color: #495fd7;
    border-radius: 20px;
    &:hover {
      color: #495fd7;
      background-color: darken(white, 2%);
      text-decoration: none;
    }
  }
  &--table {
    font-size: 12px;
    border: 1px solid #495fd7;
    color: #495fd7;
    padding: 2px 5px;
    &:hover {
      color: white;
      background-color: #495fd7;
    }
  }
  &--table-primary {
    background-color: #495fd7;
    font-size: 12px;
    color: white;
    padding: 2px 5px;
  }
  &:focus {
    outline: none;
  }
  &--left-tab {
    border: 0.5px solid #bdbdbd;
    border-right-width: 0px;
    color: #bdbdbd;
    border-radius: 0px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
  }
  &.active {
    border: 0.5px solid #8093fc !important;
    color: #8093fc !important;
  }
  &--right-tab {
    border: 0.5px solid #bdbdbd;
    border-left-width: 0px;
    color: #bdbdbd;
    border-radius: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
  }
}
