.download-pdf {
  z-index: 10000;
  background-color: #fff;
  padding: 40px;
  max-width: 1000px;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  &__title {
    font-weight: bold;
    font-size: 16px;
    color: #616261;
    margin-bottom: 20px;
  }
  &__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    &__item {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;
      .label {
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #818181;
        margin-bottom: 10px;
      }
      .value {
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        color: #616261;
        margin-bottom: 0px;
        &.bigtext {
          max-height: 80px;
          height: 80px;
          background-color: #fff;
          border: 1px solid #cbe6e0;
          font-weight: normal;
          padding: 6px;
          border-radius: 4px;
          overflow-x: scroll;
        }
        &-link {
          @extend .value;
          color: #8093fc;
        }
      }
      .tags {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .tag {
          background-color: #8093fc;
          color: #f9faff;
          font-size: 14px;
          border-radius: 10px;
          padding: 5px;
          margin-right: 8px;
          margin-bottom: 5px;
        }
      }
    }
  }
}