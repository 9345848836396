.letter-container {
  .letter {
    font-size: 16px;
    margin-bottom: 0;
    color: #bdbdbd;
  }
  &__patient {
    border-radius: 50px;
    padding: 4px 6px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: #f2f4ff;
    }
    .avatar {
      margin-right: 20px;
      img {
        width: 45px;
        height: 45px;
      }
    }
    .data {
      p {
        margin-bottom: 0;
        &:first-child {
          font-size: 16px;
          font-weight: 600;
          color: #606060;
        }
        &:nth-child(2) {
          margin-top: -4px;
          font-size: 14px;
          color: #8093fc;
        }
        &:last-child {
          margin-top: -4px;
          font-size: 12px;
          color: #606060;
        }
      }
      &__selected {
        color: #fff !important;
      }
    }
  }
  &__view {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 64px;
    padding: 8px 0 10px;
    margin-bottom: 5px;
    border-radius: 4px;
    cursor: pointer;
    .initials {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #2c3980;
      &__init {
        font-weight: 600;
        font-size: 16px;
        color: #ffffff;
        margin-bottom: 0;
      }
    }
    &:hover {
      background: #f2f4ff;
    }
    .name {
      margin-bottom: 0;
      font-size: 12px;
      color: #030303;
      &--selected {
        color: #fff;
      }
    }
  }
  .selected {
    background: #8093fc;
  }
  .unactive {
    opacity: 0.5;
  }
}

.container-patient {
  position: relative;
  &__checkbox {
    position: absolute;
    top: 35%;
    left: 90%;
    display: flex;
    align-items: center;
    font-size: 26px;
    width: 20px;
    height: 20px;
    color: darken(#bdcbf8, 20%);
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    &--selected {
      border: 2px solid #bdcbf8;
    }
  }
}

.send-button {
  margin-left: auto;
}
