.onboarding-layout {
  position: relative;
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  &.loading {
    opacity: 0.5;
  }
  &__lottie {
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 49%;
    transform: translate(-50%, -50%);
  }
}

.welcome-screen {
  position: relative;
  background-image: url('../../assets/images/girl-with-phone.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #ffe7ca;
  height: 100vh;
  height: 100svh;
  &__header {
    position: absolute;
    top: 168px;
    left: 68px;

    @media (max-width: 480px) {
      top: 180px;
      left: 52px;
    }

    &_heart {
      width: 42px;
      height: 38px;
    }
    &_title {
      color: #9b2c51;
      font-size: 40px;
      font-weight: bold;
      line-height: 43px;
      margin-top: 12px;
    }
  }
  &__text {
    position: absolute;
    top: 28px;
  }
  &__knot {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  &__next {
    z-index: 3;
    position: absolute;
    bottom: 16px;
    right: 20px;
  }
}

.path-selector {
  display: grid;
  place-items: center;
  height: 100vh;
  height: 100svh;
  background-image: url('../../assets/images/path-selector-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-color: lighten(#ffeaf1, 2%);
}

.issue-list {
  z-index: 1;
  width: 90%;
  padding: 40px;
  border-radius: 24px;
  margin-top: -40px;
  background-color: #fff;
  &__header {
    &_title {
      color: #9b2c51;
      font-size: 32px;
      font-weight: bold;
      margin-top: 8px;
    }
    &_content {
      color: #000;
    }
  }
}

.issue-card {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #8a9bfe;
  border-radius: 12px;
  margin-bottom: 12px;
  box-shadow: 0px 2px 10px rgba(35, 38, 48, 0.09);
  &__image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  &__title {
    padding-left: 16px;
    margin: 0;
    font-size: 16px;
    color: #000;
  }
  &__checkmark {
    position: relative;
    width: 24px;
    height: 24px;
    border: 2px solid #19223d;
    border-radius: 4px;
    margin-right: 8px;
    &::after {
      content: '';
      position: absolute;
      display: none;
      left: 8px;
      top: 4px;
      width: 5px;
      height: 10px;
      border: 1px solid #8a9bfe;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    &.selected {
      border: 2px solid #8a9bfe;
      &::after {
        display: block;
      }
    }
  }
  div {
    border: 1px solid tomato;
  }
}

.info-screen {
  position: relative;
  height: 100vh;
  height: 100svh;
  background-color: lighten(#ffeaf1, 1%);
  &.staticimg {
    display: flex;
    flex-direction: column;
  }
  &__goback {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 40px;
    padding-left: 12px;
    background-color: #fff;
    &_icon {
      align-self: center;
      cursor: pointer;
      font-size: 24px;
      color: #19223d;
    }
  }
  &__logo {
    z-index: 4;
    position: absolute;
    top: 48px;
    left: 16px;
    width: 103px;
    object-fit: contain;
    aspect-ratio: 2/1;
  }
  &__bgimg {
    display: block;
    object-fit: cover;
    width: 100%;
    height: calc(100vh - 206px);

    &.perks {
      max-height: 264px;

      @media (max-width: 480px) {
        max-height: 460px;
      }
    }

    &.testimonialbg {
      z-index: 1;
      position: absolute;
      border-radius: 0 0 28px 0;
    }
  }
  &__testimonials {
    display: grid;
    place-items: center;
    position: relative;
    width: 100%;
    height: calc(100vh - 206px);

    /* @media (max-width: 480px) {
      height: calc(100vh - 226px);
    } */
    &_img {
      position: absolute;
      left: 0;
      border-radius: 0 0 28px 0;
    }
    &.index-view {
      height: 544px;
    }
  }
  &__testimonial {
    z-index: 2;
    margin-top: -20px;
    padding: 0 24px;

    /* @media (max-width: 480px) {
      margin-top: -124px;
    } */

    &.index-view {
      padding: 0 48px;
      margin-top: 160px;
    }
    &_star {
      color: #ffc073;
      font-size: 20px;
      margin-right: 8px;
    }
    &_content {
      color: #fff;
      font-size: 16px;
      margin: 4px 0 0;

      @media (max-width: 380px) {
        font-size: 14px;
      }
    }
    &_author {
      text-align: left;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      padding-right: 16px;
      margin: 12px 0 0;
    }
  }
  &__footer {
    z-index: 2;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 32px 24px 80px;
    background-color: #fff;
    &.perks {
      text-align: center;
    }
    &_topbg {
      position: absolute;
      top: -75px;
      left: 0;
      background-image: url('../../assets/images/wave-bg.png');
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      min-height: 75px;

      @media (max-width: 480px) {
        top: -55px;
      }
      &.second {
        top: -55px;

        @media (max-width: 480px) {
          top: -40px;
        }
      }
      &.plan {
        top: -90px;

        @media (max-width: 480px) {
          top: -68px;
        }
      }
      &.plan {
        top: -90px;

        @media (max-width: 480px) {
          top: -70px;
        }
      }
    }
    &_icon {
      position: absolute;
      top: -56px;
      display: grid;
      place-items: center;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: #ffeaf1;

      @media (max-width: 480px) {
        top: -48px;
      }
      &.index-view {
        position: relative;
        top: 0;
        margin-bottom: 24px;
      }
      &.perks {
        width: 92px;
        height: 92px;
        background-color: #d89595;
        top: -82px;

        img {
          width: 67px;
          height: 91px;
        }
      }
      .multi-heart-icon {
        width: 45px;
        height: 40px;
        margin-top: 4px;
      }
    }
    &_title {
      font-size: 28px;
      font-weight: bold;
      color: #9b2c51;
      &.plan-screen {
        font-size: 24px;
        color: #000;
      }
    }
    &_content {
      line-height: 20px;
      font-size: 16px;
      color: #000;
      margin: 0;
      &.perks {
        margin-bottom: 24px;
      }
    }
  }
}

.test-results-screen {
  background-color: #f2e4d3;
  background-image: url('../../assets/images/vagina-shape.webp');
  background-repeat: no-repeat;
  background-size: 230px 440px;
  background-position: bottom right;
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100vh;
  min-height: 100svh;
  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px 28px;
    &_logo {
      align-self: center;
      object-fit: contain;
      aspect-ratio: 2/1;
      width: 140px;
      margin-bottom: 32px;
    }
    &_line {
      position: absolute;
      top: 0;
      right: 0;
      &.right {
        top: 0;
        left: 0;
      }
    }
    &_title {
      color: #9b2c51;
      font-size: 24px;
      font-weight: bold;
    }
    &_label {
      background-color: #ffeaf1;
      color: #9b2c51;
      font-size: 20px;
      font-weight: bold;
      width: fit-content;
      padding: 8px 16px;
      border-radius: 32px;
    }
    &_rate {
      background-color: #fff;
      color: #9b2c51;
      font-size: 20px;
      font-weight: bold;
      width: fit-content;
      padding: 4px 12px;
      border-radius: 8px;
    }
    &_description {
      font-size: 18px;
      text-align: justify;
      max-width: 300px;
      margin-bottom: 32px;
    }
  }
  &__card {
    display: grid;
    align-items: center;
    grid-template-columns: minmax(32px, 20%) 1fr;
    background-color: #fff;
    max-width: 280px;
    padding: 12px 28px;
    border-radius: 16px;
    margin-bottom: 20px;
    &_title {
      font-size: 18px;
      font-weight: bold;
    }
    &_content {
      background-color: #ffb1ae;
      width: fit-content;
      padding: 4px 16px;
      margin-bottom: 0;
      border-radius: 16px;
      &.average {
        background-color: #ffcf94;
      }
    }
  }
  &__footer {
    grid-row-start: 2;
    grid-row-end: 3;
    padding: 16px 20px;
  }
}

.useremail-screen {
  position: relative;
  height: 100vh;
  height: 100svh;
  display: flex;
  flex-direction: column;

  &__image {
    align-self: center;
    position: relative;
    display: grid;
    place-items: center;
    background: radial-gradient(ellipse closest-side, rgba(241, 226, 209, 1) 0%, rgba(255, 255, 255, 1) 100%);
    width: 100%;
    margin-bottom: 20px;
    &_img {
      z-index: 1;
      display: block;
      width: 377px;
      height: 197px;
      object-fit: cover;

      @media (max-width: 414px) {
        width: 300px;
        height: 197px;
      }
    }
    &_knot {
      z-index: 0;
      opacity: 0.25;
      position: absolute;
      top: -75px;
      right: 0;
      width: 100px;
      height: 250px;

      @media (max-width: 414px) {
        width: 70px;
        height: 220px;
      }
    }
  }
  &__form {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 414px) {
      margin-top: -40px;
    }
    &_logo {
      align-self: center;
      object-fit: contain;
      aspect-ratio: 2/1;
      width: 140px;
      margin-bottom: 32px;

      @media (max-width: 414px) {
        margin-bottom: 24px;
      }
    }
    &_label {
      font-size: 28px;
      font-weight: bold;
      text-align: center;

      @media (max-width: 414px) {
        font-size: 24px;
      }
    }
    &_description {
      color: #000;
      text-align: center;
      margin-bottom: 0;
    }
    &_input {
      margin-bottom: 0 !important;
    }
    &_nextbtn {
      z-index: 3;
      position: absolute;
      bottom: 16px;
      right: 20px;
      &.handle-sections {
        min-width: calc(100% - 40px);
      }
    }
    &_error {
      color: tomato;
      font-size: 14px;
      p {
        text-align: center;
        margin: 0;
      }
    }
    &_privacy {
      position: absolute;
      bottom: 70px;
      left: calc(50% - 137px);
      margin: 0;
      font-size: 14px;
    }
  }
}

.username-screen {
  position: relative;
  height: 100vh;
  height: 100svh;
  display: flex;
  flex-direction: column;
  background-image: url('../../assets/images/form-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: lighten(#ffeaf1, 2%);
  &__form {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &_logo {
      align-self: center;
      object-fit: contain;
      aspect-ratio: 2/1;
      width: 140px;
      margin-bottom: 32px;
    }
    &_label {
      font-size: 28px;
      font-weight: bold;
      text-align: center;
    }
    &_description {
      color: #000;
      text-align: center;
    }
    &_input {
      margin-bottom: 0 !important;
    }
    &_nextbtn {
      z-index: 3;
      position: absolute;
      bottom: 16px;
      right: 20px;
      &.handle-sections {
        min-width: calc(100% - 40px);
      }
    }
    &_error {
      color: tomato;
      font-size: 14px;
      p {
        text-align: center;
        margin: 0;
      }
    }
  }
}

.quiz-starter {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 170px;

  &.pregnancy {
    background-image: url('../../assets/images/quiz-starter-bg.png');
    background-color: lighten(#f1e2d1, 5%);
  }
  &.postpartum {
    background-image: url('../../assets/images/quiz-starter-bg-1.png');
    background-color: lighten(#ffeaf1, 1%);
  }
  &.pain {
    background-image: url('../../assets/images/quiz-starter-bg-2.png');
    background-color: lighten(#f1e2d1, 5%);
  }
  &__pregnancy {
    position: relative;
    max-width: 215px;
    min-height: 340px;
    margin: 0 auto 44px;
    &_bg {
      position: absolute;
      bottom: 0;
      width: 174px;
      height: 242px;
    }
    &_img {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      object-fit: cover;
      width: 100%;
      height: auto;
    }
  }
  &__postpartum {
    position: relative;
    max-width: 300px;
    min-height: 345px;
    margin: 0 auto 44px;
    &_bg {
      position: absolute;
      top: 40px;
      right: -40px;
      width: 174px;
      height: 242px;
    }
    &_img {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      object-fit: cover;
      width: 100%;
      height: auto;
    }
  }
  &__pain {
    position: relative;
    max-width: 241px;
    min-height: 333px;
    margin: 0 auto 44px;
    &_bg {
      position: absolute;
      bottom: 20px;
      right: -64px;
      width: 174px;
      height: 242px;
    }
    &_img {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      object-fit: cover;
      width: 100%;
      height: auto;
    }
  }
  &__content {
    flex: 1 0 auto;
    padding: 112px 24px 16px;
    text-align: center;
    &_title {
      color: #000;
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    &_description {
      color: #000;
      font-size: 16px;
      margin-bottom: 0;
    }
  }
  &__footer {
    flex-shrink: 0;
    padding: 16px 14px;
  }
}

.perk-screen {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: 100svh;
  &__perkcard {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px;
    border-radius: 12px;
    border: 1px solid rgba(138, 155, 254, 0.8);
    margin-bottom: 8px;
    box-shadow: 0 2px 2px 0 rgba(138, 155, 254, 0.3);
    &_content {
      text-align: left;
      margin: 0;
    }
    &_icon {
      width: 22px;
      height: 22px;
      flex-shrink: 0;
    }
  }
}

.plan-screen {
  &__offerings {
    margin-top: 16px;
    &_guarantee {
      font-size: 14px;
      text-align: center;
      margin-top: 20px;
      &.terms {
        padding-bottom: 32px;
        margin-top: 0;
      }
    }
    &_cardbrands {
      padding: 0 24px;
      margin-bottom: 12px;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
  &__offeringcard {
    color: #000;
    transition: 0s;
    position: relative;
    width: 100%;
    border: 2px solid #f0f3ff;
    border-radius: 8px;
    margin-bottom: 24px;
    &.selected {
      border: 2px solid #ea7dae;
    }
    .offeringcard-top {
      display: grid;
      place-items: center;
      background-color: #ea7dae;
      padding: 4px;
      border-radius: 4px 4px 0 0;
      p {
        font-weight: bold;
        font-size: 14px;
        margin: 0;
      }
    }
    .offeringcard-mid {
      display: grid;
      grid-template-columns: minmax(80px, 45%) 1fr;
      background-color: #fff;
      padding: 20px 16px;
      border-radius: 8px 8px 0 0;
    }
    .offeringcard-bottom {
      display: flex;
      justify-content: space-between;
      background-color: #f0f3ff;
      padding: 12px;
      border-radius: 0 0 8px 8px;
      &.selected {
        background-color: #ffdfed;
      }
      p {
        margin: 0;
      }
      &_discount {
        font-size: 18px;
        font-weight: bold;
      }
      &_label {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.7);
      }
    }
    &_trial {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #ea7dae;
      color: rgba(0, 0, 0, 0.7);
      padding: 2px 6px;
      border-radius: 4px 0 12px 0;
      font-size: 14px;
      &.selected {
        display: block;
      }
    }
    &_pricing {
      position: relative;
      align-self: center;
      display: flex;
      align-items: center;
      padding-right: 8px;
      .radiobutton {
        flex-shrink: 0;
        position: relative;
        width: 20px;
        height: 20px;
        border: 2px solid #f0f3ff;
        border-radius: 50%;
        margin-right: 12px;
        &::after {
          content: '';
          position: absolute;
          display: none;
          top: 3px;
          left: 3px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #9b2c51;
        }
        &.selected {
          border: 2px solid #9b2c51;
          &::after {
            display: block;
          }
        }
      }
      .offering-price {
        font-size: 18px;
        font-weight: bold;
        margin: 0;
      }
      .offering-price-label {
        font-size: 12px;
        font-weight: bold;
      }
    }
    &_description {
      align-self: center;
      padding-left: 12px;
      border-left: 1px solid rgba(108, 115, 154, 0.8);
    }
    &_periodicity {
      font-size: 18px;
      font-weight: bold;
      margin: 0;
      &.billed {
        color: rgba(0, 0, 0, 0.7);
        font-weight: normal;
        font-size: 16px;
      }
    }
  }
}

.trial-screen {
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
  height: 100svh;
  &__header {
    position: relative;
    grid-row-start: 1;
    grid-row-end: 2;
    background-image: url('../../assets/images/three-women.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 -36px;
    height: 220px;
    margin-top: 40px;
    &_bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 220px;
      background-image: linear-gradient(180deg, transparent, rgba(255, 255, 255, 1));
    }
  }
  &__content {
    position: relative;
    align-self: center;
    margin-top: -40px;

    @media (max-width: 414px) {
      margin-top: -80px;
    }
    &_title {
      font-size: 28px;
      font-weight: bold;
      text-align: center;
    }
  }
  &__steps {
    display: grid;
    grid-template-columns: minmax(72px, 20%) 1fr;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 16px;
    &.firstchild {
      margin-top: 8px;
    }
  }
  &__icons {
    position: relative;
    justify-self: center;
    display: grid;
    place-items: center;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 6px solid #fdeef5;
    background-color: #fff;
    &.firstchild {
      background-color: #ffd3e7;
    }
    &_progress {
      position: absolute;
      top: 50px;
      left: 19px;
      width: 6px;
      height: 168px;
      background-color: #ffd3e7;
    }
  }
  &__contentsteps {
    padding: 8px 12px;
    border-radius: 8px;
    &.secondchild {
      background-color: #fdeef5;
    }
    &_title {
      font-size: 16px;
      font-weight: 700;
      margin: 0;
      &.firstchild {
        color: #ea7dae;
      }
    }
    &_content {
      font-size: 16px;
      margin: 0;
      line-height: 20px;
    }
  }
  &__footer {
    grid-row-start: 3;
    grid-row-end: 4;
    padding: 16px 20px;
  }
}

.thankyou-screen {
  position: relative;
  display: grid;
  place-items: center;
  text-align: center;
  padding: 0 28px;
  &__steps {
    list-style-type: none;
    font-size: 16px;
    font-weight: bold;
    ol {
      margin-bottom: 0;
    }
    &_link {
      display: grid;
      align-items: center;
      background-color: #9b2c51;
      color: #fff;
      width: 180px;
      min-height: 40px;
      padding: 8px 24px;
      margin: 16px auto;
      border-radius: 32px;
      outline: none;
      &:hover {
        color: #fff;
        text-decoration: none;
      }
    }
  }
  &_logo {
    position: absolute;
    top: 36px;
  }
  &_title {
    color: #000;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 16px;

    @media (max-width: 480px) {
      margin-top: 36px;
    }
  }
  &_content {
    color: #000;
    font-size: 16px;
    margin-bottom: 12px;
  }
  &_qr {
    width: 160px;
    height: 160px;
    object-fit: cover;
    border-radius: 4px;
  }
}

.loading-screen {
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100vh;
  height: 100svh;
  padding: 200px 40px;
  background-color: lighten(#ffeaf1, 1%);
  background-image: url('../../assets/images/quiz-starter-bg-1.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 170px;
  &__content {
    text-align: center;
    &_title {
      font-size: 28px;
      font-weight: bold;
    }
    &_description {
      font-size: 16px;
      margin: 0;
    }
  }
}

.index-screen {
  position: relative;
  &__header {
    z-index: 20;
    position: -webkit-sticky;
    position: sticky;
    top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    background-color: #fff;
    box-shadow: 0 4px 20px 0 rgba(75, 49, 72, 0.7);
    width: 95%;
    padding: 12px 20px;
    margin: 0 auto;
    border-radius: 12px;
    &.discount {
      border-radius: 12px 12px 0 0;
    }
    &_logo {
      width: 112px;
      object-fit: contain;
    }
    /* &_blurbg {
      position: absolute;
      bottom: -24px;
      left: 0;
      width: 100%;
      height: 24px;
      background-image: linear-gradient(0deg, transparent, rgba(255, 255, 255, 1));
      &.discount {
        bottom: -55px;
      }
    } */
    .pulse-button {
      box-shadow: 0 0 0 0 rgba(#ea7dae, 0.5);
      -webkit-animation: pulse 1.5s infinite;
      animation: pulse 1.5s infinite;
    }
    .pulse-button:hover {
      -webkit-animation: none;
      animation: none;
    }

    @keyframes pulse {
      0% {
        transform: scale(0.95);
      }
      70% {
        transform: scale(1);
        box-shadow: 0 0 0 12px rgba(#ea7dae, 0);
      }
      100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(#ea7dae, 0);
      }
    }
  }
  &__offerbanner {
    position: absolute;
    bottom: -31px;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    color: #fff;
    background-color: #9b2c51;
    box-shadow: 0 4px 20px 0 rgba(75, 49, 72, 0.7);
    width: 100%;
    padding: 2px 24px;
    border-radius: 0 0 12px 12px;
    p {
      margin: 0;
    }
    &_percentage {
      font-weight: bold;
    }
    &_deadline {
      font-size: 16px;
    }
  }
  &__hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 95%;
    padding-top: 52px;
    margin: 0 auto;
    &.discount {
      padding-top: 80px;
    }
    &_title {
      max-width: 85%;
      font-size: 24px;
      font-weight: bold;
    }
    &_quote {
      max-width: 85%;
      font-weight: bold;
      background-color: #ffeaf1;
      padding: 4px 12px;
      border-radius: 32px;
      margin-bottom: 8px;
    }
    &_description {
      max-width: 85%;
      margin-bottom: 8px;
    }
    &_user {
      font-size: 14px;
      background-color: #f1e2d1;
      padding: 4px 12px;
      border-radius: 32px;
    }
  }
  &__heroimage {
    position: relative;
    margin-bottom: 20px;
    &_img {
      display: block;
      object-fit: cover;
      width: 280px;
      height: auto;
    }
    &_bg {
      position: absolute;
      bottom: 12px;
      right: -64px;
      width: 100px;
      height: 170px;
    }
  }
  &__serenadebg {
    background-image: url('../../assets/images/self-perks-bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 -290px;
  }
  &__goals {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    padding: 100px 12px 0;
  }
  &__goalscard {
    position: relative;
    background-color: #f2e4d3;
    padding: 32px 12px 16px;
    border-radius: 12px;
    &_iconcontainer {
      position: absolute;
      top: -30px;
      left: 20px;
      display: grid;
      place-items: center;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: #f2e4d3;
    }
    &_icon {
      width: 40px;
      height: 40px;
    }
    &_tag {
      font-size: 14px;
      font-weight: 600;
      width: fit-content;
      padding: 2px 16px;
      border-radius: 32px;
      background-color: #fff;
    }
  }
  &__cardlabel {
    margin-bottom: 8px;
    &.first {
      margin-bottom: 12px;
    }
    p {
      margin-bottom: 0;
    }
    &_key {
      font-weight: bold;
    }
    &_rate {
      transform: translateY(-8px);
      display: inline-block;
      width: 32px;
      height: 8px;
      border-radius: 12px;
      margin-right: 4px;
      background-color: #9d2b51;
      &.empty {
        background-color: #fff;
      }
    }
  }
  &__offerings {
    padding: 0 16px;
    margin-top: 32px;
  }
  &__testimonials {
    position: relative;
    margin-top: 72px;
    &_bg {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 150px;
      background-image: linear-gradient(0deg, transparent, rgba(255, 255, 255, 1));
    }
    &_title {
      z-index: 3;
      position: absolute;
      top: -60px;
      padding-left: 48px;
    }
    &_titleicon {
      display: grid;
      place-items: center;
      background-color: #ffeaf1;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-bottom: 12px;
    }
    &_titlelabel {
      font-weight: bold;
      font-size: 28px;
      line-height: 24px;
      max-width: 108px;
      margin: 0;
    }
    &_whitewavebg {
      z-index: 10;
      position: absolute;
      bottom: -12px;
      background-image: url('../../assets/images/wave-bg.png');
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      min-height: 75px;
      &.bottom {
        bottom: -20px;
        @media (max-width: 480px) {
          bottom: -40px;
        }
      }
    }
  }
  &__benefits {
    position: relative;
    padding: 0 16px;
    margin-top: 44px;
    &_title {
      font-size: 24px;
      font-weight: bold;
      max-width: 250px;
      margin-bottom: 20px;
    }
  }
  &__benefitcard {
    display: grid;
    grid-template-columns: minmax(45px, 20%) 1fr;
    background-color: #fff0f5;
    width: 95%;
    padding: 28px 20px 28px 24px;
    margin: 0 auto 16px;
    border-radius: 24px;
    &_icon {
      align-self: center;
      display: grid;
      place-items: center;
      background-color: #fff;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    &_content {
      font-size: 18px;
      margin: 0;
    }
  }
  &__infodata {
    position: relative;
    text-align: center;
    padding: 0 28px;
    margin-top: 40px;
    p {
      margin-bottom: 12px;
      line-height: 28px;
    }
    &_title {
      font-size: 28px;
      font-weight: bold;
    }
    &_bubble {
      font-size: 18px;
      font-weight: bold;
      background-color: #eac8c1;
      padding: 8px 16px;
      border-radius: 32px;
    }
    &_footer {
      font-size: 18px;
      font-weight: bold;
      margin-top: 8px;
    }
  }
  &__programcontent {
    position: relative;
    background-color: #9b2c51;
    margin-top: 80px;
    &_leaves {
      z-index: 12;
      position: absolute;
      right: 0;
      top: 0;
      width: 60px;
      height: 96px;
    }
    &_leaves1 {
      z-index: 12;
      position: absolute;
      right: 0;
      bottom: -40px;
    }
    &_whitewavebg {
      z-index: 10;
      position: absolute;
      top: -110px;
      background-image: url('../../assets/images/red-wave-bg.png');
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      min-height: 150px;
      &.bottom {
        top: -100px;
      }
    }
    &_wrapper {
      padding: 28px;
    }
    &_title {
      font-size: 28px;
      font-weight: bold;
      color: #fff;
      margin-bottom: 16px;
    }
  }
  &__programcontentcard {
    background-color: #fff;
    padding: 20px 28px 28px 20px;
    margin-bottom: 16px;
    border-radius: 8px;
    &_icon {
      display: grid;
      place-items: center;
      background-color: #ea7dae;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-bottom: 8px;
    }
    &_text {
      margin: 0;
      font-size: 18px;
    }
  }
  &__rate {
    position: relative;
    background-color: #f2e4d3;
    color: #9b2c51;
    padding: 60px 0;
    &_line {
      position: absolute;
      top: -56px;
      right: 0;
      &.right {
        top: -92px;
        left: 0;
      }
    }
    &_whitewavebg {
      z-index: 10;
      position: absolute;
      top: -110px;
      background-image: url('../../assets/images/cream-wave-bg.png');
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      min-height: 150px;
      &.bottom {
        top: -100px;
      }
    }
    &_creamwavebg {
      z-index: 10;
      position: absolute;
      bottom: -40px;
      background-image: url('../../assets/images/cream-wave-bg.png');
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      min-height: 150px;
      &.bottom {
        bottom: -50px;
      }
    }
    p {
      font-weight: bold;
      margin-bottom: 0;
    }
    &_title {
      font-size: 20px;
    }
    &_label {
      font-size: 28px;
    }
    &_bubble {
      font-size: 28px;
      font-weight: bold;
      background-color: #ffeaf1;
      padding: 8px 20px;
      border-radius: 32px;
    }
    &_card {
      background-color: #fff;
      max-width: 300px;
      padding: 16px 20px;
      margin: 32px auto 0;
      border-radius: 8px;
    }
    &_cardtitle {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
    }
    &_points {
      background-color: #ffeaf1;
      padding: 2px 8px;
      border-radius: 4px;
    }
    &_stars {
      display: flex;
      justify-content: flex-start;
    }
    &_star {
      display: grid;
      place-items: center;
      color: #ffc073;
      font-size: 24px;
      background-color: #ffe7ca;
      width: 40px;
      height: 40px;
      margin-right: 8px;
      border-radius: 50%;
    }
  }
}
