.selected-ecercise-content {
	width: 58%;
	padding-bottom: 40px;
	border-bottom-left-radius: 4px;
	background-color: #FFF;
	.header-content {
		background-color: #FAFAFA;
		width: 100%;
		padding: 8px 10px 10px 24px;
		&__title {
			font-weight: bold;
			font-size: 20px;
		}
		&__category {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			.one-row {
				width: 50%;
				margin-bottom: 10px;
				font-size: 14px;
				color: #606060;
			}
			.second-row {
				width: 50%;
				margin-bottom: 0;
				font-weight: bold;
				font-size: 16px;
				color: #46C2AD;
				@media (max-width: 1024px) {
				  font-size: 14px;
				}
			}
			.levels-container {
				width: 50%;
				display: grid;
				grid-template-columns: 50% 50%;
				.level {
					width: auto;
					@extend .second-row;
				}
			}
		}
	}
	.section-content {
		padding: 8px 15px 15px 24px;
		display: flex;
		.title {
			font-weight: bold;
			font-size: 14px;
			line-height: 21px;
			color: #BDBDBD;
		}
		.value {
			font-size: 14px;
			line-height: 18px;
			color: #606060;
			margin-bottom: 20px;
			margin-left: 14px;
			&::marker {
				font-weight: bold;
			}
		}
		.instructions {
			width: 50%;
			height: 280px;
			overflow-y: scroll;
			padding-right: 40px;
			@media (max-width: 768px) {
			  height: 240px;
			}
		}
		.advice {
			width: 50%;
			height: 280px;
			overflow-y: scroll;
		}
	}
}
