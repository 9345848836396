.team {
  min-height: 304px;
  &__member {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 10px;
    border-bottom: 1px solid #E0E0E0;
    &_name {
      min-width: 140px;
      margin-bottom: 0;
      font-weight: bold;
      font-size: 16px;
      color: #606060;
    }
    &_email {
      margin-bottom: 0;
      font-weight: normal;
      font-size: 14px;
      color: #BDBDBD;
    }
    &__icons {
      display: flex;
      height: 40px;
      padding: 5px 10px;
      border-radius: 3px;
      color: #7d7c8e;
      border: 1px solid #dce0e5;
      box-shadow: 1px 1px 2px 0px #d8d8d8;
      &_icon {
        cursor: pointer;
        margin: 5px 8px;
        &:hover {
          color: lighten(#495fd7, 8%);
        }
      }
    }
  }
  &--small {
    min-height: auto;
  }
}