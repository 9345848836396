$green-border: #6fcf97;
$gray-border: #bdbdbd;

.program-step-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0;
  border-bottom: 1px solid #e0e0e0;
  &__step {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 30px;
    &--name {
      line-height: 30px;
      margin-bottom: 0;
      font-size: 14px;
      color: $gray-border;
      &.current-text {
        font-weight: 700;
        color: #818181;
      }
      &.checked-text {
        color: $green-border;
      }
    }
  }
  &__icon {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 4px solid $gray-border;
    &.current {
      border: 4px solid $green-border;
    }
    &.checked {
      background-color: $green-border;
      border: 4px solid $green-border;
    }
    .checked-icon {
      color: #fff;
      width: 24px;
      height: 24px;
      margin-top: 0;
      margin-left: -1px;
    }
  }
  &--line {
    width: 100px;
    border: 3px dashed $gray-border;
    margin-left: 5px;
    &.checked-line {
      border-color: $green-border;
    }
  }
}
