.personal-info-form {
  width: 80% !important;
  .form-row {
    display: flex;
    margin-left: 0px;
    &__gender {
      width: 50%;
      padding-left: 20px;
      margin-top: 2px;
    }
    .form__input-container {
      flex-grow: 1;
      &:first-child {
        margin-right: 20px;
      }
    }
  }
  .form__radio {
    input {
      cursor: pointer;
      margin-right: 6px;
    }
  }
}
.assessment__checkbox__input {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  input {
    margin-right: 10px;
    font-size: 16px;
  }
  label {
    margin-top: -3px;
  }
}
