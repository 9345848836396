.create-company {
  padding: 40px 120px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
  gap: 16px;
  @media (max-width: 768px) {
    padding: 20px 60px;
    gap: 12px;
  }
  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: -10px;
    margin-right: 100px;
    @media (max-width: 768px) {
      margin-right: 40px;
    }
  }
}
