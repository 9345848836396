$base-border: 1px solid #edf2fa;
$base-padding: 20px;
$base-font-color: #516984;
$base-font-size: 14px;

%initials {
  background-color: #f9f9f9;
  color: $base-font-color;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: $base-font-size;
}

%credentials {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  padding-left: 8px;
  h4 {
    font-weight: 600;
    font-size: $base-font-size;
    margin: 0;
    span {
      font-weight: normal;
      font-size: 12px;
    }
  }
  p {
    @extend h4;
    color: $base-font-color;
    margin-top: 4px;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.conversations {
  background-color: #fff;
  width: 100%;
  max-width: 1640px;
  height: 100%;
  max-height: 700px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: minmax(300px, 25%) 1fr;
  &__list {
    border-right: $base-border;
    &_search {
      padding: $base-padding $base-padding;
      border-bottom: $base-border;
      display: flex;
      align-items: center;
      svg {
        font-size: 24px;
        color: #bdbdbd;
      }
      input {
        flex: 1;
        outline: none;
        border: 0;
        padding-left: 10px;
      }
    }
    &_items {
      overflow-y: auto;
      height: calc(100vh - 200px);
      max-height: 600px;
      .conversation-info {
        display: flex;
        align-items: center;
        cursor: pointer;
        list-style-type: none;
        padding: 12px $base-padding;
        border-bottom: $base-border;
        transition: 0.4s;
        &:hover {
          background-color: #edf2fa;
        }
        &.selected-conversation {
          background-color: #edf2fa;
        }
        &__initials {
          @extend %initials;
        }
        &__credentials {
          @extend %credentials;
        }
        &__count {
          font-size: 12px;
          display: grid;
          place-items: center;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: #8093fc;
          color: #fff;
        }
        &__time {
          font-weight: normal;
          font-size: 12px;
        }
      }
    }
  }
  &__messages {
    display: flex;
    flex-direction: column;
    &_title {
      padding: 12px $base-padding 10px;
      border-bottom: $base-border;
      display: flex;
      align-items: center;
      .initials {
        @extend %initials;
      }
      .credentials {
        @extend %credentials;
      }
    }
    &_list {
      flex: 1;
      height: calc(100vh - 250px);
      max-height: 550px;
      overflow-y: auto;
      padding: $base-padding;
      .messages {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        &__message {
          display: flex;
          flex-direction: column;
          background-color: #fcfcfc;
          max-width: 45%;
          padding: 12px 8px;
          margin-bottom: 20px;
          border-radius: 8px;
          &.outgoing {
            background-color: #edf2fa;
            margin-left: auto;
          }
          &.incoming {
            margin-right: auto;
          }
          span {
            font-size: $base-font-size;
            &:first-child {
              font-size: 16px;
              font-weight: bold;
            }
            &:last-child {
              font-size: 10px;
              color: #8d9eaf;
            }
          }
        }
      }
    }
    &_empty-list {
      flex: 1;
      display: grid;
      place-items: center;
      height: 565px;
      max-height: calc(100vh - 185px);
    }
    &_input {
      padding: 0 $base-padding;
      border-top: $base-border;
      .conversation-form {
        display: flex;
        align-items: center;
        &__area {
          flex: 1;
          padding-top: 20px;
          margin-right: 10px;
          resize: none;
          background: transparent;
          border: none;
          outline: none;
        }
      }
    }
  }
}
