.welcome-survey {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  padding: 50px 80px;
  margin: 5vh auto;
  background: #f9faff;
  border: 1px solid rgba(128, 147, 252, 0.2);
  border-radius: 10px;
  @media (max-width: 576px) {
    width: 100%;
    padding: 40px;
    margin: 0;
    border: none;
  }
  &__image {
    width: 375px;
    height: 265px;
    margin-bottom: 50px;
    @media (max-width: 374px) {
      width: 95%;
      height: 165px;
    }
    .image {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      font-weight: bold;
      font-size: 32px;
      text-align: center;
      color: #2c3980;
      margin-bottom: 20px;
      @media (max-width: 1024px) {
        font-size: 28px;
      }
      @media (max-width: 576px) {
        font-size: 24px;
      }
      @media (max-width: 374px) {
        font-size: 20px;
      }
    }
    p {
      width: 70%;
      font-weight: normal;
      font-size: 14px;
      text-align: center;
      color: #bdbdbd;
      @media (max-width: 768px) {
        width: 85%;
      }
      @media (max-width: 576px) {
        width: 100%;
      }
    }
  }
  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    .buttons {
      margin-bottom: 25px;
      p {
        font-weight: bold;
        color: #2c3980;
        text-align: center;
        margin-bottom: 25px;
      }
      a {
        margin-right: 25px;
        @media (max-width: 576px) {
          margin-right: 10px;
        }
        @media (max-width: 360px) {
          margin-right: 10px;
        }
        @media (max-width: 320px) {
          margin-bottom: 10px;
          display: flex;
        }
      }
    }
    .survey-button {
      width: 200px;
      margin-bottom: 20px;
      font-weight: normal;
      font-size: 15px;
      color: #ffffff;
    }
  }
}

.sections {
  position: relative;
  width: 100%;
  max-width: 480px;
  min-height: 100vh;
  min-height: 100svh;
  padding-top: 40px;
  margin: 0 auto;

  @media (max-width: 480px) {
    padding-top: 12px;
  }

  &.info-screen-survey {
    padding-top: 48px;

    @media (max-width: 480px) {
      padding-top: 40px;
    }
  }

  &__goback {
    cursor: pointer;
    color: #19223d;
    font-size: 28px;
    position: absolute;
    top: 77px;
    left: 12px;

    @media (max-width: 480px) {
      font-size: 20px;
      top: 57px;
    }

    &.info-screen-survey {
      top: 12px;
    }
  }
  &__progress {
    position: relative;
    width: 90%;
    max-width: 414px;
    height: 4px;
    margin: 0 auto 24px;
    border-radius: 12px;
    background-color: #adb8cd;
  }
  &__completed {
    position: absolute;
    top: 0;
    height: 100%;
    border-radius: 12px;
    background-color: #9b2c51;
    transition: width 0.5s;
  }
  &__logo {
    display: grid;
    place-items: center;
    img {
      width: 200px;
      object-fit: contain;
      aspect-ratio: 2/1;
    }
  }
  &__form {
    padding: 0 16px 12px;
    .form-input {
      margin-bottom: 62px;
      .question {
        position: relative;
        margin-bottom: 16px;
        flex: 1;
        display: flex;
        flex-direction: column;
        &__validation {
          margin-bottom: 2px;
          label {
            font-size: 18px;
            color: #000;
            @media (max-width: 480px) {
              font-size: 16px;
            }
          }
          &_eyeicon {
            position: absolute;
            bottom: 15px;
            right: 12px;
            font-size: 24px;
            color: #8a9bfe;
          }
          &_error {
            position: absolute;
            bottom: -20px;
            left: 4px;
            font-size: 14px;
            color: tomato;
            &.long {
              bottom: -40px;
            }
          }
        }
        &__verification {
          display: flex;
          align-items: flex-start;
          gap: 8px;
          &_label {
            flex: 1;
          }
          &_input {
            display: none;
          }
        }
        &__gif {
          width: 100%;
          margin-bottom: 10px;
          img {
            display: block;
            width: 100%;
            height: auto;
            object-fit: cover;
            border-radius: 12px;
          }
        }
        &__file {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 8px;
        }
        .radio {
          color: #1e1e1e;
          display: flex;
          align-items: center;
          position: relative;
          min-height: 60px;
          padding: 12px 44px 12px 16px;
          border: 1px solid #8a9bfe;
          border-radius: 12px;
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 16px;
          font-weight: normal;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          &.verification {
            flex-shrink: 0;
            min-width: 40px;
            min-height: 40px;
            padding: 12px;
            border: 0;
          }
          &--checked {
            color: #6a6969;
            background-color: #f5f8ff;
          }
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
          }
          .checkmark {
            position: absolute;
            top: 16px;
            right: 16px;
            height: 24px;
            width: 24px;
            border: 2px solid #19223d;
            border-radius: 50%;
            &::after {
              content: '';
              position: absolute;
              display: none;
              top: 5px;
              left: 5px;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background: #19223d;
            }
          }
          .checkmark-box {
            position: absolute;
            top: 16px;
            right: 16px;
            height: 24px;
            width: 24px;
            border: 2px solid #19223d;
            border-radius: 4px;
            &::after {
              content: '';
              position: absolute;
              display: none;
              left: 8px;
              top: 4px;
              width: 5px;
              height: 10px;
              border: 1px solid #19223d;
              border-width: 0 2px 2px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
            &.verification {
              top: 8px;
              left: 8px;
            }
          }
          &.verification-input {
            background-color: red;
          }
        }
        .radio input:checked ~ .checkmark:after {
          display: block;
        }
        .radio input:checked ~ .checkmark-box:after {
          display: block;
        }
        .range-surveys {
          width: 100%;
          .range-item-surveys {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            border: 0;
            border-right: 0.5px solid #e5e5e5;
            &:last-child {
              border-right: 0;
            }
          }
          .active-surveys {
            border: 0.5px solid #8093fc;
            background-color: #8093fc;
            color: #fff;
          }
        }
      }
      .info-screen-survey {
        flex: 1;
        margin-bottom: 12px;
        &__title {
          font-size: 24px;
          font-weight: bold;
          color: #000;
          text-align: center;
          margin-top: 16px;
          margin-bottom: 12px;

          @media (max-width: 480px) {
            font-size: 20px;
          }
        }
        &__content {
          font-size: 16px;
        }
      }
      .input {
        font-size: 16px;
        color: #1e1e1e;
        outline: none;
        width: 100%;
        min-height: 60px;
        padding: 12px 16px;
        border: 1px solid #8a9bfe;
        border-radius: 12px;

        &.field-block {
          display: none;
        }
      }
      .input-area {
        @extend .input;
        height: 100%;
        resize: none;
        background-color: #f5f8ff;
        border: none;
      }
    }
    .handle-sections {
      position: absolute;
      bottom: 12px;
      left: 16px;
      min-width: calc(100% - 32px);
    }
  }
}

.sections-completed {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f9faff;
  width: 100%;
  max-width: 868px;
  padding: 50px 60px 20px;
  border: 1px solid rgba(128, 147, 252, 0.2);
  border-radius: 10px;
  margin: 100px auto 0;
  @media (max-width: 868px) {
    min-height: 100vh;
    border: 0;
    margin: 0;
  }
  @media (max-width: 576px) {
    padding: 20px;
  }
  &__credentials {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    @media (max-width: 576px) {
      width: 100%;
      margin-top: 10px;
      flex-direction: column;
      align-items: center;
    }
    &_data {
      margin-left: 40px;
      @media (max-width: 1024px) {
        margin-left: 20px;
      }
      .login {
        min-width: 320px;
        padding: 20px;
        margin-bottom: 24px;
        background-color: #fff;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.14);
        border-radius: 8px;
        @media (max-width: 576px) {
          margin-right: 20px;
        }
        @media (max-width: 320px) {
          min-width: 250px;
        }
        p {
          &:first-child {
            font-weight: bold;
            font-size: 16px;
            color: #2c3980;
          }
          span {
            font-weight: bold;
            font-size: 14px;
            color: #8093fc;
          }
          .user {
            display: inline-block;
            width: 72px;
            margin-right: 10px;
            font-weight: normal;
            font-size: 14px;
            color: #818181;
          }
        }
      }
      .reminder {
        display: flex;
        font-weight: normal;
        font-size: 14px;
        color: #2c3980;
        @media (max-width: 576px) {
          font-size: 12px;
        }
        &__unlock {
          margin-top: -4px;
          font-size: 26px;
        }
        p {
          width: 70%;
          margin: 0 10px;
          @media (max-width: 576px) {
            width: 80%;
          }
        }
      }
    }
  }
}
