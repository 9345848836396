.list {
	width: 42%;
	padding: 15px 35px 35px 20px;
	border-radius: 0 4px 4px 0;
	background-color: #FCFCFC;
	@media (max-width: 1024px) {
	  padding: 15px 20px 35px 20px;
	}
	.select {
		position: relative;
		display: flex;
		align-items: center;
		label {
			margin-top: 6px;
			margin-right: 10px;
			font-size: 16px;
			color: #606060;
		}
		.close-selected-exercise {
			position: absolute;
			right: 0;
			cursor: pointer;
			color: #2C3980;
			@media (max-width: 768px) {
			  right: -14px;
			}
		}
	}
	.list-container {
		overflow-y: scroll;
		max-height: 690px;
		margin-top: 12px;
		@media (max-width: 1024px) {
		  max-height: 760px;
		}
		&__item {
			width: 98%;
			display: flex;
			height: 145px;
			margin-bottom: 12px;
			@media (min-width: 1500px) {
			  height: 173px;
			}
			&-image {
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				width: 50%;
				cursor: pointer;
				&:hover {
				  .list-play-icon {
				    display: block;
				  }  
				}
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					@media (max-width: 1100px) {
					  height: auto;
					}
				}
				.list-play-icon {
					display: none;
					position: absolute;
					color: #fff;
					font-size: 30px;
				}
			}
			&-title {
				width: 50%;
				padding-left: 8px;
				p {
					font-weight: normal;
					font-size: 16px;
					color: #030303;
					margin-top: 5px;
					margin-bottom: 8px;
					@media (max-width: 1024px) {
					  font-size: 14px;
					  text-overflow: ellipsis;
					  white-space: nowrap;
					  overflow: hidden;
					}
				}
			}
			&-equipments {
				display: flex;
				flex-wrap: wrap;
				.equi-item {
					padding: 5px 15px;
					margin-right: 4px;
					margin-bottom: 8px;
					font-size: 13px;
					color: #606060;
					background: #F2F4FF;
					border-radius: 4px;
					@media (max-width: 1024px) {
					  font-size: 12px;
					}
				}
			}
			&.selected {
			  -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.09);
			  -moz-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.09);
			  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.09);
			  background-color: #fff;
			  padding: 5px;
			  border-radius: 6px;
			  opacity: 1;
			}
			&.unselected {
			  opacity: 0.7;
			}
		}
	}
}