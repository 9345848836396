.how-to-win {
  display: grid;
  place-items: center;
  padding: 10px;
  min-height: 100vh;
  &__content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.14);
    max-width: 768px;
    padding: 30px 0;
    border-radius: 4px;
    &__icons {
      width: 180px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      &.medal {
        width: 35px;
        height: 35px;
        object-fit: cover;
      }
      &--center {
        width: 55px;
        height: 55px;
        transform: translateY(-10px);
      }
      &--left {
        transform: rotate(-25deg);
      }
      &--right {
        transform: rotate(25deg);
      }
    }
    &--title {
      color: #030303;
      font-size: 20px;
    }
    &--description {
      width: 80%;
      color: #616261;
      font-size: 16px;
    }
  }
}