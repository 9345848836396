.modal-invite-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  &__icon {
    cursor: pointer;
    color: #bdbdbd;
    font-size: 26px;
    position: absolute;
    right: 15px;
    top: 17px;
    &:hover {
      color: darken(#bdbdbd, 20%);
    }
  }
  .help-text {
    font-family: 'SF Pro Text';
    font-size: 14px;
    color: lighten(#616261, 25%);
    text-align: center;
    max-width: 85%;
    margin: 5px auto 20px;
  }
  &__card {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .confirmation-button {
    padding: 11px 90px 11px 90px;
    margin-top: 35px;
    font-size: 12px;
    align-self: center;
  }
}

.invite-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 0 1 180px;
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 12px;
  padding: 20px 30px 25px 30px;
  &:not(:last-child) {
    margin-right: 15px;
  }
  &__image {
    width: 130px;
    height: 130px;
    display: grid;
    place-items: center;
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
  span {
    font-weight: bold;
    font-size: 16px;
    color: #2C3980;
    margin-top: 20px;
  }
}

.active-card {
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.09);
  transform: scale(1.05);
}