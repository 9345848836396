.add-employee-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #616261;
}

.invite-tabs {
  display: flex;
  width: 100%;
  height: 35px;
  border-bottom: 2px solid #D9D9D9;
  margin: 30px 0;
  &__tab {
    cursor: pointer;
    padding: 8px 12px;
    margin: 0 20px -2px 0;
    &.selected {
      border-radius: 4px 4px 0px 0px;
      border-bottom: 3px solid #8093FC;
      background: #F2F4FF;
    }
    h4 {
      font-size: 16px;
      color: #BDBDBD;
      margin-bottom: 0;
      &.selected {
        color: #8093FC;
      }
    }
  }
}

.copy-link {
  p {
    margin-bottom: 60px;
    font-size: 14px;
    color: #818181;
  }
  &__button {
    display: flex;
    align-items: center;
    p {
      margin-bottom: 0;
    }
    &__input {
      flex: 1;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      height: 42px;
      padding-left: 8px;
      border: 1px solid #D9D9D9;
      border-radius: 4px;
      margin-right: 10px;
      background: #ffffff;
      color: #999;
      input {
        flex-grow: 1;
        border: 0;
        background-color: transparent;
        outline: none !important;
        height: 100%;
        padding: 0 8px;
        font-size: 14px;
        color: #616261;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}