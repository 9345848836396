.filters {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  border-bottom: 2px solid #d9d9d9;
  margin: 10px 0 20px;
  &__filter {
    border: 0;
    background-color: transparent;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    margin-bottom: -2px;
    span {
      font-size: 16px;
      color: #606060;
      &.filtered {
        color: #8093fc;
      }
    }
    &.filtered {
      border-radius: 4px 4px 0 0;
      border-bottom: 3px solid #8093fc;
      background-color: #f2f4ff;
    }
  }
}
