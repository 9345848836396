.plan-card {
  border: 0.5px solid #cbe6e0;
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #fcfcfc;
  cursor: pointer;
  &:hover {
    background-color: darken(#fcfcfc, 2%);
  }
  .plan-image-container {
    background-color: #fff;
    border-right: 0.5px solid #cbe6e0;
    width: 198px;
    min-width: 198px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
    .plan-image {
      width: 134px;
      min-width: 134px;
    }
  }
  .plan-info {
    position: relative;
    padding: 16px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .unactive-plan-icon {
      position: absolute;
      top: 6px;
      right: 6px;
      font-size: 20px;
    }
    .plan-title {
      font-weight: bold;
      font-size: 20px;
      color: #616261;
      text-align: left;
    }
    .plan-description {
      font-weight: normal;
      font-size: 14px;
      color: #bdbdbd;
      text-overflow: ellipsis;
      text-align: left;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .plan-tags {
      display: flex;
      flex-wrap: wrap;
      span {
        background-color: #65dfb6;
        border-radius: 5px;
        font-weight: bold;
        font-size: 12px;
        line-height: 21px;
        text-align: center;
        color: #f9faff;
        min-width: 100px;
        padding: 2px 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        font-family: 'SF Pro Text';
        &:last-child {
          margin-right: 0px;
        }
      }
    }
    &__actions {
      display: flex;
      justify-content: flex-end;
    }
  }
  .plan-stats {
    border-left: 0.5px solid #cbe6e0;
    width: 160px;
    display: flex;
    flex-direction: column;
    .plan-stat-item {
      width: 100%;
      border-bottom: 0.5px solid #cbe6e0;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 160px;
      &:last-child {
        border-bottom: 0px;
      }
      .plan-stat-value {
        color: #65dfb6;
        font-weight: bold;
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 0;
        text-align: center;
        font-family: 'SF Pro Text';
      }
      .plan-stat-label {
        font-weight: normal;
        font-size: 12px;
        line-height: 21px;
        text-align: center;
        color: #818181;
        margin-bottom: 0;
        font-family: 'SF Pro Text';
      }
    }
  }
}

.unactive-plan-card {
  opacity: 0.5;
  cursor: default;
  &:hover {
    background-color: #fcfcfc !important;
  }
}
