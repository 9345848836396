.mediafileselector {
  &__header {
    padding: 0 0 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid rgba(108, 115, 154, 0.8);
    &_title {
      font-size: 20px;
      color: #616261;
      font-weight: 600;
      margin: 0;
    }
  }
  &__content {
    overflow-y: auto !important;
    max-height: 480px;
    margin-bottom: 16px;
    &_card {
      transition: 0s;
      position: relative;
      display: grid;
      grid-template-columns: minmax(80px, 30%) 1fr;
      background-color: #f0f3ff;
      width: 100%;
      min-height: 70px;
      border-radius: 8px;
      padding: 16px 12px;
      margin-bottom: 12px;
      &.selected {
        border: 2px solid #ea7dae;
      }
    }
    &_title {
      margin: 0;
      align-self: center;
      color: #616261;
    }
  }
}
