.satisfaction-level {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 685px;
  height: 370px;
	border: 0.5px solid #CBE6E0;
	border-radius: 8px;
	margin: 20px;
	background-color: #FCFCFC;
	p {
		align-self: flex-start;
		font-weight: normal;
		font-size: 18px;
		color: #616261;
		margin-left: 30px;
	}
	canvas {
		width: 635px !important;
		height: 295px !important;	
	} 
}
