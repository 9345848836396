.billing {
  &__content {
    &__methods {
      display: flex;
      width: calc(65% - 30px);
      flex-direction: row;
      padding: 40px 30px;
      justify-content: space-between;
      .method__card {
        width: calc(50% - 15px);
        background: #ffffff;
        border: 1px solid #cbe6e0;
        border-radius: 5px;
        height: 300px;
        cursor: pointer;
        &.selected {
          background: #edf0ff;
          border: 1px solid #8093fc;
        }
        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
        &__body {
          height: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &__footer {
          border-top: 1px solid #cbe6e0;
          margin: 40px;
          margin-top: 0px;
          padding-top: 30px;
          font-weight: bold;
          font-size: 16px;
          line-height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          color: #818181;
          &.selected {
            border-top: 1px solid #8093fc;
            color: #495fd7;
          }
        }
      }
    }
    &__pay__footer {
      width: 65%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
    }
  }
}
