.payment_config {

  &__form {

    &__buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-bottom: 10px;
      button {
        &:first-of-type {
          margin-right: 10px;
        }
      }
    }
  }

  &__tooltip {
    font-size: 14px;
    color: #bdbdbd;
    margin-top: 2px;
  }

  &__type_input_container {
    margin-bottom: 0px !important
  }
}
