.dashboard {
  &__main {
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    overflow: scroll;
    min-width: 0;
    background: #fff;
    display: flex;
    flex-direction: column;
    .data-container {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      @media (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }
  &__content {
    padding: 60px;
    background: white;
    border: 0.5px solid #cbe6e0;
    border-radius: 6px;
    margin: 20px 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    &-title {
      font-size: 24px;
    }
    &--form {
      max-width: 600px;
    }
    &--segments {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
    }
    &--plans,
    &--patients {
      display: flex;
      height: 100%;
      margin: 20px 35px;
    }
    &--left {
      justify-content: flex-start;
    }
  }
  &__scroll {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 30px 40px;
    overflow-y: auto;
    justify-content: center;
    height: 100%;
  }
}
.availabilities-banner {
  background-color: #f9db9e !important;

  .banner-content {
    color: #000 !important;
  }

  .pay-button {
    border: 1px solid #000 !important;
    color: #000 !important;

    &:hover {
      background-color: darken(#f9db9e, 10%) !important;
    }
  }
}

.trial-banner {
  background-color: #8093fc;
  width: 100vw;
  display: flex;
  padding-top: 6px;
  padding-bottom: 6px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 100px;
  padding-right: 100px;
  // position: absolute;
  // top: 0;
  // left: 0;
  .banner-content {
    margin-bottom: 0px;
    color: #fff;
    text-align: center;
    font-size: 16px;
    margin-left: 10px;
    margin-right: 40px;
    span {
      font-weight: bold;
      color: #f9db9e;
    }
  }

  .pay-button {
    border: 1px solid #fff;
    color: #fff;
    cursor: pointer;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 15px;
    padding-right: 15px;
    border-width: 1px;
    border-radius: 15px;
    &:hover {
      background-color: darken(#8093fc, 4%);
    }
  }
}

.patients-banner {
  background-color: #ffbb6a;
  border-radius: 3px;
  padding: 10px 28px 10px 28px;
  margin: 0px 25px;
  display: flex;
  align-items: center;
  display: flex;
  color: #4b390f;
}
