.icon {
  &--list {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 17px;
    line-height: 21px;
    color: #2c3980;
    padding: 10px 12px;
    margin-left: 30px;
    span {
      margin-bottom: 0;
      margin-left: 6px;
    }
  }
  &--close {
    background-color: #ced5fd;
    border-radius: 4px;
  }
}
