.pagination-container {
  margin: 0;
  display: flex;
  list-style-type: none;
  &--item {
    font-size: 14px;
    color: #bdbdbd;
    width: 24px;
    height: 24px;
    text-align: center;
    margin: 8px;
    cursor: pointer;
    &.active {
      color: #495fd7;
      font-weight: bold;
      &:hover {
        color: darken(#495fd7, 5%);
      }
    }
    &:hover {
      color: darken(#bdbdbd, 10%);
    }
    &.arrow {
      color: #8093fc;
      font-size: 30px;
      margin-top: 0px;
      &:hover {
        color: darken(#8093fc, 10%);
      }
      &.disabled {
        pointer-events: none;
        color: #bdbdbd;
      }
    }
  }
}
