%center-phone {
  display: grid;
  place-items: center;
}
.readingPreview {
  @extend %center-phone;
  position: relative;
  width: 100%;
  height: 100%;
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    font-size: 28px;
  }
  &__border {
    @extend %center-phone;
    width: 414px;
    height: 736px;
    border-radius: 45px;
    background-color: #152839;
    border: 1px solid rgba(255, 255, 255, 0.6);
    box-shadow: inset 0 0 3px 1px #fff;
    color: #fff;
  }
  &__background {
    @extend %center-phone;
    width: 96%;
    height: 98%;
    border-radius: 40px;
    background-color: #000;
  }
  &__container {
    overflow-y: auto;
    width: 96%;
    height: 702px;
    border-radius: 35px;
    &_hero {
      width: 100%;
      height: 224px;
      background-color: rgba(255, 255, 255, 0.5);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: left;
    }
  }
  &__content {
    min-height: calc(100% - 200px);
    padding-bottom: 14px;
    margin-top: -24px;
    border-radius: 24px 24px 0px 0px;
    background-color: #fff;
    color: #000;
    &.empty-state {
      @extend %center-phone;
    }
    &_empty {
      color: #616261;
      font-size: 14px;
    }
  }
  &__header {
    padding: 28px 14px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    &_title {
      font-weight: 700;
      font-size: 20px;
      color: #000000;
    }
    &_save-icon {
      flex-shrink: 0;
      width: 16px;
      height: 14px;
      background-color: #000;
      -webkit-clip-path: polygon(0 0, 100% 1%, 100% 100%, 0 100%, 40% 50%);
      clip-path: polygon(0 0, 100% 1%, 100% 100%, 0 100%, 40% 50%);
      transform: rotate(270deg);
    }
  }
  &__info {
    padding: 0 14px;
    &_title {
      margin: 8px 0;
      &.heading_1 {
        font-size: 20px;
      }
      &.heading_2 {
        font-size: 18px;
      }
      &.heading_3 {
        font-size: 16px;
      }
    }
    &_content {
      font-size: 16px;
      line-height: 24px;
      color: #000;
      margin: 8px 0;
    }
    &_img {
      width: 100%;
      height: 180px;
      object-fit: cover;
      border-radius: 12px;
    }
    &_list {
      padding-left: 20px;
      margin: 8px 0;
      li {
        font-size: 15px;
        line-height: 24px;
        color: #000;
      }
    }
  }
}
.bold-block {
  font-weight: 700;
}
