.exercise {
  &__modal {
    .close-icon-container {
      width: 100%;
      text-align: right;
      .close-icon {
        font-size: 28px;
        cursor: pointer;
        color: #616261;
        margin-right: -25px !important;
        margin-top: 5px;
        &:hover {
          color: #495fd7;
        }
      }
    }
    &__title {
      width: 100%;
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #616261;
      margin-top: -10px;
    }
    &__info {
      width: 100%;
      margin-top: 40px;
      display: flex;
      flex-direction: column;
    }
    &__row {
      display: flex;
      flex-direction: row;
      .info-col {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        flex: 1;
        &:last-child {
          margin-left: 20px;
        }
        .label {
          font-weight: bold;
          font-size: 16px;
          color: #818181;
        }
        .value {
          font-weight: normal;
          font-size: 14px;
          color: #bdbdbd;
          margin-bottom: 7px;
          font-family: 'SF Pro Text';
        }
      }
    }
    &__data {
      margin-left: 30px;
      display: flex;
      flex-direction: column;
      width: 100%;
      .info-row {
        display: flex;
        flex-direction: row;
        background: #fcfcfc;
        justify-content: space-between;
        padding: 7px 20px 7px 20px;
        margin-bottom: 10px;
        p {
          margin-bottom: 0px;
          &.label {
            font-weight: bold;
            font-size: 16px;
            color: #8093fc;
          }
          &.value {
            font-weight: bold;
            font-size: 18px;
            color: #616261;
          }
        }
      }
      .button-container {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-grow: 1;
        .add-exercise {
          border-radius: 10px !important;
          padding: 7px 20px 7px !important;
          .icon {
            font-size: 18px;
            font-size: 18px;
            margin-right: 5px;
            margin-top: -2px;
          }
        }
      }
    }
  }
}
