.billing {
  &__content {
    &__confirm {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 40px 30px;
      min-height: 50vh;
      align-items: center;
      justify-content: center;
      .button__confirm {
        width: 330px;
        margin-bottom: 50px;
      }
      &__container {
        margin-bottom: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .summary__card {
          background: #ffffff;
          border: 1px solid #cbe6e0;
          border-radius: 5px;
          width: calc(33% - 20px);
          height: 200px;
          padding: 30px 15px;
          display: flex;
          flex-direction: column;
          .text {
            font-family: 'SF Pro Text';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 15px;
            color: #bdbdbd;
          }
          &__top__container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 50%;
            flex-grow: 1;
            padding-bottom: 10px;
            .title {
              font-family: 'SF Pro Text';
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              color: #818181;
            }
            .button {
              border-radius: 10px;
              padding-top: 5px;
              padding-bottom: 5px;
              font-size: 14px;
              &.button--secondary-rounded {
                color: #8093fc;
                min-width: 110px;
              }
            }
          }
          &__footer__container {
            display: flex;
            border-top: 1px solid #cbe6e0;
            padding-top: 20px;
            height: 40%;
            justify-content: space-between;
            &.price {
              align-items: center;
              p {
                margin-bottom: 0;
              }
            }
            img {
              max-width: 27%;
            }
            .total__price {
              font-family: 'SF Pro Text';
              font-weight: normal;
              font-size: 16px;
              color: #6fcf97;
              border: 1px solid #cbe6e0;
              border-radius: 2px;
              padding: 4px 6px;
              span {
                color: #65dfb6;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
    &__confirm__footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
      padding-top: 20px;
      border-top: 1px solid #e0e0e0;
    }
  }
}
