.patients {
  &__container {
    width: 100%;
    height: 100%;
    .calendar-top-bar {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      margin-top: 0px;
      justify-content: space-between;
      .view-selector {
        display: flex;
        flex-direction: row;
        button {
          margin: 0px 4px;
        }
      }
      .current-selector {
        display: flex;
        flex-direction: row;
        .current-selection-label {
          display: flex;
          margin: 0px 10px;
          height: 100%;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .calendar-body {
      height: 100%;
    }
    .month-panel {
      width: 100%;
      display: flex;
      flex-direction: column;
      border: 1px solid rgb(227, 227, 227);
      padding: 10px;
      border-radius: 5px;
      .month-panel-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: #616261;
        font-family: 'SF Pro Text';
        font-style: normal;
        align-items: center;
        .arrow-buttons {
          display: flex;
          flex-direction: row;
        }
      }
      .month-panel-body {
        display: grid;
        grid-template-columns: auto auto auto auto auto auto auto;
        margin-top: 20px;
        color: #616261;
        font-family: 'SF Pro Text';
        font-style: normal;
        .month-panel-item {
          text-align: center;
          line-height: 32px;
          &.past {
            color: lighten(#616261, 40%);
            &.selected {
              color: lighten(#fff, 40%);
            }
          }
          &.selected {
            color: #fff;
            background-color: lighten(lightseagreen, 10%);
            &.first-child {
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
            }
            &.last-child {
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
            }
          }
          .content-item {
            &.today {
              background-color: #495fd7 !important;
              border-radius: 10px;
              text-align: center;
              color: #fff !important;
              width: 100% !important;
              height: 100% !important;
            }
          }
          &.clickable {
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .month-view {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      border: 1px solid rgb(227, 227, 227);
      margin-top: 20px;
      border-radius: 5px;
      .month-view-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: #616261;
        font-family: 'SF Pro Text';
        font-style: normal;
        font-size: 18px;
        align-items: center;
        border-bottom: 1px solid rgb(227, 227, 227);
        padding: 20px 30px;
        background-color: rgb(245, 245, 245);
      }
      .month-view-body {
        display: grid;
        flex-grow: 1;
        grid-template-columns: auto auto auto auto auto auto auto;
        color: #616261;
        font-family: 'SF Pro Text';
        font-style: normal;
        .month-view-item {
          text-align: right;
          border-right: 0.2px solid rgb(230, 230, 230);
          border-bottom: 0.2px solid rgb(230, 230, 230);
          padding: 10px;
          font-size: 14px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          &.title {
            font-weight: bolder;
            background-color: #e6e8f0;
            display: flex;
            letter-spacing: 1px;
            justify-content: center;
            align-items: center;
            border-right: 0.2px solid #ced1da;
            border-bottom: 0.2px solid #ced1da;
          }
          &.past {
            background-color: lighten(#616261, 60%);
          }
          &.today {
            font-weight: bolder;
            color: #495fd7;
          }
          .events-count {
            color: #7b7878;
            font-size: 12px;
            border-radius: 2px;
            text-align: left;
            font-weight: normal;
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            div {
              background-color: #495fd7;
              width: 10px;
              height: 10px;
              border-radius: 10px;
              margin-right: 5px;
            }
            &:hover {
              color: #495fd7;
              text-decoration: underline;
            }
          }
        }
      }
    }
    .week-view {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      border: 1px solid rgb(227, 227, 227);
      margin-top: 20px;
      border-radius: 5px;
      margin-bottom: 40px;
      .week-view-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: #616261;
        font-family: 'SF Pro Text';
        font-style: normal;
        font-size: 18px;
        align-items: center;
        border-bottom: 1px solid rgb(227, 227, 227);
        padding: 20px 30px;
        background-color: rgb(245, 245, 245);
      }
      .week-view-body {
        display: grid;
        flex-grow: 1;
        grid-template-columns: auto auto auto auto auto auto auto auto;
        color: #616261;
        font-family: 'SF Pro Text';
        font-style: normal;
        .week-view-item {
          text-align: right;
          border-right: 0.2px solid rgb(230, 230, 230);
          border-bottom: 0.2px solid rgb(230, 230, 230);

          font-size: 14px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          &.disabled {
            background-color: #f9f9fa;
          }
          &.hour {
            background-color: #f6f7fa;
            padding: 10px;
          }
          &.title {
            background-color: #e6e8f0;
            display: flex;
            letter-spacing: 1px;
            padding: 10px;
            justify-content: center;
            align-items: center;
            border-right: 0.2px solid #ced1da;
            border-bottom: 0.2px solid #ced1da;
            .day-number {
              font-weight: bolder;
              &.today {
                font-weight: bolder;
                color: #495fd7;
              }
            }
            .day-label {
              &.today {
                font-weight: bolder;
                color: #495fd7;
              }
            }
          }
        }
      }
    }
    .day-view {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      border: 1px solid rgb(227, 227, 227);
      margin-top: 20px;
      border-radius: 5px;
      margin-bottom: 40px;
      .day-view-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: #616261;
        font-family: 'SF Pro Text';
        font-style: normal;
        font-size: 18px;
        align-items: center;
        border-bottom: 1px solid rgb(227, 227, 227);
        padding: 20px 30px;
        background-color: rgb(245, 245, 245);
      }
      .day-view-body {
        display: grid;
        flex-grow: 1;
        grid-template-columns: 100px auto;
        color: #616261;
        font-family: 'SF Pro Text';
        font-style: normal;
        .day-view-item {
          text-align: right;
          border-right: 0.2px solid rgb(230, 230, 230);
          border-bottom: 0.2px solid rgb(230, 230, 230);
          font-size: 14px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          &.hour {
            padding: 10px;
            background-color: #f6f7fa;
          }
          &.title {
            padding: 10px;
            background-color: #e6e8f0;
            display: flex;
            letter-spacing: 1px;
            justify-content: center;
            align-items: center;
            border-right: 0.2px solid #ced1da;
            border-bottom: 0.2px solid #ced1da;
          }
        }
      }
    }
  }
  &__no-results,
  &__empty-state {
    width: 100%;
    padding: 60px;
    background: white;
    background-color: #f9faff;
    border: 0.5px solid #cbe6e0;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      max-width: 40%;
      margin-right: 60px;
      font-family: 'SF Pro Text' !important;
      .title {
        font-size: 24px;
        line-height: 36px;
        font-weight: bold;
        color: #616261;
      }
      .subtitle {
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #bdbdbd;
        margin-bottom: 40px;
      }
    }
    .image {
      width: 30% !important;
    }
    .icon {
      margin-top: -2px;
    }
  }
  &__no-results {
    border: 0px !important;
    margin-bottom: 0px !important;
    max-height: 100%;
    padding: 50px;
    .image {
      width: 30%;
    }
  }
}
.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.event-card {
  width: 100%;
  text-align: left;
  padding: 10px;
  cursor: pointer;
  &.call {
    border-left: 2px solid darken(#2cd9d0, 10%);
    background-color: #2cd9d0;
  }
  &.event {
    border-left: 2px solid #ff6a6a;
    background-color: #ffcccc;
  }
  &.awaiting-call {
    border-left: 2px solid #ffbb6a;
    background-color: #ffe7cc;
  }
  p.title {
    margin-bottom: 2px;
    &.call {
      color: #fff;
      font-weight: 600;
    }
  }
  p.info {
    margin-bottom: 0;
    font-size: 12px;
    color: #878686;
    &.call {
      color: #fff;
    }
  }
}

.availability-form {
  .form-row {
    display: flex;
    flex-direction: row;
    margin-left: 0px;
    .form__input-container {
      flex-grow: 1;
      margin-right: 20px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

.availability-row {
  display: grid;
  flex-grow: 1;
  grid-template-columns: 25% 25% 25% 25%;
  span {
    border: 0.2px solid rgb(230, 230, 230);
    color: #616261;
    font-family: 'SF Pro Text';
    font-style: normal;
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    padding: 4px;
    &.day {
      background-color: #f6f7fa;
    }
    .action {
      cursor: pointer;
      &:hover {
        color: #495fd7;
      }
    }
  }
}
