.template-detail {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 30px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  &__description {
    flex: 1;
    max-width: 768px;
  }
  &__avatar {
    padding: 0 20px;
  }
  &__camera {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 4px solid #ffffff;
    margin-left: 60%;
    margin-top: 60%;
    background-color: #495fd7;
    cursor: pointer;
    display: grid;
    place-items: center;
    .icon {
      color: #fff;
      font-size: 40px;
    }
    &:hover {
      background-color: darken(#495fd7, 5%);
    }
  }
  &__button {
    align-self: flex-end;
    display: flex;
    margin-left: 20px;
  }
}

.readings-list-container {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  &__list {
    border-radius: 4px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.14);
    padding-bottom: 15px;
    margin: 20px 10px;
    &--title {
      font-size: 18px;
      font-weight: 600;
      color: #616261;
      padding: 10px;
    }
    .card-container {
      position: relative;
      max-height: 400px;
      overflow-y: auto;
      &__card {
        position: relative;
        display: flex;
        align-items: center;
        height: 85px;
        padding: 10px;
        margin: 0 15px 10px;
        border-radius: 4px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.14);
        &--image {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          object-fit: cover;
        }
        &__content {
          flex: 1;
          color: #616261;
          padding-left: 10px;
          h3 {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 0;
          }
        }
        &.selected {
          background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.3));
          opacity: 0.4;
          pointer-events: none;
        }
      }
      .mask-reading-card {
        position: relative;
        svg {
          position: absolute;
          top: 30%;
          left: 47%;
          color: #46c2ad;
          font-size: 40px;
        }
      }
      &__image {
        margin-top: 20px;
        display: grid;
        place-items: center;
        img {
          display: block;
          width: 350px;
          height: auto;
          object-fit: cover;
        }
      }
    }
  }
}
