.date-picker {
  &__select {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: gray 0.15s ease-in-out, black 0.15s ease-in-out;
    width: 50%;
    display: inline-block;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0px;
    -webkit-appearance: none;
    margin-top: -7px;
    font-size: 16px;
    cursor: pointer;
  }
}
