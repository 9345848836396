.dashboard {
  &__content {
    &__forms {
      background-color: #f5f6f9;
      display: flex;
      flex-grow: 1;
      flex-direction: row;
    }
    &__components {
      width: 30vw;
      min-width: 30vw;
      padding: 10px 20px;
      max-height: calc(100vh - 112px);
      overflow: scroll;
      border-left: 1px solid #dce0e5;
      label {
        font-family: 'SF Pro Text';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #616261;
      }
      .form__error {
        font-family: 'SF Pro Text';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
      }
      .form__input-container {
        margin-bottom: 15px;
      }
      hr {
        margin: 30px 0px;
      }
      .input_field_trash {
        display: flex;
        margin-top: 5px;
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type='number'] {
          -moz-appearance: textfield;
        }
        .form__input-container {
          flex-grow: 1;
        }
        .pointed-input {
          margin-left: 4px;
          max-width: 45px;
        }
        color: #616261;
        svg {
          font-size: 18px;
          line-height: 30px;
          margin-left: 10px;
          margin-top: 8px;
          cursor: pointer;
          &:hover {
            color: lighten(#495fd7, 8%);
          }
        }
      }
      .list_options_add_button {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        line-height: 20px;
        color: #616261;
        margin-bottom: 20px;
        .options_title {
          font-family: 'SF Pro Text';
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
        }
        svg {
          font-size: 20px;
          line-height: 30px;
          cursor: pointer;
          &:hover {
            color: lighten(#495fd7, 8%);
          }
        }
      }
      &.edit_mode {
        box-shadow: -1px 1px 2px 0px rgba(150, 150, 150, 0.28);
        border-left: 0px solid #dce0e5;
      }
      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 48px;
        color: #616261;
      }
      .description {
        font-family: 'SF Pro Text';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #616261;
      }
      .component_list {
        list-style-type: none;
        margin-top: 30px;
        li {
          background-color: white;
          margin-bottom: 15px;
          border-radius: 4px;
          padding: 15px;
          border: 1px solid #dce0e5;
          cursor: pointer;
          color: #7d7c8e;
          display: flex;
          align-items: center;
          .input_icon {
            font-size: 20px;
          }
          .input_content {
            padding-left: 20px;
            flex: 1;
            p {
              margin-bottom: 0px;
              font-family: 'SF Pro Text';
              font-style: normal;
              font-size: 14px;
              &.input_title {
                font-weight: 500;
                line-height: 18px;
              }
              &.input_description {
                font-size: 12px;
                line-height: 16px;
              }
            }
          }
        }
        li:hover {
          box-shadow: 1px 1px 2px 0px rgba(216, 216, 216, 0.6);
          cursor: pointer;
        }
        li * {
          opacity: 0.95;
          transition: opacity 1s;
        }
        li:hover * {
          opacity: 1;
          transition: opacity 1s;
          color: lighten(#495fd7, 8%);
        }
      }
      .create-group-container {
        display: flex;
        flex-direction: column;
        button {
          margin-top: 10px;
          align-self: flex-end;
        }
      }
      .disabled-editor {
        pointer-events: none;
        opacity: 0.5;
      }
    }
    &__overlay_area {
      flex-grow: 1;
      width: calc(100% - 30vw);
      height: calc(100vh - 112px);
      max-width: calc(100% - 30vw);
      max-height: calc(100vh - 112px);
      position: fixed;
      top: 112px;
      left: 0px;
      z-index: 100 !important;
      background-color: rgba(0, 0, 0, 0.38) !important;
    }
    &__workspace {
      flex-grow: 1;
      max-height: calc(100vh - 112px);
      overflow: scroll;
      padding: 20px;
      .fields_area {
        background-color: white;
        flex-grow: 1;
        border-radius: 4px;
        padding: 30px 20px;
        ul {
          list-style-type: none;
        }
        div.empty_state {
          padding: 50px;
          display: flex;
          border-radius: 4px;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: 1px dashed #7d7c8e31;
          h2 {
            text-align: center;
            font-family: 'SF Pro Text';
            font-weight: bold;
            font-size: 18px;
            line-height: 27px;
            color: #818181;
          }
          p {
            text-align: center;
            font-family: 'SF Pro Text';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            color: #bdbdbd;
            padding: 0px 40px;
          }
          img {
            margin-bottom: 25px;
            width: 40%;
          }
        }
      }
      .title-bar {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        &__title {
          font-family: 'SF Pro Text';
          font-weight: 500;
          font-size: 20px;
          color: #616261;
          max-width: 75%;
          margin: 0px 10px;
          cursor: pointer;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          &:hover {
            color: lighten(#495fd7, 8%);
          }
        }
        &__preview {
          margin-right: auto;
          font-size: 20px;
          color: darken(#616261, 20%);
          cursor: pointer;
          &:hover {
            color: lighten(#495fd7, 5%);
          }
        }
        &__back-button {
          font-size: 22px;
          line-height: 20px;
          color: #616261;
          cursor: pointer;
          &:hover {
            color: lighten(#495fd7, 8%);
          }
        }
      }
    }
  }
}

.group-container {
  margin-bottom: 20px;
  border-bottom: 1px solid #dce0e5;
}

.group-title {
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.group-title-edit {
  display: flex;
}

.group-title-input {
  max-width: 450px;
  margin-right: 10px;
}

.group-title h2 {
  font-weight: 600;
  font-size: 20px;
  color: #616261;
}

li.field_question {
  display: flex;
  padding: 20px 0px;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 1px 1px 2px 0px rgba(216, 216, 216, 1);
}
.field_icon {
  color: #616261;
  margin-right: 20px;
  font-size: 18px;
  line-height: 16px;
}
.field_content {
  flex: 1;
  max-width: 80%;
  color: #616261;
}
.field_title {
  font-family: 'SF Pro Text';
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.field_description {
  font-family: 'SF Pro Text';
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0px;
}
.field_buttons {
  max-height: 38px;
  padding: 5px 10px;
  border-radius: 3px;
  color: #7d7c8e;
  border: 1px solid #dce0e5;
  box-shadow: 1px 1px 2px 0px rgba(216, 216, 216, 1);
}
.upd-del-icons {
  margin: 4px 6px;
  cursor: pointer;
}

.upd-del-icons:hover {
  color: lighten(#495fd7, 8%);
}

.block-type {
  min-height: 240px;
  margin-bottom: 15px;
}

.survey-preview {
  overflow-y: auto;
  &.full-witdh-survey-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  height: 95vh;
  &__header {
    padding: 20px 10px 0;
    &--title {
      font-family: 'SF Pro Text';
      font-weight: 500;
      font-size: 18px;
      color: #616261;
    }
  }
  &__content {
    padding-bottom: 20px;
  }
  &__sections {
    width: calc(1280px - 320px) !important;
    padding-top: 10px !important;
  }
}
