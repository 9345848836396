.dashboard {
  &__content {
    &__create_patient {
      background-color: #f5f6f9;
      display: flex;
      flex-grow: 1;
      flex-direction: row;
      overflow: hidden;
    }
    &__wizard {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      &__content {
        max-height: calc(100vh - 112px);
        overflow: scroll;
        padding: 30px;
        border-left: 1px solid #dce0e5;
        width: 100%;
      }
    }
  }
}

.content__side-wizard {
  width: 30vw;
  border: 0px;
  display: flex;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 173px;
}

.content__container {
  flex-grow: 1;
  background-color: white;
  width: 100%;
  padding: 40px;
  padding-left: 60px;
  border-radius: 4px;
  .section-title {
    margin-top: 20px;
    margin-bottom: 20px;
    font-style: normal;
    font-family: 'SF Pro Text';
    font-weight: 500;
    font-size: 24px;
    line-height: 26px;
    color: #616261;
  }
  .observations-field {
    font-size: 14px;
    line-height: 21px;
    color: #616261;
    font-weight: normal;
  }
  .section-description {
    font-family: 'SF Pro Text' !important;
    color: #bdbdbd;
    font-weight: normal;
    font-size: 14px;
    span {
      font-weight: bold;
    }
  }
  .button-container {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
  }
  label,
  .form__label {
    font-family: 'SF Pro Text' !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #616261;
  }
}

.content__fullwidth {
  flex-grow: 1 !important;
  width: 100% !important;
  max-width: 92vw;
  .button-container {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
  }
  .section-title {
    color: #616261;
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .section-description {
    color: #bdbdbd;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 50px;
    span {
      font-weight: bold;
    }
  }
}

.content_create_patient {
  padding: 0px !important;
  justify-content: flex-start !important;
  border: 0px !important;
  padding-bottom: 30px !important;
  height: 100%;
}

.icon-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  .add-icon {
    position: relative;
    top: 56px;
    left: 32px;
    font-size: 24px;
    cursor: pointer;
    color: #e0e0e0;
    &:hover {
      color: #8093fc;
    }
  }
  .remove-icon {
    position: absolute;
    top: 56px;
    right: -60px;
    font-size: 28px;
    cursor: pointer;
    color: #e0e0e0;
    &:hover {
      color: #8093fc;
    }
  }
}

.horizontal-line {
  margin-top: 50px;
  margin-bottom: 45px;
  margin-left: -35px;
  width: 110%;
  height: 0px;
  border-bottom: 1px solid #ebeef3;
}
