.no-data-found {
  width: 100%;
  height: 100%;
  margin: 0 !important;
  background-color: transparent !important;
  display: flex;
  justify-content: center;
  padding: 12px 0;
  margin: auto 0;
  .content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    &__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      p {
        background: transparent;
      }
      &_title {
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        color: #818181;
      }
      &_subtitle {
        width: 60%;
        font-weight: normal;
        font-size: 14px;
        text-align: center;
        color: #bdbdbd;
      }
    }
    &__image {
      width: 35%;
      img {
        width: 90%;
        min-width: 200px;
        max-width: 600px;
        display: block;
        overflow: hidden;
        object-fit: cover;
      }
    }
  }
}
