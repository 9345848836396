.no-team-members-container {
  height: 304px;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__content {
    width: 60%;
    .title {
      font-size: 20px;
      font-weight: bold;
      color: #616261;
    }
    .subtitle {
      font-size: 14px;
      color: #bdbdbd;
    }
  }
  &__image {
    padding-left: 20px;
    display: flex;
    justify-content: flex-end;
    width: 40%;
    img {
      width: 100%;
      height: auto;
      display: block;
      object-fit: cover;
    }
  }
}