.billing {
  &__content {
    .features-label {
      font-weight: bold;
      font-size: 15px;
      line-height: 20px;
      color: #818181;
      margin: 20px 0 20px 0;
    }
    &__intro {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 30px 100px;
      p {
        margin-bottom: 0;
        max-width: 55%;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #bdbdbd;
        font-family: 'SF Pro Text';
      }
    }
    &__plans {
      background: #ffffff;
      border: 1px solid #cbe6e0;
      box-sizing: border-box;
      border-radius: 10px;
      display: flex;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      &__body {
        display: flex;
        justify-content: space-between;
        min-height: 40vh;
        padding: 40px 10px 0px 80px;
      }
      &__footer {
        display: flex;
        padding-top: 20px;
        padding-bottom: 29px;
        align-items: center;
        justify-content: center;
        width: 100%;
        div {
          display: flex;
          .icon {
            color: #bdbdbd;
            font-size: 20px;
          }
          p {
            margin-bottom: 0px;
            width: 100%;
            text-align: center;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            color: #bdbdbd;
            margin-left: 8px;
            font-family: 'SF Pro Text';
          }
        }
      }
    }
    &__features {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      max-width: calc(50% - 55px);
      height: 100%;
      .patients__slider {
        margin-bottom: 20px;
        .label {
          font-weight: normal;
          font-size: 20px;
          line-height: 48px;
          color: #616261;
          margin-bottom: 30px;
        }
        .rc-slider-rail,
        .rc-slider-track,
        .rc-slider-step {
          height: 14px !important;
        }
        .rc-slider-rail {
          background-color: #e0e0e0;
        }
        .rc-slider-track {
          background-color: #8093fc;
        }
        .rc-slider-handle {
          width: 24px;
          height: 24px;
          border: 5px solid #495fd7;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
        }
        .unit_price {
          font-family: 'SF Pro Text';
          font-weight: normal;
          font-size: 12px;
          line-height: 48px;
          color: #bdbdbd;
          margin-top: 25px;
          width: 100%;
          text-align: center;
          margin-bottom: 0px;
        }
        .big_group {
          font-family: 'SF Pro Text';
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          color: #bdbdbd;
          margin-top: 15px;
          width: 100%;
          margin-bottom: 0px;
        }
      }
      .features__list {
        list-style-type: none;
        li {
          border-bottom: 1px solid #cbe6e0;
          display: flex;
          flex-direction: row;
          padding: 20px 0px;
          align-items: center;
          p {
            font-family: 'SF Pro Text';
            margin-bottom: 0px;
            font-weight: normal;
            font-size: 16px;
            color: #818181;
            margin-left: 10px;
          }
          .icon {
            font-size: 26px;
            color: #65dfb6;
            min-width: 30px;
          }
        }
      }
    }
    &__options {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      max-width: calc(55% - 55px);
      height: 100%;
      list-style-type: none;
      .option__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .spacer {
          width: 34px;
        }
        .suggested__tag {
          background: #ffda95;
          border-radius: 5px 0px 0px 5px;
          width: 34px;
          height: 122px;
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            margin-bottom: 0px;
            transform: rotate(-90deg);
            color: #ffffff;
            text-align: center;
            font-family: SF Pro Text;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 31px;
          }
        }
      }
      li.option__item {
        margin-bottom: 20px;
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        border: 1px solid #cbe6e0;
        border-radius: 3px;
        height: 135px;
        min-width: 500px;
        .plan__name {
          background: #edf0ff;
          border-radius: 2px 0px 0px 2px;
          width: 140px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 10px;
          p {
            color: #616261;
            margin-bottom: 0px;
            font-weight: bold;
            font-size: 21px;
            line-height: 31px;
            text-align: center;
            &.discount {
              margin-top: 10px;
            }
          }
        }
        .plan__info {
          display: flex;
          flex-grow: 1;
          padding: 10px 20px;
          .details__list {
            list-style-type: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .details__list__item {
              display: flex;
              flex-direction: row;
              padding: 10px 0px;
              align-items: center;
              p {
                font-family: 'SF Pro Text';
                margin-bottom: 0px;
                font-weight: normal;
                font-size: 14px;
                color: #a1aeb7;
                margin-left: 10px;
              }
              .icon {
                font-size: 26px;
                color: #65dfb6;
              }
            }
          }
        }
        .plan__price {
          border-radius: 0px 2px 2px 0px;
          width: 140px;
          margin-right: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          p {
            color: #616261;
            font-weight: bold;
            font-size: 21px;
            line-height: 31px;
            text-align: center;
          }
          .button {
            border-radius: 15px !important;
            padding-top: 9px;
            padding-bottom: 9px;
            margin: 0px !important;
            font-size: 14px !important;
            &.button--secondary-rounded {
              color: #8093fc;
              min-width: 110px;
            }
          }
        }
        &.suggested {
          background: #f9faff;
          .plan__name {
            background-color: #8093fc;
            p {
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
