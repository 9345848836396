.authentication {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  &__logo {
    width: 93px;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  &__container {
    position: relative;
    display: flex;
    width: 100%;
    min-height: 100vh;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1070px) {
      justify-content: center;
    }
  }
  &__form {
    text-align: center;
    padding: 30px 60px;
    margin-right: auto;
    margin-left: auto;
    width: 90%;
    max-width: 500px;
    &_title {
      font-size: 24px;
      font-weight: bold;
      padding-bottom: 20px;
      color: #2c3980;
    }
    &_footer {
      display: none;
      position: absolute;
      bottom: 0;
      padding-bottom: 1rem;
      color: #bdbdbd;
      font-size: 14px;
      @media (max-width: 1070px) {
        display: block;
      }
    }
    @media (max-width: 1070px) {
      padding: 30px 10px;
    }
  }
  &__title {
    color: white;
    margin-bottom: 20px;
  }
  &__image {
    width: 50%;
    height: 100vh;
    background-image: url('../../assets/images/frame.png');
    background-size: cover;
    background-position: center;
    @media (max-width: 1070px) {
      display: none;
    }
    &.company {
      background-image: url('../../assets/images/login-employers.png');
    }
  }
}

.password-recovery-button {
  display: flex;
  justify-content: center;
}
