.bookings-container {
  margin-right: 15px;
  margin-left: 25px;
  .panel {
    display: flex;
    width: 100%;
    min-width: 480px;
    margin: 30px 0 0 0;
    cursor: pointer;
    .panel-head {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 10px;
      border-bottom: 1px solid #D9D9D9;
      &.active {
        border-bottom: 4px solid #8093FC;
      }
      &--0 {
        margin-right: 28px;
      }
      .panel-title {
        width: 60%;
        margin: 0 0 0 auto;
        font-weight: bold;
        font-size: 16px;
        color: #BDBDBD;
        &.panel-title-active {
          color: #8093FC;
        }
      }
      .panel-badge {
        width: 24px;
        height: 24px;
        background: #F2F4FF;
        border-radius: 4px;
        margin: 0;
        text-align: center;
        font-weight: bold;
        font-size: 12px;
        line-height: 24px;
        color: #C1C5D7;
        &.panel-badge-active {
          color: #8093FC;
        }
      }
    }
  }
  .bookings {
    background: #fcfcfc;
    width: 100%;
    min-width: 480px;
    margin: 30px 0 10px 0;
    border: 0.5px solid #CBE6E0;
    border-radius: 8px;
    &-empty {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 50px;
      .icon-phone {
        color: #8093fc;
        font-size: 22px;
        margin-right: 10px;
      }
      h2 {
        color: #616261;
        font-weight: bold;
        font-size: 22px;
        margin: 0;
      }
    }
    &__panel {
      width: 100%;
      .panel-body {
        max-height: 255px;
        overflow-y: scroll;
        .incomming-pending-item {
          .avatar {
            width: 80px;
            margin-right: 30px;
          }
          .info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-grow: 1;
            .name {
              font-weight: bold;
              font-size: 16px;
              color: #616261;
              margin-bottom: 6px;
            }
            .label {
              margin-bottom: 0px;
              font-weight: normal;
              font-size: 14px;
              color: #bdbdbd;
            }
            .time {
              font-size: 14px;
              color: #8093FC;
              margin-bottom: 6px;
              .icon {
                margin-right: 10px;
              }
            }
            .date {
              margin-bottom: 0px;
              font-weight: normal;
              font-size: 14px;
              color: #818181;
              margin-left: 26px;
            }
          }
          .actions {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .seemore {
              background-color: #FCFCFC;
              border-radius: 10px;
              color: #8093fc;
            }
          }
        }
        .item {
          display: flex;
          flex-direction: row;
          border-bottom: 0.5px solid #D9D9D9;
          padding: 14px 0;
          margin: 0 24px 0 24px;
          &:last-child {
            border: none;
          }
        }
      }
    }
  }  
}