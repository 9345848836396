.categories {
  display: flex;
  width: 100%;
  padding: 20px;
  background-color: #fafbff;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  &.create-plan-view {
    z-index: 10;
    position: absolute;
  }
  &.create-active-break-view {
    z-index: 1;
    position: absolute;
    margin-top: 10px;
  }
  &__items {
    &:not(&:last-child) {
      margin-right: 40px;
    }
    &--title {
      padding-bottom: 5px;
      border-bottom: 1px solid #d9d9d9;
      font-weight: bold;
      font-size: 16px;
      color: #030303;
    }
    &_list {
      display: grid;
      grid-column-gap: 6px;
      grid-template-columns: 1fr 1fr;
      &.levels {
        grid-template-columns: 1fr;
      }
      &--item {
        text-align: left;
        outline: none;
        padding: 2px 4px;
        border: 0;
        margin-bottom: 14px;
        background-color: transparent;
        font-size: 14px;
        color: #606060;
        &.selected {
          border-radius: 4px;
          background-color: darken(#fafbff, 8%);
        }
      }
    }
  }
}
