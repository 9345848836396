.form_button {
  border: 1px solid #495fd7;
  cursor: pointer;
  color: #f9f9f9;
  background-color: lighten(#495fd7, 5%);
  border-radius: 4px;
  padding: 8px;
  min-width: 130px;
  outline: 0;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: background-color 1s;
  font-family: 'SF Pro Text';
  font-style: normal;

  &:hover {
    background-color: #495fd7;
    color: #f9f9f9;
    text-decoration: none;
    border: 1px solid darken(#495fd7, 8%);
    transition: background-color 1s;
    box-shadow: 1px 1px 2px 0px rgba(200, 200, 200, 1);
  }
  &:active {
    outline: 0;
  }
  &:focus {
    outline: 0;
  }
  &.slim {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  &.extra-width {
    padding-left: 12px;
    padding-right: 12px;
  }
  &.min-width-100 {
    min-width: 100px !important;
  }
  &.min-width-140 {
    min-width: 140px !important;
  }
  &.no-min-width {
    min-width: 0px !important;
    padding-left: 10px;
    padding-right: 0px;
  }
  &.secondary {
    background-color: #fff;
    border: 1px solid #dce0e5;
    color: #616261;
    &:hover {
      background-color: darken(#fff, 2%);
      border: 1px solid darken(#dce0e5, 8%);
      transition: background-color 1s;
      box-shadow: 1px 1px 2px 0px rgba(200, 200, 200, 1);
    }
  }
  &.tertiary {
    border: 1px solid #46c2ad;
    background-color: lighten(#46c2ad, 5%);
    color: #f9f9f9;
    &:hover {
      background-color: darken(#46c2ad, 2%);
      border: 1px solid darken(#46c2ad, 8%);
      transition: background-color 1s;
      box-shadow: 1px 1px 2px 0px rgba(200, 200, 200, 1);
    }
  }
  &.active {
    font-size: 16px;
    background-color: transparent;
    color: #6fcf97;
    border: 1px solid #6fcf97;
    &:hover {
      border: 1px solid darken(#6fcf97, 10%);
    }
  }
  &.unactive {
    font-size: 16px;
    background-color: transparent;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
  }
  &.small-font {
    font-size: 14px;
  }
  &.disabled {
    pointer-events: none;
    outline: none;
    background-color: #fff;
    border: 1px solid #dce0e5;
    color: lighten(#616261, 20%);
    transition: background-color 1s;
    &:hover {
      background-color: #fff;
      border: 1px solid #dce0e5;
      box-shadow: unset;
    }
  }
  &.add {
    color: #495fd7;
    background-color: transparent;
  }
  &.remove {
    color: #f39090;
    background-color: transparent;
    border: 1px solid #f39090;
  }
  &.evie-primary {
    background-color: #9b2c51;
    border-radius: 12px;
    border: none;
  }
  &.evie-secondary {
    background: #fff;
    border: 1px solid #dce0e5;
    box-shadow: 0px 4px 4px rgba(223, 230, 255, 0.3);
    border-radius: 12px;
    font-weight: 600;
    color: #9b2c51;
  }
  &.evie-add {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    font-weight: 700;
    color: #9b2c51;
    background: #fff;
    min-width: 115px;
    padding: 4px 8px;
    border: 1px solid #d89595;
    border-radius: 8px;
    svg {
      font-size: 20px;
      margin-right: 0;
    }
  }
  &.evie-survey {
    background-color: #9b2c51;
    min-width: 100%;
    min-height: 50px;
    padding: 8px 24px;
    border-radius: 32px;
    border: none;
  }
  &.evie-next-icon {
    min-width: 56px;
    height: 56px;
    padding: 0;
    border: none;
    border-radius: 56px;
    background-color: #9b2c51;
    display: grid;
    place-items: center;
    svg {
      font-size: 28px;
      margin: 0;
      path {
        stroke: #fff;
      }
    }
  }
  &.evie-disabled {
    opacity: 0.6;
    pointer-events: none;
    color: lighten(#616261, 50%);
  }
  &.evie-index {
    background-color: #ea7dae;
    border-radius: 20px;
    border: none;
  }
  svg {
    margin-right: 10px;
  }
}
