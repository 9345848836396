.notifications {
  color: white;
  padding: 19px 30px;
  box-shadow: 0 2px 25px 0px rgba(1, 1, 1, 0.27);
  min-height: 94px;

  &.success {
    background-color: #8AC187;
  }
  &.error {
    background-color: #EE7466;
  }
  &.warning {
    background-color: #EEB95C;
  }
  &.information {
    background-color: #94C4CC;
  }

  &__title {
    font-size: 18px;
    margin: 0 0 12px;
  }
  
  &__description {
    font-size: 14px;
    margin: 0;
  }
  
  &__close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    line-height: 16px;
    font-size: 16px;
  }
}
