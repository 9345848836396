@font-face {
  font-family: 'SF Pro Text';
  src: url('../../assets/fonts/SF-Pro-Text-Bold.otf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../../assets/fonts/SF-Pro-Text-Regular.otf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../../assets/fonts/SF-Pro-Text-Semibold.otf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../../assets/fonts/SF-Pro-Display-Regular.otf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../../assets/fonts/SF-Pro-Display-Bold.otf') format('truetype');
  font-weight: bold;
}

body,
* {
  margin: 0;
  padding: 0;
  font-family: 'SF Pro Display', 'Lucida Sans', 'Verdana', sans-serif;
}

body {
  overscroll-behavior-x: none;
}

button {
  cursor: pointer;
  font-size: inherit;
  margin: 0;
  padding: 0;
  text-align: inherit;
  text-transform: inherit;
  transition: 0.2s;
  background: transparent;
  border: none;
}

.hvh-100 {
  height: 100%;
  min-height: 100vh;
}

.content-column-table {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  margin: 0;
}

.spinner {
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1008;
}


.evie-spinner {
  background: rgba(245, 238, 227, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1008;
}

.video-js {
  outline: none !important;
  * {
    outline: none !important;
  }
}

.read-only-input {
  background-color: #f2f3f4;
  &:hover {
    border-color: #f2f3f4;
  }
}
