.rewards {
  height: 100%;
  margin: 30px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  table {
    display: table;
    width: 100%;
    thead {
      background: #f9faff;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.14);
      padding: 12px 0;
      border-radius: 4px 4px 0px 0px;
      margin-bottom: 5px;
      display: block;
      tr {
        display: flex;
        th {
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          color: #616261;
        }
      }
    }
    tbody {
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.14);
      border-radius: 0px 0px 4px 4px;
      display: block;
      max-height: calc(100vh - 365px);
      overflow-y: scroll;
      tr {
        display: flex;
        margin: 8px 0;
        background-color: #fcfcfc;
        td {
          display: flex;
          justify-content: center;
          text-align: center;
          font-size: 14px;
          color: #616261;
          padding: 12px 0;
          margin: auto 0;
          &.status-reward {
            span {
              background: rgba(70, 194, 173, 0.1);
              width: 70%;
              max-width: 140px;
              padding: 8px 0;
              border-radius: 4px;
              margin: 0;
              font-weight: bold;
              color: #46c2ad;
              &.unactive {
                background: rgba(243, 144, 144, 0.1);
                color: #f39090;
              }
            }
          }
          &.order-employee {
            display: flex;
            flex-direction: column;
            span {
              &:first-child {
                font-weight: 700;
              }
            }
          }
          &.quantity-reward {
            span {
              color: #46c2ad;
              font-weight: bold;
              margin-right: 4px;
            }
          }
        }
        &:hover {
          background-color: #edf0ff;
        }
        .reward-show {
          @extend .reward-edit;
          margin: auto 4px;
        }
        .reward-edit {
          cursor: pointer;
          font-size: 28px;
          color: #d9d9d9;
          &:hover {
            color: #8093fc;
          }
        }
      }
    }
  }
}
