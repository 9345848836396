.create-notification {
  padding: 40px 20px 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(414px, 1fr));
  &__fields {
    max-width: 95%;
    margin-bottom: 20px;
  }
  &__list {
    &__filters {
      display: flex;
      justify-content: space-between;

      &__selection-button {
        display: flex;
        align-items: center;
      }
      .css-yk16xz-control {
        height: 35px;
        min-height: 35px;
      }
      .css-1wa3eu0-placeholder {
        color: #e0e0e0;
        font-size: 14px;
        margin-top: -2px;
      }
      .select-all-text {
        font-size: 14px;
        color: #e0e0e0;
        white-space: nowrap;
      }
      &_select {
        display: flex;
        align-items: center;
        font-size: 26px;
        width: 20px;
        height: 20px;
        margin: 0 10px;
        color: #bdcbf8;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        border-radius: 3px;
        &--selected {
          border: 2px solid #bdcbf8;
        }
      }
    }
    .super-clinic-employees {
      height: 550px;
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      padding: 8px;
      margin-top: 10px;
    }
  }
  &__fetchmorebuttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
  }
}
