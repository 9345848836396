.search-filter {
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 4px;
  width: 280px;
  height: 35px;
  padding-right: 10px;
  color: #999;
  &--individuals {
    margin: 12px auto 8px;
  }
  &--patients {
    margin: 24px auto 20px;
  }
  &--library {
    width: 460px;
    margin-right: 30px;
  }
  &--collapsed {
    justify-content: center;
    max-width: 100%;
    padding-right: 0;
    background: transparent;
    border: none;
    font-size: 22px;
    cursor: pointer;
  }
  input {
    flex-grow: 1;
    border: 0px;
    background-color: transparent;
    outline: none !important;
    height: 100%;
    padding: 0 4px 0 12px;
    font-weight: normal;
    font-size: 14px;
    color: #616261;
    &::placeholder {
      color: #e0e0e0;
    }
  }
}
