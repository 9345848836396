.patients {
  &__container {
    width: 100%;
    height: 100%;
  }

  &__no-results,
  &__empty-state {
    width: 100%;
    padding: 60px;
    background: white;
    background-color: #f9faff;
    border: 0.5px solid #cbe6e0;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
    .content {
      display: flex;
      background-color: #f9faff;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      max-width: 40%;
      margin-right: 60px;
      font-family: 'SF Pro Text';
      .title {
        font-size: 24px;
        line-height: 36px;
        font-weight: bold;
        color: #616261;
      }
      .subtitle {
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #bdbdbd;
        margin-bottom: 40px;
      }
    }
    .image {
      width: 30% !important;
    }
    .icon {
      margin-top: -2px;
    }
  }
  &__no-results {
    border: 0px !important;
    margin-bottom: 0px !important;
    max-height: 100%;
    padding: 50px;
    .image {
      width: 30%;
    }
  }
}
.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.pagination {
  margin-top: 65px;
  width: 100%;
  display: flex !important;
  flex-direction: row !important;
  .page-item {
    font-size: 14px;
    color: #bdbdbd;
    width: 24px;
    height: 24px;
    text-align: center;
    margin: 8px;
    cursor: pointer;
    &.active {
      color: #495fd7;
      font-weight: bold;
      &:hover {
        color: darken(#495fd7, 5%);
      }
    }
    &:hover {
      color: darken(#bdbdbd, 10%);
    }
    &.arrow {
      color: #8093fc;
      font-size: 30px;
      margin-top: 0px;
      &:hover {
        color: darken(#8093fc, 10%);
      }
    }
  }
}

.covenant-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.span-covenant-description {
  .form__error {
    display: none !important;
  }
}

p.chart_title {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #616261;
  text-align: center;
}

div.chart_card {
  -webkit-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.09);
  background-color: #fff;
  border-radius: 6px;
  padding: 15px;
  &--printable {
    flex: 0 1 407px;
    margin: 5px;
  }
}

div.chart_container {
  background-color: #f9faff;
  padding: 20px;
}

.reports-form {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  &__download-buttons {
    margin-top: -8px;
    display: flex;
    align-items: center;
  }
}
