.modal-exercise {
  width: 100%;
  -webkit-box-shadow: inset -2px -9px 8px -8px rgba(189, 189, 189, 1);
  -moz-box-shadow: inset -2px -9px 8px -8px rgba(189, 189, 189, 1);
  box-shadow: inset -2px -9px 8px -8px rgba(189, 189, 189, 1);
  margin-top: 40px;
  height: 60vh;
  display: flex;
  overflow: hidden;
  background: #f9faff;
  border-top: 1px solid darken(#f9faff, 10%);
  .video-js {
    max-width: 40vw;
    height: 60vh;
  }
  &__data {
    display: flex;
    flex-direction: column;
    width: 53%;
    .info-row {
      display: flex;
      flex-direction: row;
      width: 100%;
      .info-title {
        font-weight: bold;
        font-size: 20px;
        line-height: 36px;
        color: #616261;
        margin-bottom: 0px;
        vertical-align: middle;
        padding: 20px;
        flex-grow: 1;
      }
      .close-icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        .close-icon {
          cursor: pointer;
          font-size: 20px;
          color: #8093fc;
          margin-right: 15px;
          margin-top: -15px;
        }
      }
      .info-col {
        display: flex;
        flex-direction: column;
        width: 33%;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;
        margin-top: 5px;
        .label {
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 21px;
          color: #8093fc;
          margin-bottom: 5px;
        }
        .value {
          margin-bottom: 0px;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 21px;
          color: #818181;
        }
      }
      &.instructions {
        flex-grow: 1;
        padding-bottom: 40px;
        margin-top: 20px;
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
        overflow-y: scroll;
        .label {
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 21px;
          color: #8093fc;
          margin-bottom: 10px;
        }
        ol {
          padding-left: 40px;
          padding-right: 40px;
          li {
            font-family: 'SF Pro Text' !important;
            font-size: 14px;
            line-height: 20px;
            color: #bdbdbd;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}